import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { EditUserAPI, getUserDetailAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Style from './EditUserForm.module.scss'
const EditUserForm = ({id}) => {
    const [loadingState, setLoadingState] = useState(false);
    const [userDetail, setUserDetail] = useState(false);
    useEffect(()=>{
        getUserDetailHandler()
    },[])
    const getUserDetailHandler=async()=>{
        const data=await getUserDetailAPI(id.id)
        setUserDetail(data.data.user)

    }
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm();
    const onSubmit = (data) => {

            setLoadingState(true)
            let info={
                Id:id.id,
              FirstName:data.FirstName,
              LastName:data.LastName,
              Email:data.Email
            }
            EditUserAPI(info,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    reset()
                    setLoadingState(false)
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        
        
    };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <ul className={Style.InputHolder}>
        <li>
            <label htmlFor="FirstName">نام کاربر</label>
            <input
            {...register("FirstName", { required: true })}
            className={errors.FirstName ? "valid" : "text"}
            name="FirstName"
            defaultValue={userDetail.firstName}
            />
        </li>
        
        <li>
            <label htmlFor="LastName">نام خانوادگی کاربر</label>
            <input
            {...register("LastName", { required: true })}
            className={errors.LastName ? "valid" : "text"}
            name="LastName"
            defaultValue={userDetail.lastName}
            />
        </li>
        
        <li>
            <label htmlFor="Email">ایمیل</label>
            <input
            {...register("Email", { required: false })}
            className={errors.Email ? "valid" : "text"}
            name="Email"
            type={'mail'}
            defaultValue={userDetail.email}
            />
        </li>

        </ul>
        <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
        {loadingState ? (
            <small>
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            /> 
            لطفا صبرکنید
            </small>
        ) : (
            "ثبت"
        )}
        </Button>
    </form>
  )
}

export default EditUserForm