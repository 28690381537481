import React, { useState } from 'react'
import Style from './AddCountryForm.module.scss'
import { useForm } from "react-hook-form";
import { Button, Spinner } from "react-bootstrap";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { AddCountryAPI } from '../../../API/BarangizanServices';


const AddCountryForm = () => {
    const [loadingState, setLoadingState] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
      } = useForm();
      const onSubmit = (data) => {
          setLoadingState(true)
          let formData=new FormData()
          formData.append('Title',data.title)
          formData.append('EnglishTitle',data.EnglishTitle)
          formData.append('Currency',data.Currency)
          formData.append('Initials',data.Initials)
          AddCountryAPI(formData,(succ,res)=>{
              if(succ){
                  setLoadingState(false)
                  succToast(res.data.message)
                  reset()
              }
              else{
                  errorToast(res.data.errors[0])
                  setLoadingState(false)
              }
          })
      };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      <h3>افزودن کشور</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="title">عنوان کشور</label>
          <input
            {...register("title", { required: false })}
            className={errors.title ? "valid" : "text"}
            autoComplete="title"
            type="text"
            name="title"
          />
        </li>
        <li>
          <label htmlFor="EnglishTitle">عنوان کشور به انگلیسی</label>
          <input
            {...register("EnglishTitle", { required: false })}
            className={errors.EnglishTitle ? "valid" : "text"}
            autoComplete="EnglishTitle"
            type="text"
            name="EnglishTitle"
          />
        </li>
        <li>
          <label htmlFor="Currency">ارز کشور</label>
          <input
            {...register("Currency", { required: false })}
            className={errors.Currency ? "valid" : "text"}
            autoComplete="Currency"
            type="text"
            name="Currency"
          />
        </li>
        <li>
          <label htmlFor="Initials">مخفف کشور</label>
          <input
            {...register("Initials", { required: false })}
            className={errors.Initials ? "valid" : "text"}
            autoComplete="Initials"
            type="text"
            name="Initials"
          />
        </li>

      </ul>


      <Button
        className="w-50 my-3"
        type="submit"
        disabled={loadingState ? true : false}
      >
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  )
}

export default AddCountryForm