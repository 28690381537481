import React, { useState } from 'react'
import Style from './AddForeignPropertyTypeForm.module.scss'
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { AddForeignPropertyTypeAPI } from '../../../API/BarangizanServices';
const AddForeignPropertyTypeForm = () => {
    const [loadingState, setLoadingState] = useState(false);

    const {
      register,
      formState: { errors },
      handleSubmit,
      reset
    } = useForm();
    const onSubmit = (data) =>{
        setLoadingState(true)
        let formData = new FormData();
        formData.append("Title", data.Text);
        formData.append("EnglishTitle", data.EnglishTitle);
        AddForeignPropertyTypeAPI(formData,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                reset()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
        
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      <h3>افزودن ویژگی ملک خارجی</h3>
      <ul className={Style.InputHolder}>
       
        <li>
          <label htmlFor="Text">عنوان نوع ملک</label>
          <input
            {...register("Text", { required: true })}
            className={errors.Text ? "valid" : "text"}
            autoComplete="Text"
            name="Text"
          />
        </li>
        <li>
          <label htmlFor="EnglishTitle">عنوان نوع ملک به انگلیسی</label>
          <input
            {...register("EnglishTitle", { required: true })}
            className={errors.EnglishTitle ? "valid" : "text"}
            autoComplete="EnglishTitle"
            name="EnglishTitle"
          />
        </li>
      </ul>




      <Button className="w-100 my-3" type="submit">
        ثبت
      </Button>
    </form>
  )
}

export default AddForeignPropertyTypeForm