import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { DeleteForeignPropertyProjectAPI, getForeignPropertyProjectListAPI } from '../../API/BarangizanServices'
import EditForeignPropertyProjectForm from '../../Components/Forms/EditForeignPropertyProjectForm/EditForeignPropertyProjectForm'
import ForeignPropertyProjectGallery from '../../Components/Gallery/ForeignPropertyProjectGallery/ForeignPropertyProjectGallery'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import ForeignPropertyProjectListTabel from '../../Components/Tabels/ForeignPropertyProjectListTabel/ForeignPropertyProjectListTabel'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'
import { errorToast, succToast } from '../../Utils/Toast/Toast'

const ForeignPropertyProjectListContainer = () => {
    const[PropertyData,setPropertyData]=useState()
    const[pageDetail,setPageDetail]=useState()
    const[currentPage,setCurrentPage]=useState()
    useEffect(()=>{
        getPropertyListHandler(1)
        
    },[])
    const getPropertyListHandler=async(pageNumber)=>{
        setCurrentPage(pageNumber)
        setLoadingState(true)
        const data=await getForeignPropertyProjectListAPI(pageNumber)
        setPropertyData(data.data.list)
        setPageDetail(data.data.pagination)
        setLoadingState(false)
        window.scrollTo(0, 0)
    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const[ProprtyInfo,setPropertyInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
      setShow3(false)
      
    };
    const handelModal=(id,value)=>{
      setPropertyInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }
      if(id===3){
        setShow3(true)
      }
    }
    const deletePropertyHandler=()=>{
        setLoadingState(true)
        DeleteForeignPropertyProjectAPI(ProprtyInfo.code,(succ,res)=>{
          if(succ){
            succToast(res.data.message)
            getPropertyListHandler(currentPage)
            setLoadingState(false)
            handleShow()
          }
          else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
          }
        })
      }
  return (
    <div className='flexStyle'>
    <LoadingPage show={loadingState}/>
    <TitleHeader text={"لیست پروژه های ملکی خارجی ثبت شده"}/>
    <Modal show={show3} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>گالری ملک</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <ForeignPropertyProjectGallery id={ProprtyInfo}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={show} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>ویرایش پروژه</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <EditForeignPropertyProjectForm info={ProprtyInfo} getUpdate={()=>getPropertyListHandler(1)} closeModal={()=>handleShow}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={show2} className={'RTL'}>
      <Modal.Header >
        <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
      </Modal.Header>

      <Modal.Body className='d-flex justify-content-around w-100'>
        <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deletePropertyHandler()}>بله</Button>
        <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
      </Modal.Body>
  </Modal>
    <ForeignPropertyProjectListTabel currentPage={currentPage} data={PropertyData} pageDetail={pageDetail} updatePage={(page)=>getPropertyListHandler(page)} showModal={(e,value)=>handelModal(e,value)}/>
   
</div>
  )
}

export default ForeignPropertyProjectListContainer