import React, { useState } from 'react'
import { useEffect } from 'react'
import { getTiketInfoAPI } from '../../API/BarangizanServices'
import MessageBoxTiket from './MessageBoxTiket/MessageBoxTiket'
import MessageList from './MessageList/MessageList'

const TiketChat = ({info}) => {
    const[tiketState,setTiketState]=useState()

   useEffect(()=>{
    getTiketInfoHandler()
   },[])

   const getTiketInfoHandler=async()=>{
       const data=await getTiketInfoAPI(info.id)
       setTiketState(data.data)
   }
   console.log(tiketState)
  return (
    <div className='flexStyle'>
        <MessageList list={tiketState&&tiketState.messages}/>
        <MessageBoxTiket tiketId={info.id} getUpdate={getTiketInfoHandler}/>
    </div>
  )
}

export default TiketChat