import { Button, Spinner } from "react-bootstrap";
import React from "react";
import Style from "./MessageBoxTiket.module.scss";
import { useForm } from "react-hook-form";
import { SendOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { newMessageTiketAPI } from "../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";

const MessageBoxTiket = ({ tiketId, getUpdate }) => {
  const [loadingState, setLoadingState] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    setLoadingState(true);
    let formData = new FormData();
    formData.append("TicketId", tiketId);
    formData.append("Message", data.Message);
    formData.append("File", data.FileTiket[0]);
    newMessageTiketAPI(formData, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        reset();
        getUpdate();
        setLoadingState(false);
      } else {
        errorToast(res.data.errors[0]);
        setLoadingState(false);
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <textarea
        {...register("Message", { required: true })}
        className={errors.Message ? "valid" : Style.TextBoxHolder}
      />
      <input
        type="file"
        name=""
        id="tiketFile"
        style={{ display: "none" }}
        {...register("FileTiket", { required: false })}
      />
      <label htmlFor="tiketFile">
        <FontAwesomeIcon icon={faPaperclip} />
      </label>
      <Button
        disabled={loadingState}
        type="submit"
        className={Style.ButtonHolder}
      >
        {loadingState ? (
          <Spinner animation="border" variant="light" />
        ) : (
          <SendOutlined rotate={180} />
        )}
      </Button>
    </form>
  );
};

export default MessageBoxTiket;
