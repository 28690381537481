import React, { useEffect, useState } from 'react'
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import { Button, Modal } from 'react-bootstrap'
import { CooperationRequestAPI } from '../../API/BarangizanServices';
import CooperationRequestTabel from '../../Components/Tabels/CooperationRequestTabel/CooperationRequestTabel';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';

const CooperationRequestContainer = () => {
    const[loadingState,setLoadingState]=useState(false)
    const [show, setShow] = useState(false);
    const[UserInfo,setUserInfo]=useState()
    const[RequestListState,setRequestListState]=useState()
    const handelModal=(id,value)=>{
        setUserInfo(value)
        if(id===1){
          setShow(true)
        }
      }
      const handleShow = () =>setShow(false)

      useEffect(()=>{
        getRequestHandler()
      },[])
      const getRequestHandler=async()=>{
          const data=await CooperationRequestAPI()
          setRequestListState(data.data.list)
      }
      console.log(RequestListState)
      return (
    <div className='flexStyle'>
    <LoadingPage show={loadingState}/>
    <TitleHeader text={"لیست  درخواست همکاری ها"}/>
    <Modal show={show} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>جزئیات</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
        <CooperationRequestTabel data={RequestListState}  showModal={(e,value)=>handelModal(e,value)}/>
</div>
  )
}

export default CooperationRequestContainer