import React from 'react'
import AddCountryContainer from '../../../Container/AddCountryContainer/AddCountryContainer'
import Layout from '../Layout'

const AddCountry = () => {
  return (
    <Layout><AddCountryContainer/></Layout>
  )
}

export default AddCountry