import React, { useEffect, useState } from "react";
import Style from "./PropertyRegisterRequestForm.module.scss";
import {Spinner,Button} from 'react-bootstrap'
import Editor from "../../Editor/Editor";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { getAllUserListAPI, getCityApi, getCountryListAPI, getPropertyRequestDetailAPI, getPropertyTypesAPI, getProvinceApi, PropertyRequestConfirmAPI } from "../../../API/BarangizanServices";
import { Checkbox } from "antd";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";

const PropertyRegisterRequestForm = ({ ProprtyInfo,getUpdate,closeModal }) => {
    const [loadingState, setLoadingState] = useState();
  const [PropertyDetailState, setPropertyDetailState] = useState();
  const [propertyType, setPropertyType] = useState([]);
  const [propertyValue, setPropertyValue] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [cityName, setCityName] = useState("");
  const[featureListState,setFeatureListState]=useState()
  const[UserList,setUserList]=useState()
  const[userSelect,setUserSelect]=useState()
  const[ImageSelected,setImageSelected]=useState()
  const [countryList, setCountryList] = useState(null);
  const[loadingType,setLoadingType]=useState(true)
  const [TahatorState, setTahatorState] = useState(false);
  const [countryListTahator, setCountryListTahator] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();


  useEffect(() => {
    getPropertyDetails();
  }, []);
  const getPropertyDetails = async () => {
    const countries = await getCountryListAPI();
    setCountryList(countries.data.list);
    const data = await getPropertyRequestDetailAPI(ProprtyInfo.id);
    setPropertyDetailState(data.data.request);
    setFeatureListState(data.data.featuresList)
    setTahatorState(data.data.request.isTahator)
    // const features=await getFeaturesListOptionsAPI()
    const data1 = await getProvinceApi();
    setProvince(data1.data);
    getCityListHandler({ label: data.data.request.province });
    const users=await getAllUserListAPI(1)
    setUserList(users.data.users)
    const propertyType=await getPropertyTypesAPI()
    setPropertyType(propertyType.data.list)
    setLoadingType(false)

  };
  
  const getCityListHandler = async (id) => {
    setProvinceName(id.label);
    const data = await getCityApi(id.label);
    setCity(data.data);
  };
  let provinceList = [];
  province.forEach((element, index) => {
    provinceList.push({ value: index, label: element });
  });
  const defaultProvince = provinceList.find(
    (element) => element.label === PropertyDetailState.province
  );
  
  let CityList = [];
  city.forEach((element, index) => {
    CityList.push({ value: index, label: element });
  });
  const defaultCity = CityList.find(
    (element) => element.label === PropertyDetailState.city
  );
  
  const [description, setDescription] = useState('');
  let defaultValueFeature=[]
  featureListState&&featureListState.forEach(element=>{
    let value=element.value!==null?element.value.split(','):['ندارد']
    let testarray=[]
    value.forEach(element=>{
      testarray.push({
        label:element,value:element
      })
    })
    defaultValueFeature.push(testarray)
  })

  const onSubmit = (data) => {
    
    if(ImageSelected===undefined||ImageSelected.length===0){
      errorToast('حداقل یک عکس انتخاب شود!')
    }
    else{
      setLoadingState(true);

      let formData = new FormData();
      formData.append("CreationRequestId", ProprtyInfo.id);
      formData.append("IsTahator", data.Tahator);
      formData.append("TahatorCountries", countryListTahator===null?defaultTahatorCountries:countryListTahator);
      formData.append("EstatesType",propertyValue===""?PropertyDetailState.type: propertyValue.label);
      formData.append("EstatesTypeId",propertyValue===""?PropertyDetailState.typeId: propertyValue.value);
      formData.append("title", data.title);
      formData.append("description", description===''?PropertyDetailState.description:description);
      formData.append("province", provinceName===""?defaultProvince.label:provinceName);
      formData.append("city", cityName===""?defaultCity.label:cityName.label);
      formData.append("Metraj", data.Metraj);
      formData.append("price", data.price);
      formData.append("discountedPrice", data.discountedPrice);
      formData.append("PrePaymentPrice", data.PrePaymentPrice);
      formData.append("ExclusiveVisitPrice", data.ExclusiveVisitPrice);
      formData.append("VirtualVisitPrice", data.VirtualVisitPrice);
      formData.append("Address", data.Address);
      for (var i = 0; i < parseInt(featureListState.length); i++) {
        formData.append(`Features[${i}].Key`, data.Features[i].Key);
        let featureString=[]
        data.Features[i].value&&data.Features[i].value.forEach(element=>{
          featureString.push(element.label)
        })
        formData.append(`Features[${i}].Value`, featureString.toString());
      }
        for (var j = 0; j < parseInt(ImageSelected.length); j++) {
          formData.append(`Images[${j}].Id`, ImageSelected[j]);
        }
        PropertyRequestConfirmAPI(formData,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                  setLoadingState(false)
                  getUpdate()
                  closeModal()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
  
    }
    
  }

  const checkedImageHandler=(e)=>{
    setImageSelected(e)
  }
  const defaultTahatorCountries =PropertyDetailState?.tahatorCountries?.split('_')
  const TahatorHandler = (e) => {
    setTahatorState(e.target.checked);
  };
  const seletCountryTahatorHandler = (e) => {
    let CountryListSelected = [];
    e.forEach((element) => {
      CountryListSelected.push(element.label);
    });
    setCountryListTahator(CountryListSelected.join("_"));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      {PropertyDetailState === undefined ||
        province.length === 0 ||
        city.length === 0?<Spinner animation="border" variant="warning"/>:
        <>
        <ul className={Style.InputHolder}>
          <li>
            <label htmlFor="type">نوع ملک</label>
            <div className={Style.selectOption}>
              <Select
                {...register("type", { required: false })}
                onChange={setPropertyValue}
                options={propertyType.map(item=>{
                  return ({ value: item.id, label: item.title })
                })}
                defaultValue={{label:PropertyDetailState.type,value:PropertyDetailState.typeId}}
                name="type"
                placeholder="انتخاب کنید"
                className={Style.selectOptionsearch}
                isLoading={loadingType}
              />
            </div>
          </li>
          <li>
            <label htmlFor="title">عنوان ملک</label>
            <input
              {...register("title", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="title"
              type="text"
              name="title"
              defaultValue={PropertyDetailState.title}
            />
          </li>

          <li>
            <label htmlFor="province"> استان</label>
            <div className={Style.selectOption}>
              <Select
                {...register("province", { required: false })}
                onChange={getCityListHandler}
                options={provinceList}
                name="province"
                placeholder="انتخاب کنید"
                className={Style.selectOptionsearch}
                defaultValue={defaultProvince}
              />
            </div>
          </li>
          <li>
            <label htmlFor="city"> شهر</label>
            <div className={Style.selectOption}>
              <Select
                {...register("city", { required: false })}
                options={CityList}
                name="city"
                onChange={setCityName}
                placeholder="انتخاب کنید"
                className={Style.selectOptionsearch}
                defaultValue={defaultCity}
              />
            </div>
          </li>

          <li>
            <label htmlFor="Metraj">متراژ </label>
            <input
              {...register("Metraj", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="Metraj"
              type="text"
              name="Metraj"
              defaultValue={PropertyDetailState.area}
            />
          </li>
          <li>
            <label htmlFor="price">قیمت </label>
            <input
              {...register("price", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="price"
              type="number"
              name="price"
              placeholder="به تومان"
              defaultValue={PropertyDetailState.price}
            />
          </li>
          <li>
            <label htmlFor="discountedPrice">قیمت با تخفیف </label>
            <input
              {...register("discountedPrice", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="discountedPrice"
              type="number"
              name="discountedPrice"
              placeholder="به تومان"
              defaultValue={PropertyDetailState.discountedPrice}
            />
          </li>
          <li>
            <label htmlFor="PrePaymentPrice">قیمت پیش پرداخت </label>
            <input
              {...register("PrePaymentPrice", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="PrePaymentPrice"
              type="number"
              name="PrePaymentPrice"
              placeholder="به تومان"
              defaultValue={PropertyDetailState.prePaymentPrice}
            />
          </li>
          <li>
            <label htmlFor="ExclusiveVisitPrice">قیمت بازدید اختصاصی </label>
            <input
              {...register("ExclusiveVisitPrice", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="ExclusiveVisitPrice"
              type="number"
              placeholder="به تومان"
              name="ExclusiveVisitPrice"
              defaultValue={PropertyDetailState.exclusiveVisitPrice}
            />
          </li>
          <li>
            <label htmlFor="VirtualVisitPrice">قیمت بازدید مجازی </label>
            <input
              {...register("VirtualVisitPrice", { required: true })}
              className={errors.username ? "valid" : "text"}
              autoComplete="VirtualVisitPrice"
              type="number"
              placeholder="به تومان"
              name="VirtualVisitPrice"
              defaultValue={PropertyDetailState.virtualVisitPrice}
            />
          </li>

                <li>
          <label htmlFor="owner">  مالک</label>
          <div className={Style.selectOption}>
            <Select
              {...register("owner", { required: false })}
              onChange={setUserSelect}
              options={
                UserList&&UserList.map(item=>{
                  return({label:`${item.firstName} ${item.lastName}- ${item.userName}`,value:item.userName})
                })
              }
              isDisabled={true}
              name="owner"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
             defaultValue={{label:`${PropertyDetailState.ownerName}- ${PropertyDetailState.ownerPhoneNumber}`,value:PropertyDetailState.userName}}
            />
          </div>
        </li>
        <li>
            <label htmlFor="Tahator" className={Style.TahatorCheckBox}>
              <input
                type={"checkbox"}
                id="Tahator"
                {...register("Tahator", { required: false })}
                defaultChecked={PropertyDetailState.isTahator}
                onChange={TahatorHandler}
              />
               تهاتر 
            </label>
          </li>
          {TahatorState? (
            <li>
              <label htmlFor="type"> کشور برای تهاتر</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("type", { required: false })}
                  onChange={seletCountryTahatorHandler}
                  options={countryList?.map((item) => {
                    return { value: item.title, label: item.title };
                  })}
                  name="type"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  defaultValue={defaultTahatorCountries?.map((item)=>{
                    return { value: item, label: item }
                  })}
                  isMulti
                />
              </div>
            </li>
          ) : null}
        </ul>
        <div className={Style.DescHolder}>
        <label htmlFor="Address">آدرس ملک</label>
        <textarea
          {...register("Address", { required: true })}
          className={errors.Address ? "valid" : "text"}
          autoComplete="Address"
          type="text"
          name="Address"
          defaultValue={PropertyDetailState.address}
        />
      </div>
      <ul className={Style.InputHolder}>
          {
          featureListState&&featureListState.map((item,i)=>{
            return(
              <li key={item.id}>
                <label htmlFor="featureCount">{`  ویژگی ${i+1}` }</label>
                <input
                  {...register(`Features[${i}].Key`)}
                  className={errors.username ? "valid" : "text"}
                  autoComplete={`Features[${i}].Key`}
                  type="text"
                  name={`Features[${i}].Key`}
                  style={{ marginBottom: "0.5rem" }}
                  defaultValue={item.text}
                  readOnly
                />
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <div className={Style.selectOption}>
                    <Select
                      defaultValue={defaultValueFeature[i]}
                      options={
                          item.options.map(item=>{
                            return(
                              {
                                label:item.text,
                                value:item.text
                              }
                            )
                          })
                      }
                      name={`Features[${i}].value`}
                      // onChange={setFeatureTest}
                      onChange={onChange}
                      placeholder="انتخاب کنید"
                      className={Style.selectOptionsearch}
                      isMulti
                    />
                  </div> 
                  )}
                  name={`Features[${i}].value`}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={defaultValueFeature[i]}
                />
              </li>
            )
          })
        }
        </ul>
        <div className={Style.DescHolder}>
          <label htmlFor="description">توضیحات ملک</label>
          <Editor desc={description} setdescription={setDescription} defaultValue={PropertyDetailState.description}/>
        </div>
        <Checkbox.Group style={{ width: '100%' }} onChange={checkedImageHandler}>
            {
                PropertyDetailState.images.map(item=>{
                    return(
                        <Checkbox value={item.id}>
                            <div className={Style.ImageHolder}><img src={`https://api.barangizan.ir${item.url}`} alt="property" /></div>
                        </Checkbox>
                    )
                })
            }
        </Checkbox.Group>

        <Button className="w-100 my-3" type="submit">
          ثبت
        </Button></>
        }
    </form>
  );
};

export default PropertyRegisterRequestForm;
