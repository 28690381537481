import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { DeleteImageFromGalleryMagazineAPI, makeImageCoverMagazineAPI } from "../../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../../Utils/Toast/Toast";
import Style from "./MediaList.module.scss";
const MediaList = ({ data,getUpdate }) => {
  const [loading, setLoading] = useState(false);
  const RemoveItemHandler = (id) => {
    setLoading(true);
    
    DeleteImageFromGalleryMagazineAPI(id,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            setLoading(false)
            getUpdate()
        }
        else{
            errorToast(res.data.errors[0])
            setLoading(false)
        }
    })
  };
  const makeImageMainHandler=(id)=>{
    setLoading(true);
    makeImageCoverMagazineAPI(id,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            setLoading(false)
            getUpdate()
        }
        else{
            errorToast(res.data.errors[0])
            setLoading(false)
        }
    })
  }
  return (
    <ul className={Style.ListHolder}>
      {data.map((item) => {
        return (
          <li key={item.id}>
            {!item.isVideo ? (
              <img src={`https://api.barangizan.ir${item.url}`} alt="" />
            ) : (
              <video className={Style.VideoHolder} controls>
                <source
                  src={`https://api.barangizan.ir${item.url}`}
                  type="video/mp4"
                />
                Your browser does not support HTML video.
              </video>
            )}

            {!item.isVideo ? (
              item.isMainImage ? (
                <p>عکس اصلی</p>
              ) : (
                <Button className="my-2 text-light" variant="warning" disabled={loading?true:false} onClick={()=>makeImageMainHandler(item.id)}>
                  تنظیم به عنوان عکس اصلی
                </Button>
              )
            ) : null}
            {item.isMainImage ? null : (
              <Button variant="danger" onClick={()=>RemoveItemHandler(item.id)} disabled={loading?true:false}>
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <small><FontAwesomeIcon icon={faTrash}/> حذف</small>
                )}
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default MediaList;
