import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { DeleteAdminAPI, getAdminListAPI } from '../../API/BarangizanServices';
import EditAdminPasswordForm from '../../Components/Forms/EditAdminPasswordForm/EditAdminPasswordForm';
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import AdminListTabel from '../../Components/Tabels/AdminListTabel/AdminListTabel';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
import { errorToast, succToast } from '../../Utils/Toast/Toast';

const AdminListContainer = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[adminListState,setAdminListState]=useState()
    const handleShow = () =>{
        setShow(false)
        setShow2(false)
      };
      const[AdminInfo,setAdminInfo]=useState()
      const[loadingState,setLoadingState]=useState()

      useEffect(()=>{
        getAdminListHandler()
      },[])
      const getAdminListHandler=async()=>{
          const data=await getAdminListAPI()
        setAdminListState(data.data)
      }
      const handelModal=(id,value)=>{
        setAdminInfo(value)
        if(id===1){
          setShow(true)
        }
        if(id===2){
          setShow2(true)
        }

      }
      const deleteAdminHandler=()=>{
        setLoadingState(true)
        DeleteAdminAPI(AdminInfo.userName,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                getAdminListHandler()
                handleShow()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
      }
  return (
    <div className='pageHolder'>
        <LoadingPage show={loadingState}/>
        <TitleHeader text={"لیست  ادمین ها"}/>
        
        <Modal show={show} className={'RTL'} >
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>ویرایش پسورد ادمین</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <EditAdminPasswordForm info={AdminInfo}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>

          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteAdminHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>
        <AdminListTabel data={adminListState} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default AdminListContainer