import React from 'react'
import AddTourForm from '../../Components/Forms/AddTourForm/AddTourForm'

const AddTourContainer = () => {
  return (
    <div className='pageHolder'>
        <AddTourForm/>
    </div>
  )
}

export default AddTourContainer