import React,{useEffect,useState} from 'react'
import { getAffidavitDetailAPI } from '../../../API/BarangizanServices'
import { errorToast } from '../../../Utils/Toast/Toast'

const AffidavvitDetail = ({info}) => {
    const[detailState,setDetailState]=useState(null)
    useEffect(()=>{
        getAffidavitDetailHandler()
    },[])
    const getAffidavitDetailHandler=()=>{
        getAffidavitDetailAPI(info.id,(succ,res)=>{
            if(succ){
                setDetailState(res.data.data.data)
            }else{
                errorToast(res.data.data.errors[0])
            }
        })
    }
  return (
    <section className='flexStyle'>
        <ul className='w-100 p-0'>
            <li className='bg-secondary p-2 text-white'>
                لیست متغیرها
            </li>
            {detailState&&detailState.dynamicVariables.map((item,index)=>{
                return(
                    <li className='text-warning bg-light p-2' key={index}>
                        {item}
                    </li>
                )
            })}
        </ul>
        <h3 className='bg-light w-100 p-2 text-center fs-5'>{detailState?.title}</h3>
        <div className='w-100 p-2 mb-3' dangerouslySetInnerHTML={{__html: detailState!==null?detailState.text:'درحال دریافت'}}></div>
    </section>
  )
}

export default AffidavvitDetail