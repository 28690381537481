import React from 'react'
import TourListContainer from '../../../Container/TourListContainer/TourListContainer'
import Layout from '../Layout'

const TourList = () => {
  return (
    <Layout><TourListContainer/></Layout>
  )
}

export default TourList