import React, { useEffect, useState } from 'react'
import { errorToast, succToast } from '../../Utils/Toast/Toast'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import { Button, Modal } from 'react-bootstrap'
import { DeletePropetyTypeAPI, getPropertyTypesAPI } from '../../API/BarangizanServices'
import PropertyTypeListTabel from '../../Components/Tabels/PropertyTypeListTabel/PropertyTypeListTabel'
import EditPropertyTypeForm from '../../Components/Forms/EditPropertyTypeForm/EditPropertyTypeForm'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'
const PropertyTypeListContainer = () => {
    const[propertTypeList,setPropertyTypeList]=useState()
    useEffect(()=>{
        getPropertyTypeHandler()
    },[])
    const getPropertyTypeHandler=async()=>{
        const data=await getPropertyTypesAPI()
        setPropertyTypeList(data.data.list)
    }
    const[loadingState,setLoadingState]=useState(false)
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[PropertyTypeInfo,setPropertyTypeInfo]=useState()
    const handleShow = () =>{
        setShow(false)
        setShow2(false)
      };
      const handelModal=(id,value)=>{
        setPropertyTypeInfo(value)
        if(id===1){
          setShow(true)
        }
        if(id===2){
          setShow2(true)
        }
  
      }
      const deleteTypeHandler=()=>{
        setLoadingState(true)
        DeletePropetyTypeAPI(PropertyTypeInfo.id,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                getPropertyTypeHandler()
                handleShow()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
                handleShow()
            }
        })
      }
  return (
    <div className='flexStyle'>
    <TitleHeader text={"لیست نوع املاک ثبت شده"}/>
    <LoadingPage show={loadingState}/>
    <Modal show={show} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>ویرایش نوع ملک</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <EditPropertyTypeForm id={PropertyTypeInfo} getUpdate={()=>getPropertyTypeHandler()} closeModal={()=>handleShow()}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={show2} className={'RTL'}>
      <Modal.Header >
        <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
      </Modal.Header>

      <Modal.Body className='d-flex justify-content-around w-100'>
        <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteTypeHandler()}>بله</Button>
        <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
      </Modal.Body>
  </Modal>
    <PropertyTypeListTabel data={propertTypeList} showModal={(e,value)=>handelModal(e,value)}/>
</div>
  )
}

export default PropertyTypeListContainer