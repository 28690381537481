import { Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getPropertyRequestAPI } from '../../API/BarangizanServices'
import AddTiketForOneUserForm from '../../Components/Forms/AddTiketForOneUserForm/AddTiketForOneUserForm'
import PropertyRegisterRequestForm from '../../Components/Forms/PropertyRegisterRequestForm/PropertyRegisterRequestForm'
import RejectPropertyForm from '../../Components/Forms/RejectPropertyForm/RejectPropertyForm'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import PropertyRegisterRequestTabel from '../../Components/Tabels/PropertyRegisterRequestTabel/PropertyRegisterRequestTabel'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'

const PropertyRegisterRequestContainer = () => {
    const[PropertyData,setPropertyData]=useState()
    const[pageDetail,setPageDetail]=useState()

    useEffect(()=>{
        getPropertyRequestHandler(1,0)
    },[])
    const getPropertyRequestHandler=async(pageNumber,status)=>{
      setLoadingState(true)
        const data=await getPropertyRequestAPI(pageNumber,status)
        setPropertyData(data.data.list)
        setPageDetail(data.data.pagination)
        setLoadingState(false)
    }

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const[ProprtyInfo,setPropertyInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
      setShow3(false)
      
    };
    const handelModal=(id,value)=>{
      setPropertyInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }
      if(id===3){
        setShow3(true)
      }
    }
    const RequestHandler = (e) => {
      getPropertyRequestHandler(1,e.target.value)
    };

  return (
    <div className='flexStyle'>
        <LoadingPage show={loadingState}/>
        <TitleHeader text={"لیست درخواست های ثبت املاک"}/>
        <Modal show={show3} className={'RTL'} size={'xl'}>
            <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>رد کردن ملک</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <RejectPropertyForm ProprtyInfo={ProprtyInfo} getUpdate={()=>getPropertyRequestHandler(1)} closeModal={()=>handleShow()}/>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>اطلاعات و ویرایش ملک</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <PropertyRegisterRequestForm ProprtyInfo={ProprtyInfo} getUpdate={()=>getPropertyRequestHandler(1)} closeModal={()=>handleShow()}/>
            
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
        <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>تیکت</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <AddTiketForOneUserForm info={ProprtyInfo}/>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
    </Modal>
        <Radio.Group onChange={RequestHandler} defaultValue={0} className={'w-100 m-2 text-center'}>
          <Radio.Button value={0} className="mx-2">درانتظار بررسی</Radio.Button>
          <Radio.Button value={1} className="mx-2">تایید شده</Radio.Button>
          <Radio.Button value={2} className="mx-2">ردشده</Radio.Button>
        </Radio.Group>
        <PropertyRegisterRequestTabel data={PropertyData} pageDetail={pageDetail} updatePage={(page)=>getPropertyRequestHandler(page)} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default PropertyRegisterRequestContainer