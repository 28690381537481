import React from 'react'
import MagazinePropertyRegisterContainer from '../../../Container/MagazinePropertyRegisterContainer/MagazinePropertyRegisterContainer'
import Layout from '../Layout'

const MagazinePropertyRegister = () => {
  return (
    <Layout><MagazinePropertyRegisterContainer/></Layout>
  )
}

export default MagazinePropertyRegister