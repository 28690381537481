import React, { useEffect, useState } from 'react'
import { getPropertyReservationRequestAPI } from '../../API/BarangizanServices'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import PropertyReservationListTabel from '../../Components/Tabels/PropertyReservationListTabel/PropertyReservationListTabel'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'

const PropertyReservationListContainer = () => {
    useEffect(()=>{
        getRequestsHandler(1)
    },[])
    const[requestsState,setRequestsState]=useState()
    const getRequestsHandler =async(pageNumber)=>{
        const data=await getPropertyReservationRequestAPI(pageNumber)
        let list=[]
        data.data.list.forEach(element => {
            if(element.isFinalized){
                list.push(element)
            }
            setRequestsState(list)
        });
    }
  return (
    <div className='flexStyle'>
    <TitleHeader text={"لیست املاک رزو شده"}/>
    

    <PropertyReservationListTabel data={requestsState} />
</div>
  )
}

export default PropertyReservationListContainer