import React, { useState } from 'react'
import Style from "./EditMagazineForm.module.scss";
import { useForm } from "react-hook-form";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import Editor from "../../Editor/Editor";
import { Button, Spinner } from "react-bootstrap";
import { useEffect } from 'react';
import { EditMagazineAPI, getMagazineDetailAPI } from '../../../API/BarangizanServices';
import LoadingPage from '../../LoadingPage/LoadingPage';
import DatePickerInRange from '../../DatePickerInRange/DatePickerInRange';
const EditMagazineForm = ({info,getUpdate,closeModal}) => {
    const[detailsState,setDetailsState]=useState()
    const [loadingState, setLoadingState] = useState(false);
    const [description, setDescription] = useState("");
    const [magazineMonth, setMagazineMonth] = useState();
    
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDay2, setSelectedDay2] = useState(null);
    const month = [
      {
        value: "فروردین",
        name: "فروردین",
      },
      {
        value: "اردیبهشت",
        name: "اردیبهشت",
      },
      {
        value: "خرداد",
        name: "خرداد",
      },
      {
        value: "تیر",
        name: "تیر",
      },
      {
        value: "مرداد",
        name: "مرداد",
      },
      {
        value: "شهریور",
        name: "شهریور",
      },
      {
        value: "مهر",
        name: "مهر",
      },
      {
        value: "آبان",
        name: "آبان",
      },
      {
        value: "آذر",
        name: "آذر",
      },
      {
        value: "دی",
        name: "دی",
      },
      {
        value: "بهمن",
        name: "بهمن",
      },
      {
        value: "اسفند",
        name: "اسفند",
      },
    ];
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm();
   useEffect(()=>{
    getMagazineDetailHandler()
   },[])
   const getMagazineDetailHandler=async()=>{
       const data=await getMagazineDetailAPI(info.id)
       setDetailsState(data.data)
       setSelectedDay({
        year:parseInt(data.data.publishDate.split('/')[0]),
        month:parseInt(data.data.publishDate.split('/')[1]),
        day:parseInt(data.data.publishDate.split('/')[2]),
    })
       setSelectedDay2({
        year:parseInt(data.data.endRequestDate.split('/')[0]),
        month:parseInt(data.data.endRequestDate.split('/')[1]),
        day:parseInt(data.data.endRequestDate.split('/')[2]),
    })
   }
    const onSubmit = (data) => {
      setLoadingState(true);
      let formData = new FormData();
      formData.append("Title", data.Title);
      formData.append("FrontCoverPrice", data.FrontCoverPrice);
      formData.append("BackCoverPrice", data.BackCoverPrice);
      formData.append("PricePerPage", data.PricePerPage);
      formData.append("Month", magazineMonth);
      formData.append("Description", description);
      formData.append('PublishDate',`${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`)
      formData.append('EndRequestDate',`${selectedDay2.year}/${selectedDay2.month}/${selectedDay2.day}`)
      EditMagazineAPI(formData,(succ,res)=>{
          if(succ){
              succToast(res.data.message)
              setLoadingState(false)
              closeModal()
              getUpdate()
              reset()
          }
          else{
              errorToast(res.data.errors[0])
              setDetailsState(false)
          }
      })
  
    };
    let loadingHandler=null
    if(detailsState===undefined){
        loadingHandler=<LoadingPage show={true}/>
    }
    else{
        loadingHandler=<form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <ul className={Style.InputHolder}>
          <li>
            <label htmlFor="Title">نام مجله</label>
            <input
              {...register("Title", { required: true })}
              className={errors.Title ? "valid" : "text"}
              autoComplete="Title"
              type="mail"
              name="Title"
              defaultValue={detailsState.title}
            />
          </li>
          <li>
          <label htmlFor="month"> ماه مجله</label>
            <select name="month" id="month" defaultValue={detailsState.month} className="text" onChange={e=>setMagazineMonth(e.target.value)}>
              <option value="0" disabled>
                یک ماه را انتخاب کنید
              </option>
              {month.map((item,index)=>{
                 return(
                  <option value={item.value} key={index}>{item.name}</option>
                 )
              })
              }
            </select>
          </li>
          <li>
            <label htmlFor="PublishDate"> تاریخ عرضه</label>
            <DatePickerInRange id='PublishDate' selectedDayRange={selectedDay} setSelectedDayRange={setSelectedDay}/>
        </li>
        <li>
            <label htmlFor="EndRequestDate"> تاریخ بسته شدن ثبت نام</label>
            <DatePickerInRange id='EndRequestDate' selectedDayRange={selectedDay2} setSelectedDayRange={setSelectedDay2}/>
        </li>
        <li>
          <label htmlFor="PricePerPage"> قیمت هر صفحه</label>
          <input
            {...register("PricePerPage", { required: true })}
            className={errors.PricePerPage ? "valid" : "text"}
            autoComplete="PricePerPage"
            type="number"
            name="PricePerPage"
            defaultValue={detailsState.pricePerPage}
          />
        </li>
        <li>
          <label htmlFor="BackCoverPrice"> قیمت پشت جلد</label>
          <input
            {...register("BackCoverPrice", { required: true })}
            className={errors.BackCoverPrice ? "valid" : "text"}
            autoComplete="BackCoverPrice"
            type="number"
            name="BackCoverPrice"
            defaultValue={detailsState.backCoverPrice}
          />
        </li>
        <li>
          <label htmlFor="FrontCoverPrice"> قیمت روی جلد</label>
          <input
            {...register("FrontCoverPrice", { required: true })}
            className={errors.FrontCoverPrice ? "valid" : "text"}
            autoComplete="FrontCoverPrice"
            type="number"
            name="FrontCoverPrice"
            defaultValue={detailsState.frontCoverPrice}
          />
        </li>
        
        </ul>
        <div className={Style.DescHolder}>
          <label htmlFor="description">توضیحات مجله</label>
    
          <Editor desc={description} setdescription={setDescription} defaultValue={detailsState.description}/>
        </div>
    
        <Button
          className="w-50 my-3"
          type="submit"
          disabled={loadingState ? true : false}
        >
          {loadingState ? (
            <small>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
                لطفا صبرکنید
            </small>
          ) : (
            "ثبت"
          )}
        </Button>
      </form>
    }
  return (
    <>
    {
        loadingHandler
    }
    </>
  )
}

export default EditMagazineForm