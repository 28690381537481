import React from 'react'
import UserListContainer from '../../../Container/UserListContainer/UserListContainer'
import Layout from '../Layout'

const UserList = () => {
  return (
    <Layout><UserListContainer/></Layout>
  )
}

export default UserList