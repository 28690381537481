import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingPage from "../../LoadingPage/LoadingPage";
import Style from "./AddForeignPropertyProjectForm.module.scss";
import Select from "react-select";
import Editor from "../../Editor/Editor";
import { Button, Spinner } from "react-bootstrap";
import { AddForeignProjectPropertyAPI, getCountryListAPI, getForeignCitylistAPI } from "../../../API/BarangizanServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
const AddForeignPropertyProjectForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const [description, setDescription] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCitiestList] = useState([]);
  const [coutrySelect, setCountrySelect] = useState("");
  const [citySelect, setCitySelect] = useState("");
  const[floorNumber,setFloorNumber]=useState(0)
  const [ImageUrls, setImageUrls] = useState();
  const [ImageFile, setImageFile] = useState();
  const[loadingType,setLoadingType]=useState(true)
  const imageChange = (evt) => {
    setImageFile(evt.target.files);
    let imageUrlList = [];
    Array.from(evt.target.files).forEach((element) =>
      imageUrlList.push(URL.createObjectURL(element))
    );
    setImageUrls(imageUrlList);
  };
  useEffect(() => {
    getDataHandler();
  }, []);

  const getDataHandler = async () => {
    const data = await getCountryListAPI();
    setCountryList(data.data.list);

    setLoadingType(false);
  };
  const getCityListHandler = async (info) => {
    setCountrySelect(info);
    const cities = await getForeignCitylistAPI(info.value);
    setCitiestList(cities.data.list);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();
  const runCallback = (cb) => {
    return cb();
  };
  const onSubmit = (data) => {
    setLoadingState(true)
    let formData=new FormData()
    formData.append('Title',data.title)
    formData.append('Country',coutrySelect.label)
    formData.append('CountryId',coutrySelect.value)
    formData.append('City',citySelect.label)
    formData.append('Description',description)
    formData.append('Address',data.Address)
    formData.append("ExclusiveVisitPrice", data.ExclusiveVisitPrice);
    formData.append("VirtualVisitPrice", data.VirtualVisitPrice);
    formData.append('ForeignEstateSellingType',data.SalesCondition)
    for (let index = 0; index < ImageFile.length; index++) {
      formData.append(`Images[${index}].Item`, ImageFile[index]);
    }
    for(let j=0;j<data.block;j++){
      formData.append(`Blocks[${j}].Title`, data.Blocks[j].Title);
      formData.append(`Blocks[${j}].FloorCount`, data.Blocks[j].FloorCount);
    }
    AddForeignProjectPropertyAPI(formData,(succ,res)=>{
      if(succ){
        succToast(res.data.message)
        setLoadingState(false)
        reset()
      }
      else{
        errorToast(res.data.errors[0])
        setLoadingState(false)
      }
    })

  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      <h3>افزودن پروژه خارجی</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="title">عنوان پروژه</label>
          <input
            {...register("title", { required: false })}
            className={errors.title ? "valid" : "text"}
            autoComplete="title"
            type="text"
            name="title"
          />
        </li>
        <li>
          <label  htmlFor="SalesCondition" >شرایط فروش</label>
          <select defaultValue={0} className={"text"} name="" id="" {...register("SalesCondition", { required: false })}>
            <option value={0}>نقد</option>
            <option value={1}>اقساط</option>
            <option value={2}>نقد،اقساط</option>
          </select>
        </li>
        <li>
          <label htmlFor="country">کشور</label>
          <div className={Style.selectOption}>
            <Select
              {...register("country", { required: false })}
                onChange={getCityListHandler}
                options={countryList.map(item=>{
                  return ({ value: item.id, label: item.title })
                })}
              name="country"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isLoading={loadingType}
            />
          </div>
        </li>
        <li>
          <label htmlFor="city">شهر</label>
          <div className={Style.selectOption}>
            <Select
              {...register("city", { required: false })}
                onChange={setCitySelect}
                options={citiesList.map(item=>{
                  return ({ value: item.id, label: item.title })
                })}
              name="city"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isLoading={loadingType}
            />
          </div>
        </li>
        <li>
          <label htmlFor="ExclusiveVisitPrice">قیمت بازدید اختصاصی </label>
          <input
            {...register("ExclusiveVisitPrice", { required: true })}
            className={errors.ExclusiveVisitPrice ? "valid" : "text"}
            autoComplete="ExclusiveVisitPrice"
            type="number"
            name="ExclusiveVisitPrice"
          />
        </li>
        <li>
          <label htmlFor="VirtualVisitPrice">قیمت بازدید مجازی </label>
          <input
            {...register("VirtualVisitPrice", { required: true })}
            className={errors.VirtualVisitPrice ? "valid" : "text"}
            autoComplete="VirtualVisitPrice"
            type="number"
            name="VirtualVisitPrice"
          />
        </li>
        <li>
          <label htmlFor="block">تعداد بلوک</label>
          <input
            {...register("block", { required: false })}
            className={errors.block ? "valid" : "text"}
            autoComplete="block"
            type="number"
            min={0}
            name="block"
            onChange={e=>setFloorNumber(e.target.value)}
          />
        </li>
      </ul>
      <ul className={Style.InputHolder}>
        {runCallback(() => {
          const row = [];
          for (var i =0; i < parseInt(floorNumber) ; i++) {
            row.push(
              <li key={i} className={Style.EachBlock}>
                <label htmlFor={`Blocks[${i}].Title`}>نام بلوک</label>
                <input
                  {...register(`Blocks[${i}].Title`, { required: false })}
                  className={errors.title ? "valid" : "text"}
                  autoComplete={`Blocks[${i}].Title`}
                  type="text"
                  name={`Blocks[${i}].Title`}
                />
                <label htmlFor={`Blocks[${i}].FloorCount`}>تعداد طبقه</label>
                <input
                  {...register(`Blocks[${i}].FloorCount`, { required: false })}
                  className={errors.title ? "valid" : "text"}
                  autoComplete={`Blocks[${i}].FloorCount`}
                  type="number"
                  name={`Blocks[${i}].FloorCount`}
                />
              </li>
            );
          }
          return row;
        })}
      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="Address">آدرس ملک</label>
        <textarea
          {...register("Address", { required: true })}
          className={errors.Address ? "valid" : "text"}
          autoComplete="Address"
          type="text"
          name="Address"
        />
      </div>

      <div className={Style.DescHolder}>
        <label htmlFor="description">توضیحات پروژه</label>
        <Editor
          desc={description}
          setdescription={setDescription}
          defaultValue={""}
        />
      </div>
      

      <div className={Style.ImagePart}>
        <label htmlFor="imageFile" className={Style.ImageLabel}>
        <FontAwesomeIcon icon={faImage}/>  انتخاب تصویر 
          <input
            type="file"
            name="imageFile"
            id="imageFile"
            accept="image/*"
            style={{ display: "none" }}
            multiple
            onChange={(e) => imageChange(e)}
          />
        </label>
        <ul className={Style.InputHolder}>
        {ImageUrls &&
          ImageUrls.map((item, index) => {
            return (
              <li key={index} className={Style.ImagePerview}>
                <img
                  src={item}
                  className={Style.ImagePerview}
                  alt=""
                  onClick={() => window.open(item, "_blank")}
                />
              </li>
            );
          })}
      </ul>
      </div>
      <Button
        className="w-50 my-3"
        type="submit"
        disabled={loadingState ? true : false}
      >
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  );
};

export default AddForeignPropertyProjectForm;
