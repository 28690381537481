import React from 'react'
import PropertyReservationRequestsContainer from '../../../Container/PropertyReservationRequestsContainer/PropertyReservationRequestsContainer'
import Layout from '../Layout'

const PropertyReservationRequests = () => {
  return (
    <Layout><PropertyReservationRequestsContainer/></Layout>
  )
}

export default PropertyReservationRequests