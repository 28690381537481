import React from 'react'
import CurrenciesListContainer from '../../../Container/CurrenciesListContainer/CurrenciesListContainer'
import Layout from '../Layout'

const CurrenciesList = () => {
  return (
    <Layout><CurrenciesListContainer/></Layout>
  )
}

export default CurrenciesList