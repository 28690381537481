import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AddUserAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Style from './AddUserForm.module.scss'
const AddUserForm = () => {
    const [loadingState, setLoadingState] = useState(false);
    const [userRegion, setUserRegion] = useState(false);
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm();
    const onSubmit = (data) => {
        if(data.UserType==="-1"){
            errorToast('لطفا نوع شخص را مشخص کنید!')
        }
        else{
            setLoadingState(true)
            let info={
              UserType:parseInt(data.UserType),
              IsForeign:data.UserRegion==='true',
              FirstName:data.FirstName,
              LastName:data.LastName,
              PhoneNumber:data.PhoneNumber,
              Email:data.Email,
              Password:data.Password,
              ConfirmPassword:data.ConfirmPassword
            }
            AddUserAPI(info,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    reset()
                    setLoadingState(false)
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        }
        
    };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <h3>افزودن کاربر</h3>
      <ul className={Style.InputHolder}>
        <li>
        
        <label htmlFor="UserType"> نوع شخص</label>
          <select name="UserType" id="UserType" defaultValue={"-1"} className="text" {...register("UserType", { required: true })}>
            <option value="-1" disabled>
              نوع شخص را مشخص کنید
            </option>
            <option value={0}>حقیقی</option>
            <option value={1}>حقوقی</option>
          </select>
        
        </li>
        <li>
        
        <label htmlFor="UserRegion">  نوع کاربر</label>
          <select name="UserRegion" id="UserRegion"  className="text" {...register("UserRegion", { required: true })} onChange={e=>setUserRegion(e.target.value)}>
            <option value="-1" disabled>
              نوع کاربر را مشخص کنید
            </option>
            <option value={false}>داخلی</option>
            <option value={true}>خارجی</option>
          </select>
        
        </li>
        <li>
          <label htmlFor="FirstName">نام کاربر</label>
          <input
            {...register("FirstName", { required: true })}
            className={errors.FirstName ? "valid" : "text"}
            autoComplete="FirstName"
            name="FirstName"
          />
        </li>
        
        <li>
          <label htmlFor="LastName">نام خانوادگی کاربر</label>
          <input
            {...register("LastName", { required: true })}
            className={errors.LastName ? "valid" : "text"}
            autoComplete="LastName"
            name="LastName"
          />
        </li>
        
        <li>
          <label htmlFor="PhoneNumber">شماره تلفن</label>
          <input
            {...register("PhoneNumber", { required: !userRegion })}
            className={errors.PhoneNumber ? "valid" : "text"}
            autoComplete="PhoneNumber"
            name="PhoneNumber"
            type={'tel'}
          />
        </li>
        
        <li>
          <label htmlFor="Email">ایمیل</label>
          <input
            {...register("Email", { required: userRegion })}
            className={errors.Email ? "valid" : "text"}
            autoComplete="Email"
            name="Email"
            type={'mail'}
          />
        </li>
        <li>
          <label htmlFor="Password"> رمزعبور</label>
          <input
            {...register("Password", { required: true })}
            className={errors.Password ? "valid" : "text"}
            autoComplete="Password"
            type="Password"
            name="Password"
          />
        </li>
        <li>
          <label htmlFor="ConfirmPassword"> تکرار رمزعبور</label>
          <input
            {...register("ConfirmPassword", { required: true })}
            className={errors.ConfirmPassword ? "valid" : "text"}
            autoComplete="ConfirmPassword"
            type="password"
            name="ConfirmPassword"
          />
        </li>
      </ul>
      <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> 
            لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  )
}

export default AddUserForm