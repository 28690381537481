import React from 'react'
import AddAffidavitForm from '../../../Components/Forms/AddAffidavitForm/AddAffidavitForm'
import TitleHeader from '../../../Components/TitleHeader/TitleHeader'

const AddaffidavitContainer = () => {
  return (
    <div className='flexStyle'>
      <TitleHeader text={'افزودن قولنامه'}/>
      <AddAffidavitForm/>
    </div>
  )
}

export default AddaffidavitContainer