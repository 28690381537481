
import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { ChangePasswordAdminApi } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Style from './EditAdminPasswordForm.module.scss'
import { useForm } from "react-hook-form";
const EditAdminPasswordForm = ({info}) => {
    const [loadingState, setLoadingState] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  
  const onSubmit = (data) => {
      setLoadingState(true)
    let variables={
        username:info.userName,
        password:data.password
    }
    ChangePasswordAdminApi(variables,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            reset()
            setLoadingState(false)
        }
        else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
        }
    })
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="password"> رمزعبور جدید</label>
          <input
            {...register("password", { required: true })}
            className={errors.password ? "valid" : "text"}
            autoComplete="password"
            type="password"
            name="password"
          />
        </li>
      </ul>
      <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> 
            لطفا صبرکنید
          </small>
        ) : (
          "ویرایش"
        )}
      </Button>
    </form>
  )
}

export default EditAdminPasswordForm