import React from 'react'
import AddForeignPropertyProjectContainer from '../../../Container/AddForeignPropertyProjectContainer/AddForeignPropertyProjectContainer'
import Layout from '../Layout'

const AddForeignPropertyProject = () => {
  return (
    <Layout><AddForeignPropertyProjectContainer/></Layout>
  )
}

export default AddForeignPropertyProject