import React from 'react'
import AddPropertyForm from '../../Components/Forms/AddPropertyForm/AddPropertyForm'

const AddProepertyContainer = () => {
  return (
    <div className='flexStyle'>
      <AddPropertyForm/>
    </div>
  )
}

export default AddProepertyContainer