import React from 'react'
import MagazineListContainer from '../../../Container/MagazineListContainer/MagazineListContainer'
import Layout from '../Layout'

const MagazineList = () => {
  return (
    <Layout><MagazineListContainer/></Layout>
  )
}

export default MagazineList