import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import LoadingPage from '../../LoadingPage/LoadingPage';
import Style from './AddPropertyTypeForm.module.scss'
import {AddPropertyTypeAPI} from '../../../API/BarangizanServices'
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
const AddPropertyTypeForm = () => {
    const [loadingState, setLoadingState] = useState(false);

      const {
        register,
        formState: { errors },
        handleSubmit,
        reset
      } = useForm();
      const onSubmit = (data) =>{
          setLoadingState(true)
          let formData = new FormData();
          formData.append("Title", data.Text);
          AddPropertyTypeAPI(formData,(succ,res)=>{
              if(succ){
                  succToast(res.data.message)
                  setLoadingState(false)
                  reset()
              }
              else{
                  errorToast(res.data.errors[0])
                  setLoadingState(false)
              }
          })
          
      }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      <h3>افزودن ویژگی ملک</h3>
      <ul className={Style.InputHolder}>
       
        <li>
          <label htmlFor="Text">عنوان نوع ملک</label>
          <input
            {...register("Text", { required: true })}
            className={errors.Text ? "valid" : "text"}
            autoComplete="Text"
            name="Text"
          />
        </li>
      </ul>




      <Button className="w-100 my-3" type="submit">
        ثبت
      </Button>
    </form>
  )
}

export default AddPropertyTypeForm