import React from 'react'
import AddMagazineContainer from '../../../Container/AddMagazineContainer/AddMagazineContainer'
import Layout from '../Layout'

const AddMagazine = () => {
  return (
    <Layout>
        <AddMagazineContainer/>
    </Layout>
  )
}

export default AddMagazine