import React from 'react'
import PropertyListContainer from '../../../Container/PropertyListContainer/PropertyListContainer'
import Layout from '../Layout'

const PropertyList = () => {
  return (
    <Layout><PropertyListContainer/></Layout>
  )
}

export default PropertyList