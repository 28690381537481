import React, { useEffect } from 'react'
import Style from './AddTiketForm.module.scss'
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState } from 'react';
import Select from "react-select";
import { CreateTiketAPI, getAllUserListAPI } from '../../../API/BarangizanServices';
import { Checkbox } from 'antd';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
const AddTiketForm = () => {
    const[loadingState,setLoadingState]=useState()
    const[userInfo,setUserInfo]=useState()
    const[UsersList,setUsersList]=useState()
    const[notifCheckbox,setNotifCheckbox]=useState(false)
    const[UserListShow,setUserListShow]=useState(false)
    const priority = [
        {
          value: "خیلی کم",
          name: "0",
        },
        {
          value: "کم",
          name: "1",
        },
        {
          value: "متوسط",
          name: "2",
        },
        {
          value: "زیاد",
          name: "3",
        },
        {
          value: "خیلی زیاد",
          name: "0",
        },
      ];
      useEffect(()=>{
        getUserListHandler()
      },[])
      const getUserListHandler=async()=>{
          const data=await getAllUserListAPI(1)
          setUsersList(data.data.users)
      }
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
      } = useForm();

      const TypeChangeHandler=(e)=>{
        if(e.target.value==="0"){ setUserListShow(true)}
        else{ setUserListShow(false)}

      }


      const onSubmit = (data) => {
          setLoadingState(true)
          let formData = new FormData();
          formData.append('Username',userInfo.value)
          formData.append('Subject',data.Subject)
          formData.append('TicketPriorities',data.priority)
          formData.append('Message',data.Message)
          formData.append('File',data.File[0])
          formData.append('SendType',+data.SendType)
          formData.append('IsNotif',notifCheckbox)
          CreateTiketAPI(formData,(succ,res)=>{
              if(succ){
                  setLoadingState(false)
                  reset()
                  succToast(res.data.message)
              }
              else{
                  setLoadingState(false)
                  errorToast(res.data.errors[0])
              }
          })
      }
  return (
    <div className='flexStyle'>
        {UsersList===undefined?<Spinner  animation="border" variant='warning'/>:
        <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <h3>بازکردن تیکت </h3>
        <ul className={Style.InputHolder}>
          <li>
            <label htmlFor="Subject">موضوع تیکت</label>
            <input
              {...register("Subject", { required: true })}
              className={errors.Subject ? "valid" : "text"}
              autoComplete="Subject"
              type="text"
              name="Subject"
            />
          </li>
          <li>
          <label htmlFor="priority">اهمیت تیکت</label>
            <select name="priority" id="priority" defaultValue={"0"} className="text"  {...register("priority", { required: true })}>
              <option value="0" disabled>
                یک گزینه را انتخاب کنید
              </option>
              {priority.map((item,index)=>{
                 return(
                  <option value={item.name} key={index}>{item.value}</option>
                 )
              })
              }
            </select>
          </li>
          <li>
          <label htmlFor="SendType">نوع ارسال را مشخص کنید</label>
            <select name="SendType" id="SendType" defaultValue={"-1"} className="text"  {...register("SendType", { required: true })} onChange={TypeChangeHandler}>
              <option value="-1" disabled>
                یک گزینه را انتخاب کنید
              </option>
              <option value={0}>به یک کاربر</option>
              <option value={3}>به کاربران خارجی</option>
              <option value={2}>به کاربران داخلی</option>
              <option value={1}>به همه کاربران</option>
            </select>
          </li>
          {
            UserListShow?<li>
            <label htmlFor="type"> کاربر</label>
            <div className={Style.selectOption}>
              <Select
                {...register("type", { required: false })}
                onChange={setUserInfo}
                options={
                  UsersList.map(item=>{
                      return(
                          {
                              value:item.userName,
                              label:`${item.firstName} ${item.lastName} ${item.userName}`
                          }
                      )
                  })
                }
                name="type"
                placeholder="انتخاب کنید"
                className={Style.selectOptionsearch}
               
              />
            </div>
          </li>:null
          }
          <li>
              <div className={Style.CheckBoxHolder}>
                  <Checkbox onChange={(e)=>setNotifCheckbox(e.target.checked)}>اطلاعیه</Checkbox>
              </div>
          </li>
  
        </ul>
        <div className={Style.DescHolder}>
          <label htmlFor="Message">پیام</label>
          <textarea
            {...register("Message", { required: true })}
            className={errors.Message ? "valid" : "text"}
            autoComplete="Message"
            type="text"
            name="Message"
          />
        </div>
         <div className='w-100'>
             <label htmlFor="fileUpload" className={Style.uploadLabel}>آپلود فایل</label>
             <input id='fileUpload' type={"file"} style={{display:"none"}} {...register("File", { required: false })}/>
        </div>       
  
        <Button
          className="w-50 my-3"
          type="submit"
          disabled={loadingState ? true : false}
        >
          {loadingState ? (
            <small>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
                لطفا صبرکنید
            </small>
          ) : (
            "ثبت"
          )}
        </Button>
      </form>}
    </div>
  )
}

export default AddTiketForm