import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { getPplRegistredTourDetailAPI } from "../../../API/BarangizanServices";
import AdditionalPerson from "./AdditionalPerson/AdditionalPerson";
import Style from "./PplRegistredTourDetails.module.scss";
const PplRegistredTourDetails = ({ info }) => {
  const [detailState, setDetailState] = useState();
  useEffect(() => {
    getDetailDataHandler();
  }, []);
  const getDetailDataHandler = async () => {
    const data = await getPplRegistredTourDetailAPI(info.id);
    setDetailState(data.data.tourRegister);
  };

  return (
    <section className="flexStyle">
      {detailState === undefined ? (
        "درحال دریافت اطلاعات"
      ) : (
        <>
          <ul className={Style.DetailHolder}>
            <li>
              <p className="text-primary">نام: </p>
              <h6>{`${detailState.firstName} ${detailState.lastName}`}</h6>
            </li>
            <li>
              <p className="text-primary">شماره همراه: </p>
              <h6>{` ${detailState.phoneNumber}`}</h6>
            </li>
            <li>
              <p className="text-primary"> ایمیل: </p>
              <h6>{` ${detailState.email}`}</h6>
            </li>
            <li>
              <p className="text-primary"> تاریخ ثبت نام: </p>
              <h6>{` ${detailState.date}`}</h6>
            </li>
            <li>
              <p className="text-primary">کدملی: </p>
              <h6>{` ${detailState.nationalCode}`}</h6>
            </li>
            <li>
              <p className="text-primary">شماره واتس اپ: </p>
              <h6>{` ${detailState.whatsapp}`}</h6>
            </li>
            <li>
              <p className="text-primary"> معلولیت: </p>
              <h6>{` ${detailState.disability}`}</h6>
            </li>
            <li>
              <p className="text-primary"> تصویر شناسنامه: </p>
              <Button
                onClick={() =>
                  window.open(
                    `https://api.barangizan.ir${detailState.idCardImage}`,
                    "_blank"
                  )
                }
              >
                نمایش
              </Button>
            </li>
            <li>
              <p className="text-primary"> تصویر روی کارت ملی: </p>
              <Button
                onClick={() =>
                  window.open(
                    `https://api.barangizan.ir${detailState.nationalCodeFrontImage}`,
                    "_blank"
                  )
                }
              >
                نمایش
              </Button>
            </li>
            <li>
              <p className="text-primary"> تصویر پشت کارت ملی: </p>
              <Button
                onClick={() =>
                  window.open(
                    `https://api.barangizan.ir/${detailState.nationalCodeBackImage}`,
                    "_blank"
                  )
                }
              >
                نمایش
              </Button>
            </li>
          </ul>
          {detailState.tourParticipants.length!==0?detailState.tourParticipants.map(item=>{
              return(
                <AdditionalPerson data={item} key={item.id}/>
              )
          }):null}
        </>
      )}
    </section>
  );
};

export default PplRegistredTourDetails;
