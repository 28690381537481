import { SearchOutlined } from "@ant-design/icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faCommentsDollar, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Space, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { separate } from "../../../Utils/Seprate/SeprateThreeNumber";
import Style from "./MagazinePropertyRegisterTabel.module.scss";
class MagazinePropertyRegisterTabel extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sortedInfo: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let dataSoruce = [];
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSoruce.push({
          key:index,
          user:`${element.user.firstName} ${element.user.lastName}`,
          title:element.estate.title,
          code:element.estate.code,
          userName:element.user.userName,
          magazineName:element.magazine.title,
          price:element.price===0?'رایگان':element.price,
          frontPage:element.iwantFrontCover?<FontAwesomeIcon icon={faCheck} color="green"/>:<FontAwesomeIcon icon={faTimes} color="red"/>,
          BackPage:element.iwantBackCover?<FontAwesomeIcon icon={faCheck} color="green"/>:<FontAwesomeIcon icon={faTimes} color="red"/>,
          midelPage:element.pageCount>0?<FontAwesomeIcon icon={faCheck} color="green"/>:<FontAwesomeIcon icon={faTimes} color="red"/>,
        });
        index = index + 1;
      });

    const columns = [
      {
        title: "نام کاربر",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "کدملک",
        dataIndex: "code",
        key: "code",

        ...this.getColumnSearchProps("code"),
      },
      {
        title: "نام مجله",
        dataIndex: "magazineName",
        key: "magazineName",

        ...this.getColumnSearchProps("magazineName"),
      },
      {
        title: "قیمت",
        dataIndex: "price",
        key: "price",

        ...this.getColumnSearchProps("price"),
      },
      {
        title: "صفحه اول جلد",
        dataIndex: "frontPage",
        key: "frontPage",

        ...this.getColumnSearchProps("frontPage"),
      },
      {
        title: "صفحه آخر جلد",
        dataIndex: "BackPage",
        key: "BackPage",

        ...this.getColumnSearchProps("BackPage"),
      },
      {
        title: "صفحه داخلی مجله",
        dataIndex: "midelPage",
        key: "midelPage",

        ...this.getColumnSearchProps("midelPage"),
      },
      
      {
        title: "عملیات",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              danger
                onClick={() => this.props.showModal(2, record)}
                
            >بازکردن تیکت  <FontAwesomeIcon icon={faCommentsDollar} /></Button>
          </Space>
        ),
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          loading={this.props.data === undefined ? true : false}
          columns={columns}
          dataSource={dataSoruce}
          pagination={{position:['bottomCenter'],total:this.props.totalPages,pageSize:30}}
        />
      </div>
    );
  }
}

export default MagazinePropertyRegisterTabel;
