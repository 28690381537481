import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Style from "./AddPropertyForm.module.scss";
import Select from "react-select";
import { AddPropertyAPI, getAllUserListAPI, getCityApi, getCountryListAPI, getFeaturesListOptionsAPI, getPropertyTypesAPI, getProvinceApi } from "../../../API/BarangizanServices";
import { Button } from "react-bootstrap";
import { Alert } from "antd";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import Editor from "../../Editor/Editor";
const AddPropertyForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const [province, setProvince] = useState([]);
  // const [featuresCountState, setFeaturesCountState] = useState(0);
  const [provinceName, setProvinceName] = useState("");
  const [propertyTypeValue, setPropertyValue] = useState("");
  const [city, setCity] = useState([]);
  const [cityName, setCityName] = useState("");
  const[featureListState,setFeatureListState]=useState()
  const[UserList,setUserList]=useState()
  const[userSelect,setUserSelect]=useState()
  const[loadingType,setLoadingType]=useState(true)
  const[TahatorState,setTahatorState]=useState(false)
  const [countryList, setCountryList] = useState(null);
  const [countryListTahator, setCountryListTahator] = useState(null);
  useEffect(() => {
    getDataHandler();
  }, []);

  const getDataHandler = async () => {
    const countries = await getCountryListAPI();
    setCountryList(countries.data.list);
    const data = await getProvinceApi();
    setProvince(data.data);
    const features=await getFeaturesListOptionsAPI(0)
    setFeatureListState(features.data.data)
    const users=await getAllUserListAPI(1)
    setUserList(users.data.users)
    const propertyType=await getPropertyTypesAPI()
    setPropertyType(propertyType.data.list)
    setLoadingType(false)
  };

  const getCityListHandler = async (id) => {
    setProvinceName(id.label);
    const data = await getCityApi(id.label);
    setCity(data.data);
  };
  let provinceList = [];
  province.forEach((element, index) => {
    provinceList.push({ value: index, label: element });
  });
  let CityList = [];
  city.forEach((element, index) => {
    CityList.push({ value: index, label: element });
  });
  const [propertyType,setPropertyType]=useState([])
  const [description, setDescription] = useState('');
  const [ImageUrl, setImageUrl] = useState();
  const [ImageFile, setImageFile] = useState();
  const imageChange = (evt) => {
    setImageFile(evt.target.files[0]);
    setImageUrl(URL.createObjectURL(evt.target.files[0]));
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control
  } = useForm();
  const onSubmit = (data) => {

    if(description==="") return errorToast('توضیحات ملک را وارد کنید')
    if(userSelect===undefined) return errorToast('مالک را مشخص کنید')
      setLoadingState(true)
      let formData = new FormData();
    formData.append("EstatesTypeId", propertyTypeValue.value);
    formData.append("EstatesType", propertyTypeValue.label);
    formData.append("title", data.title);
    formData.append("IsTahator", TahatorState);
    formData.append("TahatorCountries", countryListTahator);
    formData.append("description", description);
    formData.append("province", provinceName);
    formData.append("city", cityName.label);
    formData.append("Metraj", data.Metraj);
    formData.append("price", data.price);
    formData.append("discountedPrice", data.discountedPrice);
    formData.append("PrePaymentPrice", data.PrePaymentPrice);
    formData.append("ExclusiveVisitPrice", data.ExclusiveVisitPrice);
    formData.append("VirtualVisitPrice", data.VirtualVisitPrice);
    formData.append("Address", data.Address);
    formData.append("OwnerName", userSelect&&userSelect.label.split('-')[0]);
    formData.append("OwnerPhoneNumber", userSelect.value);
    formData.append("Image", ImageFile);
    for (var i = 0; i < parseInt(featureListState.length); i++) {
      formData.append(`Features[${i}].Key`, data.Features[i].Key);
      let featureString=[]
      data.Features[i].value.forEach(element=>{
        featureString.push(element.label)
      })
      formData.append(`Features[${i}].Value`, featureString.toString());
    }


    AddPropertyAPI(formData,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            setLoadingState(false)
            reset()
            setImageUrl()
        }
        else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
        }
    })
};
  const TahatorHandler=(e)=>{
    setTahatorState(e.target.checked)
  }
  const seletCountryTahatorHandler=(e)=>{
    let CountryListSelected=[]
    e.forEach(element => {
      CountryListSelected.push(element.label)
    });
    setCountryListTahator(CountryListSelected.join("_"))
  }
  

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      <h3>افزودن ملک</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="type">نوع ملک</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={setPropertyValue}
              options={propertyType.map(item=>{
                return ({ value: item.id, label: item.title })
              })}
              name="type"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isLoading={loadingType}
            />
          </div>
        </li>
        <li>
          <label htmlFor="title">عنوان ملک</label>
          <input
            {...register("title", { required: false })}
            className={errors.title ? "valid" : "text"}
            autoComplete="title"
            type="text"
            name="title"
          />
        </li>

        <li>
          <label htmlFor="province"> استان</label>
          <div className={Style.selectOption}>
            <Select
              {...register("province", { required: false })}
              onChange={getCityListHandler}
              options={provinceList}
              name="province"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
            />
          </div>
        </li>
        <li>
          <label htmlFor="city"> شهر</label>
          <div className={Style.selectOption}>
            <Select
              {...register("city", { required: false })}
              options={CityList}
              name="city"
              onChange={setCityName}
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
            />
          </div>
        </li>

        <li>
          <label htmlFor="Metraj">متراژ </label>
          <input
            {...register("Metraj", { required: true })}
            className={errors.Metraj ? "valid" : "text"}
            autoComplete="Metraj"
            type="text"
            name="Metraj"
          />
        </li>
        <li>
          <label htmlFor="price">قیمت </label>
          <input
            {...register("price", { required: true })}
            className={errors.price ? "valid" : "text"}
            autoComplete="price"
            type="number"
            name="price"
            placeholder="به تومان"
          />
        </li>
        <li>
          <label htmlFor="discountedPrice">قیمت با تخفیف </label>
          <input
            {...register("discountedPrice", { required: true })}
            className={errors.discountedPrice ? "valid" : "text"}
            autoComplete="discountedPrice"
            type="number"
            name="discountedPrice"
            placeholder="به تومان"
          />
        </li>
        <li>
          <label htmlFor="PrePaymentPrice">قیمت پیش پرداخت </label>
          <input
            {...register("PrePaymentPrice", { required: true })}
            className={errors.PrePaymentPrice ? "valid" : "text"}
            autoComplete="PrePaymentPrice"
            type="number"
            name="PrePaymentPrice"
            placeholder="به تومان"
          />
        </li>
        <li>
          <label htmlFor="ExclusiveVisitPrice">قیمت بازدید اختصاصی </label>
          <input
            {...register("ExclusiveVisitPrice", { required: true })}
            className={errors.ExclusiveVisitPrice ? "valid" : "text"}
            autoComplete="ExclusiveVisitPrice"
            type="number"
            placeholder="به تومان"
            name="ExclusiveVisitPrice"
          />
        </li>
        <li>
          <label htmlFor="VirtualVisitPrice">قیمت بازدید مجازی </label>
          <input
            {...register("VirtualVisitPrice", { required: true })}
            className={errors.VirtualVisitPrice ? "valid" : "text"}
            autoComplete="VirtualVisitPrice"
            type="number"
            placeholder="به تومان"
            name="VirtualVisitPrice"
          />
        </li>
        <li>
          <label htmlFor="type">  مالک</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={setUserSelect}
              options={
                UserList&&UserList.map(item=>{
                  return({label:`${item.firstName} ${item.lastName}- ${item.userName}`,value:item.userName})
                })
              }
              name="type"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
             
            />
          </div>
        </li>
        <li>
        <label htmlFor="Tahator" className={Style.TahatorCheckBox}>
        <input type={'checkbox'} id="Tahator"  {...register("Tahator", { required: false })} onChange={TahatorHandler}/> تهاتر 
        </label>

        </li>
        {
          TahatorState?<li>
          <label htmlFor="type">  کشور برای تهاتر</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={seletCountryTahatorHandler}
              options={
                countryList?.map(item=>{
                  return({ value: item.id, label: item.title })
                })
              }
              name="type"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isMulti
            />
          </div>
        </li>:null
        }

      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="Address">آدرس ملک</label>
        <textarea
          {...register("Address", { required: true })}
          className={errors.Address ? "valid" : "text"}
          autoComplete="Address"
          type="text"
          name="Address"
        />
      </div>
      <ul className={Style.InputHolder}>
        {
          featureListState&&featureListState.map((item,i)=>{
            return(
              <li key={item.id}>
                <label htmlFor="featureCount">{`  ویژگی ${i+1}` }</label>
                <input
                  {...register(`Features[${i}].Key`)}
                  className={errors.username ? "valid" : "text"}
                  autoComplete={`Features[${i}].Key`}
                  type="text"
                  name={`Features[${i}].Key`}
                  style={{ marginBottom: "0.5rem" }}
                  defaultValue={item.text}
                  readOnly
                />
                  <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <div className={Style.selectOption}>
                    <Select
                       {...register(`Features[${i}].value`)}
                      options={
                          item.options.map(item=>{
                            return(
                              {
                                label:item.text,
                                value:item.text
                              }
                            )
                          })
                      }
                      name={`Features[${i}].value`}
                      // onChange={setFeatureTest}
                      onChange={onChange}
                      placeholder="انتخاب کنید"
                      className={Style.selectOptionsearch}
                      isMulti
                      defaultValue={[{label:"ندارد",value:0}]}
                    />
                  </div> 
                  )}
                  name={`Features[${i}].value`}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={[{label:"ندارد",value:0}]}
                />
              </li>
            )
          })
        }
      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="description">توضیحات ملک</label>
         <Editor
            desc={description}
            setdescription={setDescription}
            defaultValue={''}
        />
      </div>
      <div className={Style.ImagePart}>
        <label htmlFor="imageFile" className={Style.ImageLabel}>
          انتخاب تصویر
          <input
            type="file"
            name="imageFile"
            id="imageFile"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => imageChange(e)}
          />
        </label>
        {ImageUrl && (
          <img src={ImageUrl} className={Style.ImagePerview} alt="" />
        )}
      </div>
      <Alert
        type="warning"
        message=" درصورت نداشتن تخفیف، قیمت و قیمت با تخفیف را یکسان وارد کنید"
        showIcon
        className="w-100"
      />
      <Alert
        type="warning"
        message=" درصورت نداشتن قیمت های بازدید عدد 1 را وارد کنید!"
        showIcon
        className="w-100 mt-2"
      />

      <Button className="w-100 my-3" type="submit">
        ثبت
      </Button>
    </form>
  );
};

export default AddPropertyForm;
