import React, { useEffect, useState } from 'react'
import { getForeignProeprtyDetailAPI, getProeprtyDetailAPI } from '../../../API/BarangizanServices'
import Style from './PropertyTahtorDetail.module.scss'
import { Skeleton } from 'antd';
const PropertyTahtorDetail = ({info}) => {
    const[PropertyDetail,setPropertyDetail]=useState(null)
  useEffect(()=>{
    !info.UserProperteForeign?getDakheliDetailHandler():getKharejiDetailHandler()
  },[])
  const getDakheliDetailHandler=async()=>{
      const data=await getProeprtyDetailAPI(info.UserProperteCode)
      setPropertyDetail(data.data)
  }
  const getKharejiDetailHandler=async()=>{
      const data=await getForeignProeprtyDetailAPI(info.UserProperteCode)
      setPropertyDetail(data.data)
  }
  console.log(PropertyDetail)
  return (
      <>
      {
          PropertyDetail===null?<Skeleton active />:<section className={Style.PropertyDetailsHolder}>
          <div className={Style.ImageHolder}>
              <img src={`https://api.barangizan.ir${PropertyDetail.estate.image}`} alt="property" />
          </div>
          <ul className={Style.PropertyInfoHolder}>
              <li>
                  <h5>نوع ملک</h5>
                  <p>{PropertyDetail.estate.estateType}</p>
              </li>
              <li>
                  <h5>کد ملک</h5>
                  <p>{PropertyDetail.estate.code}</p>
              </li>
              <li>
                  <h5>نام ملک</h5>
                  <p>{PropertyDetail.estate.title}</p>
              </li>
              <li>
                  <h5> آدرس </h5>
                  <p>{PropertyDetail.estate.address}</p>
              </li>
              <li>
                  <h5> {PropertyDetail.estate.country?'کشور':'استان'} </h5>
                  <p> {PropertyDetail.estate.country?PropertyDetail.estate.country:PropertyDetail.estate.province}</p>
              </li>
              <li>
                  <h5> شهر </h5>
                  <p>{PropertyDetail.estate.city}</p>
              </li>
              <li>
                  <h5> کشور های قابل تهاتر </h5>
                  <p>{PropertyDetail.estate.tahatorCountries}</p>
              </li>
              <li>
                  <h5>متراژ</h5>
                  <p>{PropertyDetail.estate.area}</p>
              </li>
              <li>
                  <h5>قیمت</h5>
                  <p>{PropertyDetail.estate.price} {PropertyDetail.estate.currency}</p>
              </li>
              <li>
                  <h5>نام کاربر</h5>
                  <p>{PropertyDetail.estate.ownerName}</p>
              </li>
              <li>
                  <h5>نام کاربری</h5>
                  <p>{PropertyDetail.estate.ownerPhoneNumber}</p>
              </li>
          </ul>
          <ul className={Style.PropertyInfoHolder}>
        {PropertyDetail.featuresList.map((item,index)=>{
            return(
                item.hasValue?<li key={index} >
                <h5>{item.text}</h5>
                <p>{item.value}</p>
            </li>:null
            )
        })}
        
        </ul>
        <p className={Style.description}><span>توضیحات درخواست :</span>{info.description}</p>
      </section>
      }
      </>
    
  )
}

export default PropertyTahtorDetail