import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddMagazineAPI } from "../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import DatePickerInRange from "../../DatePickerInRange/DatePickerInRange";
import Editor from "../../Editor/Editor";
import Style from "./AddMagazineForm.module.scss";
import { Alert } from "antd";
const AddMagazineForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const [description, setDescription] = useState("");
  const [magazineMonth, setMagazineMonth] = useState();
  
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDay2, setSelectedDay2] = useState(null);

  const month = [
    {
      value: "فروردین",
      name: "فروردین",
    },
    {
      value: "اردیبهشت",
      name: "اردیبهشت",
    },
    {
      value: "خرداد",
      name: "خرداد",
    },
    {
      value: "تیر",
      name: "تیر",
    },
    {
      value: "مرداد",
      name: "مرداد",
    },
    {
      value: "شهریور",
      name: "شهریور",
    },
    {
      value: "مهر",
      name: "مهر",
    },
    {
      value: "آبان",
      name: "آبان",
    },
    {
      value: "آذر",
      name: "آذر",
    },
    {
      value: "دی",
      name: "دی",
    },
    {
      value: "بهمن",
      name: "بهمن",
    },
    {
      value: "اسفند",
      name: "اسفند",
    },
  ];
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [ImageUrl, setImageUrl] = useState();
  const [ImageFile, setImageFile] = useState();
  const imageChange = (evt) => {
    setImageFile(evt.target.files[0]);
    setImageUrl(URL.createObjectURL(evt.target.files[0]));
  };
  const onSubmit = (data) => {
    if (magazineMonth === undefined) return errorToast("  ماه مجله را مشخص کنید");
    if (ImageFile === undefined) return errorToast("   کاور مجله را آپلود کنید");
    if (description === "") return errorToast("توضیحات مجله را وارد کنید");
    if (selectedDay === null) return errorToast("زمان عرضه مجله را وارد کنید");
    if (selectedDay2 === null) return errorToast("زمان بسته شدن ثبت نام مجله را وارد کنید");
    setLoadingState(true);
    let formData = new FormData();
    formData.append("Title", data.Title);
    formData.append("FrontCoverPrice", data.FrontCoverPrice);
    formData.append("BackCoverPrice", data.BackCoverPrice);
    formData.append("PricePerPage", data.PricePerPage);
    formData.append("Month", magazineMonth);
    formData.append("Cover", ImageFile);
    formData.append("Description", description);
    formData.append('PublishDate',`${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`)
    formData.append('EndRequestDate',`${selectedDay2.year}/${selectedDay2.month}/${selectedDay2.day}`)

    AddMagazineAPI(formData, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        reset();
        setLoadingState(false);
      } else {
        errorToast(res.data.errors[0]);
        setLoadingState(false);
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <h3>افزودن محله</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="Title">نام مجله</label>
          <input
            {...register("Title", { required: true })}
            className={errors.Title ? "valid" : "text"}
            autoComplete="Title"
            type="mail"
            name="Title"
          />
        </li>
        <li>
        <label htmlFor="month"> ماه مجله</label>
          <select name="month" id="month" defaultValue={"0"} className="text" onChange={e=>setMagazineMonth(e.target.value)}>
            <option value="0" disabled>
              یک ماه را انتخاب کنید
            </option>
            {month.map((item,index)=>{
               return(
                <option value={item.value} key={index}>{item.name}</option>
               )
            })
            }
          </select>
        </li>
        <li>
            <label htmlFor="PublishDate"> تاریخ عرضه</label>
            <DatePickerInRange id='PublishDate' selectedDayRange={selectedDay} setSelectedDayRange={setSelectedDay}/>
        </li>
        <li>
            <label htmlFor="EndRequestDate"> تاریخ بسته شدن ثبت نام</label>
            <DatePickerInRange id='EndRequestDate' selectedDayRange={selectedDay2} setSelectedDayRange={setSelectedDay2}/>
        </li>
        <li>
          <label htmlFor="PricePerPage"> قیمت هر صفحه</label>
          <input
            {...register("PricePerPage", { required: true })}
            className={errors.PricePerPage ? "valid" : "text"}
            autoComplete="PricePerPage"
            type="number"
            name="PricePerPage"
          />
        </li>
        <li>
          <label htmlFor="BackCoverPrice"> قیمت پشت جلد</label>
          <input
            {...register("BackCoverPrice", { required: true })}
            className={errors.BackCoverPrice ? "valid" : "text"}
            autoComplete="BackCoverPrice"
            type="number"
            name="BackCoverPrice"
          />
        </li>
        <li>
          <label htmlFor="FrontCoverPrice"> قیمت روی جلد</label>
          <input
            {...register("FrontCoverPrice", { required: true })}
            className={errors.FrontCoverPrice ? "valid" : "text"}
            autoComplete="FrontCoverPrice"
            type="number"
            name="FrontCoverPrice"
          />
        </li>
        
      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="description">توضیحات مجله</label>

        <Editor desc={description} setdescription={setDescription} defaultValue=""/>
      </div>
      <div className={Style.ImagePart}>
        <label htmlFor="imageFile" className={Style.ImageLabel}>
          انتخاب تصویر
          <input
            type="file"
            name="imageFile"
            id="imageFile"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => imageChange(e)}
          />
        </label>
        {ImageUrl && (
          <img src={ImageUrl} className={Style.ImagePerview} alt="" />
        )}
      </div>
      <Alert
        type="warning"
        message=" برای ثبت مجله های قبلی هزینه ها را 1 و تاریخ های خواسته شده رو تاریخ  آن روز وارد ! "
        showIcon
        className="w-100 mt-2"
      />
      <Button
        className="w-50 my-3"
        type="submit"
        disabled={loadingState ? true : false}
      >
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  );
};

export default AddMagazineForm;
