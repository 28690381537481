import React from 'react'
import NewTiketListContainer from '../../../Container/TiketListContainer/NewTiketListContainer'
import Layout from '../Layout'

const NewTiketList = () => {
  return (
    <Layout>
        <NewTiketListContainer/>
    </Layout>
  )
}

export default NewTiketList