import React from 'react'
import AddProepertyContainer from '../../../Container/AddProepertyContainer/AddProepertyContainer'
import Layout from '../Layout'

const AddProperty = () => {
  return (
    <Layout>
        <AddProepertyContainer/>
    </Layout> 
  )
}

export default AddProperty