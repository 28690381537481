import { Divider } from 'antd'
import React from 'react'
import Style from './AdditionalPerson.module.scss'
const AdditionalPerson = ({data}) => {

  return (
    <ul className={Style.DetailHolder}>
        <li className='w-100 text-danger'>شخص همراه</li>
        <li>
                <p className="text-primary">   نام: </p>
              <h6>{` ${data.firstName} ${data.lastName}`}</h6>
        </li>
        <li>
                <p className="text-primary">   شماره همراه: </p>
              <h6>{` ${data.phoneNumber}`}</h6>
        </li>
        <li>
                <p className="text-primary">    کدملی: </p>
              <h6>{` ${data.nationalCode}`}</h6>
        </li>
    </ul>
  )
}

export default AdditionalPerson