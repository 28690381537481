import React from 'react'
import AffidavitListContainer from '../../../../Container/AffidavitContainer/AffidavitListContainer/AffidavitListContainer'
import Layout from '../../Layout'

const AffidavitList = () => {
  return (
    <Layout><AffidavitListContainer/></Layout>
  )
}

export default AffidavitList