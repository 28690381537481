import React from 'react'
import CountryListContainer from '../../../Container/CountryListContainer/CountryListContainer'
import Layout from '../Layout'

const CountryList = () => {
  return (
    <Layout><CountryListContainer/></Layout>
  )
}

export default CountryList