import React from 'react'
import AddPageContainer from '../../../Container/AddPageContainer/AddPageContainer'
import Layout from '../Layout'

const AddPage = () => {
  return (
    <Layout><AddPageContainer/></Layout>
  )
}

export default AddPage