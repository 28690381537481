import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import Style from "./TahatorRequestsTabel.module.scss";
class TahatorRequestsTabel extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sortedInfo: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let dataSoruce = [];
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSoruce.push({
          key: element.id,
          name: `${element.user.firstName} ${element.user.lastName}`,
          userName: element.user.userName,
          UserProperteCode: element.proposedEstate.code,
          UserProperteForeign: element.isProposedEstateIdForeign,
          UserPropertyWantedCode: element.wantedEstate.code,
          UserPropertyWantedForeign: element.isWantedEstateIdForeign,
          description: element.description,
          status: element.status,
          affidavit: element.affidavit,
        });
        index = index + 1;
      });

    const columns = [
      {
        title: "نام درخواست دهنده",
        dataIndex: "name",
        key: "name",
        width:'25%',
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "مشخصات ملک درخواست دهنده",
        key: "propertyRequest",
        width:'22%',
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className="bg-warning border-0"
              onClick={() => this.props.showModal(1, record)}
            >
              نمایش جزئیات{" "}
            </Button>
          </Space>
        ),
      },
      {
        title: "مشخصات ملک درخواست شده",
        key: "wantedProperty",
        width:'22%',
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className="bg-warning border-0"
              onClick={() => this.props.showModal(2, record)}
            >
              نمایش جزئیات{" "}
            </Button>
          </Space>
        ),
      },

      {
        title: "عملیات",
        key: "action",
        width: "31%",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className="bg-danger border-0"
              onClick={() => this.props.showModal(3, record)}
            >
              بازکردن تیکت{" "}
            </Button>
            {record.status === 0 ? (
              <p style={{fontSize:'0.7rem'}}>در انتظار تایید شخص</p>
            ) : record.status === 1 ? (
              <Button
                type="primary"
                className="bg-success border-0"
                onClick={() => this.props.showModal(4, record)}
              >
                ثبت کمسیون{" "}
              </Button>
            ) : record.status===2?(
              <p style={{fontSize:'0.7rem',color:'green'}}>تاییدشد!</p>
            ):record.status===3&&record.affidavit===null?               <Button
            type="primary"
            className="bg-success border-0"
            onClick={() => this.props.showModal(5, record)}
          >
            تخصیص قولنامه
          </Button>:record.status===3?<>
          <Button
            type="primary"
            className="bg-success border-0"
            onClick={() => this.props.showModal(5, record)}
          >
            تغییر قولنامه
          </Button><Button
            type="primary"
            className="bg-info border-0"
            onClick={() => this.props.showModal(6, record)}
          >
            نمایش قولنامه
          </Button></>:null}
          </Space>
        ),
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          loading={this.props.data === undefined ? true : false}
          columns={columns}
          dataSource={dataSoruce}
          pagination={{
            position: ["bottomCenter"],
            total: this.props.totalPages,
            pageSize: 30,
          }}
        />
      </div>
    );
  }
}

export default TahatorRequestsTabel;
