import React from 'react'
import PropertyVisitListContianer from '../../../Container/PropertyVisitListContianer/PropertyVisitListContianer'
import Layout from '../Layout'

const PropertyVisitList = () => {
  return (
    <Layout><PropertyVisitListContianer/></Layout>
  )
}

export default PropertyVisitList