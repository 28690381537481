import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ConfirmRequestReseverationAPI, getPropertyReservationRequestAPI } from '../../API/BarangizanServices'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import PropertyReservationRequestsTabel from '../../Components/Tabels/PropertyReservationRequestsTabel/PropertyReservationRequestsTabel'
import { errorToast, succToast } from '../../Utils/Toast/Toast'

const PropertyReservationRequestsContainer = () => {
    useEffect(()=>{
        getRequestsHandler(1)
    },[])
    const[requestsState,setRequestsState]=useState()
    const getRequestsHandler =async(pageNumber)=>{
        const data=await getPropertyReservationRequestAPI(pageNumber)
        let list=[]
        data.data.list.forEach(element => {
            if(!element.isFinalized){
                list.push(element)
            }
            setRequestsState(list)
        });
    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[RequestInfo,setRequestInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
    };
    const handelModal=(id,value)=>{
      setRequestInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }

    }
    const ConfirmRequestHandler=()=>{
        setLoadingState(true)
        ConfirmRequestReseverationAPI(RequestInfo.id,(succ,res)=>{
            if(succ){
                setLoadingState(false)
                succToast(res.data.message)
                getRequestsHandler()
                handleShow()
            }
            else{
                setLoadingState(false)
                errorToast(res.data.errors[0])
            }
        })
    }
  return (
    <div className='flexStyle'>
        <h5 className='w-100 text-end px-3'>لیست درخواست رزرو های ثبت شده</h5>
        <LoadingPage show={loadingState}/>
        <Modal show={show2} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>بازکردن تیکت</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              {/* <EditPropertyFeaturesForm id={FeaturesInfo} getUpdate={()=>getPropertyFeaturesHandler(1)} closeModal={()=>handleShow()}/> */}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از تایید اطمینان دارید؟</Modal.Title>
          </Modal.Header>

          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>ConfirmRequestHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>

        <PropertyReservationRequestsTabel data={requestsState} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default PropertyReservationRequestsContainer