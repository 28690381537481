import React from 'react'
import ContactUsRequestContainer from '../../../Container/ContactUsRequestContainer/ContactUsRequestContainer'
import Layout from '../Layout'

const ContactUsRequest = () => {
  return (
    <Layout><ContactUsRequestContainer/></Layout>
  )
}

export default ContactUsRequest