import React, { useEffect, useState } from 'react'
import { DeletePropertyFeatureAPI, gerPropertyFeaturesAPI } from '../../API/BarangizanServices'
import PropertyFeaturesListTabel from '../../Components/Tabels/PropertyFeaturesListTabel/PropertyFeaturesListTabel'
import { Button, Modal } from 'react-bootstrap'
import EditPropertyFeaturesForm from '../../Components/Forms/EditPropertyFeaturesForm/EditPropertyFeaturesForm'
import { errorToast, succToast } from '../../Utils/Toast/Toast'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'
const PropertyFeaturesListContainer = () => {
    const[propertyFeaturesState,setPropertyFeautersState]=useState()
    const[FeaturePart,setFeaturePart]=useState(0)
    useEffect(()=>{
        getPropertyFeaturesHandler(FeaturePart)
    },[])
    const getPropertyFeaturesHandler=async(type)=>{
        const data=await gerPropertyFeaturesAPI(type)
        setPropertyFeautersState(data.data.list)
    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[FeaturesInfo,setFeaturesInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)

      
    };
    const handelModal=(id,value)=>{
      setFeaturesInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }

    }
    const featureTypeChangeHandler=(e)=>{
        setFeaturePart(e)
        getPropertyFeaturesHandler(e)
    }
    
    const deletePropertyFeaturesHandler=()=>{
      setLoadingState(true)
      DeletePropertyFeatureAPI(FeaturesInfo.id,(succ,res)=>{
        if(succ){
          succToast(res.data.message)
          setLoadingState(false)
          getPropertyFeaturesHandler(FeaturePart)
          handleShow()
        }
        else{
          errorToast(res.data.errors[0])
          setLoadingState(false)
        }
      })

    }

  return (
    <div className='flexStyle'>
        
        <TitleHeader text={"لیست ویژگی املاک ثبت شده"}/>
        <LoadingPage show={loadingState}/>
        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>ویرایش ویژگی ملک</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <EditPropertyFeaturesForm id={FeaturesInfo} getUpdate={()=>getPropertyFeaturesHandler(FeaturePart)} closeModal={()=>handleShow()}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>

          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deletePropertyFeaturesHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>
          <select
              name="FeatureType"
              id=""
              className="text w-50 mb-3"
              onChange={e=>featureTypeChangeHandler(e.target.value)}
            >
              <option value={0}>داخلی</option>
              <option value={1}>خارجی</option>
              <option value={2}>داخلی،خارجی</option>
            </select>
        <PropertyFeaturesListTabel data={propertyFeaturesState} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default PropertyFeaturesListContainer