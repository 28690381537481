import React from 'react'
import PropertyTypeListContainer from '../../../Container/PropertyTypeListContainer/PropertyTypeListContainer'
import Layout from '../Layout'

const PropertyTypeList = () => {
  return (
    <Layout><PropertyTypeListContainer/></Layout>
  )
}

export default PropertyTypeList