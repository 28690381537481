import React, { useEffect, useState } from "react";
import { Alert } from "antd";
import { Button } from "react-bootstrap";
import Select from "react-select";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import { Controller, useForm } from "react-hook-form";
import Style from "./EditForeignPropertyForm.module.scss";
import {
  EditForeignPropertyAPI,
  getAllUserListAPI,
  getCountryListAPI,
  getForeignCitylistAPI,
  getForeignCitylistAPI2,
  getForeignProeprtyDetailAPI,
  getForeignPropertyTypeAPI,
} from "../../../API/BarangizanServices";
import Editor from "../../Editor/Editor";
import { Spinner } from "react-bootstrap";
const EditForeignPropertyForm = ({ info,getUpdate,closeModal }) => {
  const [loadingState, setLoadingState] = useState(false);
  const [loadingType, setLoadingType] = useState(true);
  const [RentState, setRentState] = useState(false);
  const [propertyType, setPropertyType] = useState();
  const [propertyDetail, setPropertyDetai] = useState();
  const [propertyTypeValue, setPropertyValue] = useState("");
  const [countryList, setCountryList] = useState("");
  const [citiesList, setCitiestList] = useState();
  const [CityName, setCityName] = useState("");
  const [coutrySelect, setCountrySelect] = useState("");
  const [UserList, setUserList] = useState();
  const [userSelect, setUserSelect] = useState();
  const [featureListState, setFeatureListState] = useState();
  const [description, setDescription] = useState("");
  const [TahatorState, setTahatorState] = useState(false);
  const [countryListTahator, setCountryListTahator] = useState(null);


  useEffect(() => {
    getDataHandler();
  }, []);

  const getDataHandler = async () => {
    const data = await getCountryListAPI();
    setCountryList(data.data.list);
    const users = await getAllUserListAPI(1);
    setUserList(users.data.users);
    const propertyType = await getForeignPropertyTypeAPI();
    setPropertyType(propertyType.data.list);
    setLoadingType(false);
    const detail = await getForeignProeprtyDetailAPI(info.code);
    const cities = await getForeignCitylistAPI2(detail.data.estate.country);
    setCitiestList(cities.data.list);
    setPropertyDetai(detail.data.estate);
    setTahatorState(detail.data.estate.isTahator)
    setFeatureListState(detail.data.featuresList);
    setRentState(detail.data.estate.isEjareyi)
  };
  const getCityListHandler = async (info) => {
    setCountrySelect(info);
    const cities = await getForeignCitylistAPI(info.value);
    setCitiestList(cities.data.list);
  };
  const defaultCountry =
    propertyDetail &&
    countryList &&
    countryList.find((element) => element.title === propertyDetail.country);
  const defaultCity =
    propertyDetail &&
    citiesList &&
    citiesList.find((element) => element.title === propertyDetail.city);
  
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();
  const onSubmit = (data) => {
    setLoadingState(true);
    let formData = new FormData();
    formData.append("EstatesTypeId", propertyTypeValue.value);
    formData.append("title", data.title);
    formData.append("description", description);
    formData.append("IsTahator", data.Tahator);
    formData.append("TahatorCountries", countryListTahator===null?defaultTahatorCountries:countryListTahator);
    formData.append("Country", coutrySelect.label);
    formData.append("CountryId", coutrySelect.value);
    formData.append("Metraj", data.Metraj);
    formData.append("price", data.price);
    formData.append("discountedPrice", data.discountedPrice);
    formData.append("OwnerName", userSelect && userSelect.label.split("-")[0]);
    formData.append("OwnerPhoneNumber", userSelect.value);
    formData.append("PrePaymentPrice", data.PrePaymentPrice);
    formData.append("ExclusiveVisitPrice", data.ExclusiveVisitPrice);
    formData.append("VirtualVisitPrice", data.VirtualVisitPrice);
    for (var i = 0; i < parseInt(featureListState.length); i++) {
      formData.append(`Features[${i}].Key`, data.Features[i].Key);
      let featureString = [];
      data.Features[i].value.forEach((element) => {
        featureString.push(element.label);
      });
      formData.append(`Features[${i}].Value`, featureString.toString());
    }

    EditForeignPropertyAPI(formData,(succ,res)=>{
      if(succ){
        succToast(res.data.message)
        setLoadingState(false)
        reset()
        closeModal()
        getUpdate()
      }
      else{
        errorToast(res.data.errors[0])
        setLoadingState(false)
      }
    })
  };
  
  const TahatorHandler = (e) => {
    setTahatorState(e.target.checked);
  };
  const seletCountryTahatorHandler = (e) => {
    let CountryListSelected = [];
    e.forEach((element) => {
      CountryListSelected.push(element.label);
    });
    setCountryListTahator(CountryListSelected.join("_"));
  };
  const defaultTahatorCountries =propertyDetail?.tahatorCountries?.split('_')
  

  
  let defaultValueFeature = [];
  featureListState &&
    featureListState.forEach((element) => {
      let value = element.value !== null ? element.value.split(",") : [""];
      let testarray = [];
      value.forEach((element) => {
        testarray.push({
          label: element,
          value: element,
        });
      });
      defaultValueFeature.push(testarray);
    });
  return (
    <>
      {propertyDetail !== undefined ? (
        <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
          <LoadingPage show={loadingState} />
          <h3>افزودن ملک خارجی</h3>
          <ul className={Style.InputHolder}>
            <li>
              <label htmlFor="type">نوع ملک</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("type", { required: false })}
                  onChange={setPropertyValue}
                  options={
                    propertyType &&
                    propertyType.map((item) => {
                      return { value: item.id, label: item.title };
                    })
                  }
                  name="type"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  isLoading={loadingType}
                  defaultValue={{
                    label: propertyDetail.estateType,
                    value: propertyDetail.estateTypeId,
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="title">عنوان ملک</label>
              <input
                {...register("title", { required: false })}
                className={errors.title ? "valid" : "text"}
                autoComplete="title"
                type="text"
                name="title"
                defaultValue={propertyDetail.title}
              />
            </li>

            <li>
              <label htmlFor="country"> کشور</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("country", { required: false })}
                  onChange={getCityListHandler}
                  options={
                    countryList &&
                    countryList.map((item) => {
                      return { value: item.id, label: item.title };
                    })
                  }
                  name="province"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  isLoading={loadingType}
                  defaultValue={{
                    label: defaultCountry.title,
                    value: defaultCountry.id,
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="city"> شهر</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("city", { required: false })}
                  options={citiesList&&citiesList.map(item=>{
                    return ({ value: item.id, label: item.title })
                  })}
                  name="city"
                  onChange={setCityName}
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  defaultValue={{label:defaultCity.title,value:defaultCity.id}}
                />
              </div>
            </li>

            <li>
              <label htmlFor="Metraj">متراژ </label>
              <input
                {...register("Metraj", { required: true })}
                className={errors.Metraj ? "valid" : "text"}
                autoComplete="Metraj"
                type="text"
                name="Metraj"
                defaultValue={info.area}
              />
            </li>
            <li>
              <label htmlFor="price">قیمت </label>
              <input
                {...register("price", { required: true })}
                className={errors.price ? "valid" : "text"}
                autoComplete="price"
                type="number"
                name="price"
                defaultValue={propertyDetail.price}
              />
            </li>
            <li>
              <label htmlFor="discountedPrice">قیمت با تخفیف </label>
              <input
                {...register("discountedPrice", { required: true })}
                className={errors.discountedPrice ? "valid" : "text"}
                autoComplete="discountedPrice"
                type="number"
                name="discountedPrice"
                defaultValue={propertyDetail.discountedPrice}
              />
            </li>
            <li>
              <label htmlFor="PrePaymentPrice">قیمت پیش پرداخت </label>
              <input
                {...register("PrePaymentPrice", { required: true })}
                className={errors.PrePaymentPrice ? "valid" : "text"}
                autoComplete="PrePaymentPrice"
                type="number"
                name="PrePaymentPrice"
                defaultValue={propertyDetail.prePaymentPrice}
              />
            </li>
            <li>
              <label htmlFor="ExclusiveVisitPrice">قیمت بازدید اختصاصی </label>
              <input
                {...register("ExclusiveVisitPrice", { required: true })}
                className={errors.ExclusiveVisitPrice ? "valid" : "text"}
                autoComplete="ExclusiveVisitPrice"
                type="number"
                name="ExclusiveVisitPrice"
                defaultValue={propertyDetail.exclusiveVisitPrice}
              />
            </li>
            <li>
              <label htmlFor="VirtualVisitPrice">قیمت بازدید مجازی </label>
              <input
                {...register("VirtualVisitPrice", { required: true })}
                className={errors.VirtualVisitPrice ? "valid" : "text"}
                autoComplete="VirtualVisitPrice"
                type="number"
                name="VirtualVisitPrice"
                defaultValue={propertyDetail.virtualVisitPrice}
              />
            </li>
            <li>
              <label htmlFor="type"> مالک</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("type", { required: false })}
                  onChange={setUserSelect}
                  options={
                    UserList &&
                    UserList.map((item) => {
                      return {
                        label: `${item.firstName} ${item.lastName}- ${item.userName}`,
                        value: item.userName,
                      };
                    })
                  }
                  name="type"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  defaultValue={{
                    label: `${propertyDetail.ownerName}- ${propertyDetail.ownerPhoneNumber}`,
                    value: propertyDetail.userName,
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="foreignEstateSellingType"> نوع فروش</label>
              <div className={Style.selectOption}>
                <select
                  defaultValue={propertyDetail.foreignEstateSellingType}
                  name="foreignEstateSellingType"
                  id=""
                  className="text"
                  {...register("foreignEstateSellingType", { required: false })}
                >
                  <option value={0}>نقد</option>
                  <option value={1}>اقساط</option>
                  <option value={2}>نقد،اقساط</option>
                </select>
              </div>
            </li>
            <li className={Style.CheckBoxHolder}>
              <label htmlFor="Rent">  اجاره داده می شود </label>
              <input
                defaultChecked={propertyDetail.isEjareyi}
                style={{ accentColor: "blue" }}
                type={"checkbox"}
                id={"Rent"}
                onChange={(e) => setRentState(e.target.checked)}
              />
            </li>

            {RentState ? (
              <li>
                <label htmlFor="prepayment">پیش پرداخت </label>
                <input
                  {...register("prepayment", { required: true })}
                  className={errors.prepayment ? "valid" : "text"}
                  autoComplete="prepayment"
                  type="number"
                  name="prepayment"
                  defaultValue={propertyDetail.pish}
                />
              </li>
            ) : null}
            {RentState ? (
              <li>
                <label htmlFor="monthlyRent">ماهیانه </label>
                <input
                  {...register("monthlyRent", { required: true })}
                  className={errors.monthlyRent ? "valid" : "text"}
                  autoComplete="monthlyRent"
                  type="number"
                  name="monthlyRent"
                  defaultValue={propertyDetail.mahiane}
                />
              </li>
            ) : null}

            {RentState ? (
              <li>
                <label htmlFor="ChargingFee">هزینه شارژ </label>
                <input
                  {...register("ChargingFee", { required: true })}
                  className={errors.ChargingFee ? "valid" : "text"}
                  autoComplete="ChargingFee"
                  type="number"
                  name="ChargingFee"
                  defaultValue={propertyDetail.sharj}
                />
              </li>
            ) : null}
                      <li>
            <label htmlFor="Tahator" className={Style.TahatorCheckBox}>
              <input
                type={"checkbox"}
                id="Tahator"
                {...register("Tahator", { required: false })}
                defaultChecked={propertyDetail.isTahator}
                onChange={TahatorHandler}
              />
               تهاتر 
            </label>
          </li>
          {TahatorState? (
            <li>
              <label htmlFor="type"> کشور برای تهاتر</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("type", { required: false })}
                  onChange={seletCountryTahatorHandler}
                  options={countryList?.map((item) => {
                    return { value: item.title, label: item.title };
                  })}
                  name="type"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  defaultValue={defaultTahatorCountries?.map((item)=>{
                    return { value: item, label: item }
                  })}
                  isMulti
                />
              </div>
            </li>
          ) : null}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="Address">آدرس ملک</label>
            <textarea
              {...register("Address", { required: true })}
              className={errors.Address ? "valid" : "text"}
              autoComplete="Address"
              type="text"
              name="Address"
              defaultValue={propertyDetail.address}
            />
          </div>
          <ul className={Style.InputHolder}>
            {featureListState &&
              featureListState.map((item, i) => {
                return (
                  <li key={item.id}>
                    <label htmlFor="featureCount">{`  ویژگی ${i + 1}`}</label>
                    <input
                      {...register(`Features[${i}].Key`)}
                      className={errors.username ? "valid" : "text"}
                      autoComplete={`Features[${i}].Key`}
                      type="text"
                      name={`Features[${i}].Key`}
                      style={{ marginBottom: "0.5rem" }}
                      defaultValue={item.text}
                      readOnly
                    />
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <div className={Style.selectOption}>
                          <Select
                            defaultValue={defaultValueFeature[i]}
                            options={item.options.map((item) => {
                              return {
                                label: item.text,
                                value: item.text,
                              };
                            })}
                            name={`Features[${i}].value`}
                            // onChange={setFeatureTest}
                            onChange={onChange}
                            placeholder="انتخاب کنید"
                            className={Style.selectOptionsearch}
                            isMulti
                          />
                        </div>
                      )}
                      name={`Features[${i}].value`}
                      control={control}
                      rules={{ required: false }}
                      defaultValue={defaultValueFeature[i]}
                    />
                  </li>
                );
              })}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="description">توضیحات ملک</label>
            <Editor
              desc={description}
              setdescription={setDescription}
              defaultValue={propertyDetail.description}
            />
          </div>

          <Alert
            type="warning"
            message=" درصورت نداشتن تخفیف، قیمت و قیمت با تخفیف را یکسان وارد کنید"
            showIcon
            className="w-100"
          />
          <Alert
            type="warning"
            message=" درصورت نداشتن قیمت های بازدید عدد 1 را وارد کنید!"
            showIcon
            className="w-100 mt-2"
          />

          <Button className="w-100 my-3" type="submit">
            ثبت
          </Button>
        </form>
      ) : (
        <Spinner animation="border" variant="warning" />
      )}
    </>
  );
};

export default EditForeignPropertyForm;
