import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getTahatorRequestAPI } from "../../API/BarangizanServices";
import AllocationAffidavitDeatil from "../../Components/Details/AllocationAffidavitDeatil/AllocationAffidavitDeatil";
import PropertyTahtorDetail from "../../Components/Details/PropertyTahtorDetail/PropertyTahtorDetail";
import WantedPropertyTahtorDetail from "../../Components/Details/WantedPropertyTahtorDetail/WantedPropertyTahtorDetail";
import AddPrePayForTahator from "../../Components/Forms/AddPrePayForTahator/AddPrePayForTahator";
import AddTiketForOneUserForm from "../../Components/Forms/AddTiketForOneUserForm/AddTiketForOneUserForm";
import AllocationAffidavitForm from "../../Components/Forms/AllocationAffidavitForm/AllocationAffidavitForm";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import TahatorRequestsTabel from "../../Components/Tabels/TahatorRequestsTabel/TahatorRequestsTabel";
const TahatorRequestListContainer = () => {
  useEffect(() => {
    getRequestsHandler(1);
  }, []);
  const [requestsState, setRequestsState] = useState();
  const getRequestsHandler = async (pageNumber) => {
    const data = await getTahatorRequestAPI(pageNumber);
    setRequestsState(data.data.list);
  };
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [RequestInfo, setRequestInfo] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const handleShow = () => {
    setShow(false);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
    setShow6(false);
  };
  const handelModal = (id, value) => {
    setRequestInfo(value);
    if (id === 1) {
      setShow(true);
    }
    if (id === 2) {
      setShow2(true);
    }
    if (id === 3) {
      setShow3(true);
    }
    if (id === 4) {
      setShow4(true);
    }
    if (id === 5) {
      setShow5(true);
    }
    if (id === 6) {
      setShow6(true);
    }
  };
  // console.log(requestsState)
  return (
    <div className="flexStyle">
      <h5 className="w-100 text-end px-3">لیست درخواست تهاتر های ثبت شده</h5>
      <LoadingPage show={loadingState} />
      <Modal show={show} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>جزئیات ملک</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          <PropertyTahtorDetail info={RequestInfo} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>جزئیات ملک</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          <WantedPropertyTahtorDetail info={RequestInfo} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show3} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>بازکردن تیکت</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          <AddTiketForOneUserForm info={RequestInfo} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>ثبت پیش پرداخت</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          <AddPrePayForTahator
            info={RequestInfo}
            HandelModal={() => {
              getRequestsHandler(1);
              handleShow();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show5} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>تخصیص قولنامه</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          <AllocationAffidavitForm
            info={RequestInfo}
            HandelModal={() => {
              getRequestsHandler(1);
              handleShow();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show6} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>نمایش قولنامه</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
        <AllocationAffidavitDeatil info={RequestInfo}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>


      <TahatorRequestsTabel
        data={requestsState}
        showModal={(e, value) => handelModal(e, value)}
      />
    </div>
  );
};

export default TahatorRequestListContainer;
