import React, { useEffect } from 'react'
import { useState } from 'react'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'
import { Button, Modal } from 'react-bootstrap'
import { getCurrencyListAPI } from '../../API/BarangizanServices'
import CurrenciesListTabel from '../../Components/Tabels/CurrenciesListTabel/CurrenciesListTabel'
import EditCurrencyRateForm from '../../Components/Forms/EditCurrencyRateForm/EditCurrencyRateForm'
import CurrencyHistoryDetails from '../../Components/Details/CurrencyHistoryDetails/CurrencyHistoryDetails'
const CurrenciesListContainer = () => {
    const[loadingState,setLoadingState]=useState(false)
    const[CurrencyListState,setCurrencyListState]=useState()
    useEffect(()=>{
        getCurrencyDataHandler()
    },[])
    const getCurrencyDataHandler=async()=>{
        setLoadingState(true)
        const data=await getCurrencyListAPI()
        setCurrencyListState(data.data.list)
        setLoadingState(false)
    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[CurrencyInfo,setCurrencyInfo]=useState()
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
    };
    const handelModal=(id,value)=>{
        setCurrencyInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }

    }
    

  return (
    <div className='flexStyle'>
        
    <TitleHeader text={"لیست ارزها"}/>
    <LoadingPage show={loadingState}/>
    <Modal show={show} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>ویرایش ویژگی ملک</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-around w-100'>
          <EditCurrencyRateForm id={CurrencyInfo} getUpdate={()=>getCurrencyDataHandler()} closeModal={()=>handleShow()}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={show2} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>تاریخچه تغییرات</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-around w-100'>
          <CurrencyHistoryDetails id={CurrencyInfo} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <CurrenciesListTabel data={CurrencyListState} showModal={(e,value)=>handelModal(e,value)}/>
</div>
  )
}

export default CurrenciesListContainer