import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import Style from './Editor.module.css'

export const Editor = ({desc,setdescription,defaultValue}) => {
  // const [state, setState] = React.useState({ value: null });
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={desc===''?defaultValue:desc}
        onChange={e=>setdescription(e)}
        placeholder={"توضیحات"}
        modules={modules}
        formats={formats}
        className={Style.ReactQuill}
        // defaultValue={defaultValue}
      />
    </div>
  );
};

export default Editor;
