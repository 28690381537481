import React from 'react'
import DatePicker from "@amir04lm26/react-modern-calendar-date-picker";

const DatePickerInRange = ({selectedDayRange,setSelectedDayRange,placeholder,minimumDate,maximumDate}) => {
 
    return (
        <DatePicker
        value={selectedDayRange}
        onChange={setSelectedDayRange}
        inputPlaceholder={placeholder}
        shouldHighlightWeekends
        locale='fa'
      />
    )
}

export default DatePickerInRange
