import React from 'react'

const AllocationAffidavitDeatil = ({info}) => {
  return (
    <div
    className="w-100 p-2 mb-3 bg-light border border-primary"
    dangerouslySetInnerHTML={{ __html: info.affidavit }}
  ></div>
  )
}

export default AllocationAffidavitDeatil