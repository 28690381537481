import React from 'react'
import ForeignPropertyRegisterRequestContainer from '../../../Container/ForeignPropertyRegisterRequestContainer/ForeignPropertyRegisterRequestContainer'
import Layout from '../Layout'

const ForeignPropertyRegisterRequest = () => {
  return (
    <Layout><ForeignPropertyRegisterRequestContainer/></Layout>
  )
}

export default ForeignPropertyRegisterRequest