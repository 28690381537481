import React, { useEffect, useState } from 'react'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import { Button, Modal } from 'react-bootstrap'
import { errorToast, succToast } from '../../Utils/Toast/Toast'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'
import { DeleteUnitAPI, getUnitListApi } from '../../API/BarangizanServices'
import UnitListTabel from '../../Components/Tabels/UnitListHandler/UnitListTabel'
import UnitGallery from '../../Components/Gallery/UnitGallery/UnitGallery'
import EditUnitForm from '../../Components/Forms/EditUnitForm/EditUnitForm'
const UnitListContainer = () => {
    const[UnitData,setUnitData]=useState()
    const[pageDetail,setPageDetail]=useState()
    const[currentPage,setCurrentPage]=useState()
    useEffect(()=>{
        getUnitListHandler(1)
        
    },[])
    const getUnitListHandler=async(pageNumber)=>{
        setCurrentPage(pageNumber)
        setLoadingState(true)
        const data=await getUnitListApi(pageNumber)
        setUnitData(data.data.list)
        setPageDetail(data.data.pagination)
        setLoadingState(false)
        window.scrollTo(0, 0)
    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const[UnitInfo,setUnitInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
      setShow3(false)
      
    };
    const handelModal=(id,value)=>{
      setUnitInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }
      if(id===3){
        setShow3(true)
      }
    }
    const deleteUnitHandler=()=>{
        setLoadingState(true)
        DeleteUnitAPI(UnitInfo.UnitCode,(succ,res)=>{
          if(succ){
            succToast(res.data.message)
            getUnitListHandler(currentPage)
            setLoadingState(false)
            handleShow()
          }
          else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
          }
        })
      }
  return (
    <div className='flexStyle'>
    <LoadingPage show={loadingState}/>
    <TitleHeader text={"لیست واحد های خارجی ثبت شده"}/>
    <Modal show={show3} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>گالری ملک</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <UnitGallery id={UnitInfo}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={show} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>ویرایش ملک</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <EditUnitForm info={UnitInfo} getUpdate={()=>getUnitListHandler(1)} closeModal={()=>handleShow}/>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={show2} className={'RTL'}>
      <Modal.Header >
        <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
      </Modal.Header>

      <Modal.Body className='d-flex justify-content-around w-100'>
        <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteUnitHandler()}>بله</Button>
        <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
      </Modal.Body>
  </Modal>
    {/* <ForeignPropertyListTabel currentPage={currentPage} data={PropertyData} pageDetail={pageDetail} updatePage={(page)=>getPropertyListHandler(page)} showModal={(e,value)=>handelModal(e,value)}/> */}
    <UnitListTabel currentPage={currentPage} data={UnitData} pageDetail={pageDetail} updatePage={(page)=>getUnitListHandler(page)} showModal={(e,value)=>handelModal(e,value)}/>
   
</div>
  )
}

export default UnitListContainer