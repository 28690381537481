import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faList,
  faPlusCircle,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNewRequestApi } from "../../../API/BarangizanServices";
import { errorToast } from "../../../Utils/Toast/Toast";
import Style from "./PanelSideBar.module.scss";
const PanelSideBar = ({ CloseSideBar }) => {
  const navigate = useNavigate();
  const params = () => window.location.pathname.split("/")[1];

  const MianAdmin = () => localStorage.getItem("MainAdmin");

  const SignOutHandler = () => {
    localStorage.removeItem("AdminBarangizanToken");
    errorToast("باموفقیت خارج شدید!");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const[Requests,setRequests]=useState()
  useEffect(()=>{
    getNewRequestListHandler()
  },[])

  const getNewRequestListHandler=async()=>{
    const data=await getNewRequestApi()
    setRequests(data.data)
  }


  
  return (
    <section className={Style.ComponentHolder}>
      <div className={Style.CloseSideBar} onClick={() => CloseSideBar()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={Style.UserInfo}>
        <h4>
          <FontAwesomeIcon icon={faUser} /> {" ادمین"}
        </h4>
        <p>
          <span>{"املاک برانگیزان"}</span>
        </p>
      </div>
      <ul className={Style.ProfileItems}>
        <li
          className={
            params() === "addproperty" ||
            params() === "propertylist" ||
            params() === "addforeignpropertyproject" ||
            params() === "addforeignproperty" ||
            params() === "foreignpropertylist" ||
            params() === "foreignpropertyprojectlist" ||
            params() === "addunittoProject" ||
            params() === "unitlist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addproperty");
            }}
          >
            <p> املاک</p>
            <FontAwesomeIcon
              icon={
                params() === "addproperty" ||
                params() === "propertylist" ||
                params() === "addforeignpropertyproject" ||
                params() === "addforeignproperty" ||
                params() === "foreignpropertylist" ||
                params() === "foreignpropertyprojectlist" ||
                params() === "addunittoProject" ||
                params() === "unitlist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addproperty" ||
                params() === "propertylist" ||
                params() === "addforeignpropertyproject" ||
                params() === "addforeignproperty" ||
                params() === "foreignpropertylist" ||
                params() === "foreignpropertyprojectlist" ||
                params() === "addunittoProject" ||
                params() === "unitlist"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addproperty");
              }}
            >
              <p> افزودن ملک داخلی</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertylist");
              }}
            >
              <p> لیست املاک داخلی </p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addforeignproperty");
              }}
            >
              <p>افزودن ملک خارجی</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/foreignpropertylist");
              }}
            >
              <p>لیست املاک خارجی</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addforeignpropertyproject");
              }}
            >
              <p>افزودن پروژه خارجی</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/foreignpropertyprojectlist");
              }}
            >
              <p>لیست پروژهای خارجی</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addunittoProject");
              }}
            >
              <p>افزودن واحد پروژه </p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/unitlist");
              }}
            >
              <p> لیست واحد ها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addpropertyfeatures" ||
            params() === "propertyfeaturelist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addpropertyfeatures");
            }}
          >
            <p> ویژگی های ملک</p>
            <FontAwesomeIcon
              icon={
                params() === "addpropertyfeatures" ||
                params() === "propertyfeaturelist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addpropertyfeatures" ||
                params() === "propertyfeaturelist"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addpropertyfeatures");
              }}
            >
              <p> افزودن ویژگی ملک</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertyfeaturelist");
              }}
            >
              <p> لیست ویژگی های ملک </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addpropertytype" ||
            params() === "propertytypelist" ||
            params() === "addforeignproprtytype" ||
            params() === "foreignpropertytypelist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addpropertytype");
            }}
          >
            <p>نوع ملک</p>
            <FontAwesomeIcon
              icon={
                params() === "addpropertytype" ||
                params() === "propertytypelist" ||
                params() === "addforeignproprtytype" ||
                params() === "foreignpropertytypelist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addpropertytype" ||
                params() === "propertytypelist" ||
                params() === "addforeignproprtytype" ||
                params() === "foreignpropertytypelist"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addpropertytype");
              }}
            >
              <p> افزودن نوع ملک داخلی</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertytypelist");
              }}
            >
              <p> لیست انواع ملک داخلی </p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addforeignproprtytype");
              }}
            >
              <p> افزودن نوع ملک خارجی</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/foreignpropertytypelist");
              }}
            >
              <p> لیست انواع ملک خارجی </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addcountry" ||
            params() === "countrylist" ||
            params() === "addcitytocountry" ||
            params() === "citiesofcountries" ||
            params() === "currencylist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addcountry");
            }}
          >
            <p>کشور</p>
            <FontAwesomeIcon
              icon={
                params() === "addcountry" ||
                params() === "countrylist" ||
                params() === "addcitytocountry" ||
                params() === "citiesofcountries" ||
                params() === "currencylist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addcountry" ||
                params() === "countrylist" ||
                params() === "addcitytocountry" ||
                params() === "citiesofcountries" ||
                params() === "currencylist"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addcountry");
              }}
            >
              <p> افزودن کشور</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/countrylist");
              }}
            >
              <p> لیست کشورها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addcitytocountry");
              }}
            >
              <p> افزودن شهر به کشور </p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/citiesofcountries");
              }}
            >
              <p> لیست شهرهای کشور </p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/currencylist");
              }}
            >
              <p> لیست ارزها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "newtiketlist" ||
            params() === "readedtiketlist" ||
            params() === "addtiket"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addtiket");
            }}
          >
            <p>تیکت</p>
            <FontAwesomeIcon
              icon={
                params() === "newtiketlist" ||
                params() === "readedtiketlist" ||
                params() === "addtiket"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "newtiketlist" ||
                params() === "readedtiketlist" ||
                params() === "addtiket"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addtiket");
              }}
            >
              <p> بازکردن تیکت</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/newtiketlist");
              }}
            >
              <p> لیست تیکت های خوانده نشده {Requests?.ticketCount!==0?<div className={Style.redColor}>{Requests?.ticketCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/readedtiketlist");
              }}
            >
              <p> لیست تیکت های خوانده شده </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addaffidavit" ||
            params() === "affidavitlist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addaffidavit");
            }}
          >
            <p>قولنامه</p>
            <FontAwesomeIcon
              icon={
                params() === "addaffidavit" ||
                params() === "affidavitlist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addaffidavit" ||
                params() === "affidavitlist" 
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addaffidavit");
              }}
            >
              <p> افرودن قولنامه</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/affidavitlist");
              }}
            >
              <p> لیست قولنامه ها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
            {/* <div
              onClick={() => {
                CloseSideBar();
                navigate("/readedtiketlist");
              }}
            >
              <p> لیست تیکت های خوانده شده </p>
              <FontAwesomeIcon icon={faList} />
            </div> */}
          </div>
        </li>
        <li
          className={
            params() === "propertyreservationrequests" ||
            params() === "propertyreservationlist" ||
            params() === "propertyvisitrequest" ||
            params() === "propertyvisitlist" ||
            params() === "propertyregisterrequest" ||
            params() === "cooperationrequest" ||
            params() === "foreignpropertyregisterrequest" ||
            params() === "contactusrequest"|| params()==="tahatorrequest"|| params()==='magazinepropertyRegister'
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/propertyreservationrequests");
            }}
          >
            <p>درخواست ها</p>
            <FontAwesomeIcon
              icon={
                params() === "propertyreservationrequests" ||
                params() === "propertyreservationlist" ||
                params() === "propertyvisitrequest" ||
                params() === "propertyvisitlist" ||
                params() === "propertyregisterrequest" ||
                params() === "cooperationrequest" ||
                params() === "foreignpropertyregisterrequest" ||
                params() === "contactusrequest"|| params()==="tahatorrequest"|| params()==='magazinepropertyRegister'
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "propertyreservationrequests" ||
                params() === "propertyreservationlist" ||
                params() === "propertyvisitrequest" ||
                params() === "propertyvisitlist" ||
                params() === "propertyregisterrequest" ||
                params() === "cooperationrequest" ||
                params() === "foreignpropertyregisterrequest" ||
                params() === "contactusrequest" || params()==="tahatorrequest" || params()==='magazinepropertyRegister'
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertyreservationrequests");
              }}
            >
              <p>درخواست های رزرو ملک {Requests?.reserveEstateCount!==0?<div className={Style.redColor}>{Requests?.reserveEstateCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertyregisterrequest");
              }}
            >
              <p>درخواست های ثبت ملک {Requests?.estatecreationRequestCount!==0?<div className={Style.redColor}>{Requests?.estatecreationRequestCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/foreignpropertyregisterrequest");
              }}
            >
              <p>درخواست های ثبت ملک خارجی {Requests?.foreignEstateCreationRequestCount!==0?<div className={Style.redColor}>{Requests?.foreignEstateCreationRequestCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/cooperationrequest");
              }}
            >
              <p>درخواست های همکاری {Requests?.cooperationRequestCount!==0?<div className={Style.redColor}>{Requests?.cooperationRequestCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/cooperationrequest");
              }}
            >
              <p> لیست ملک های رزو شده</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertyvisitrequest");
              }}
            >
              <p>درخواست بازدید ملک {Requests?.visitRequestCount!==0?<div className={Style.redColor}>{Requests?.visitRequestCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/tahatorrequest");
              }}
            >
              <p>درخواست تهاتر ملک {Requests?.tahatorRequestCount!==0?<div className={Style.redColor}>{Requests?.tahatorRequestCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/magazinepropertyRegister");
              }}
            >
              <p>درخواست ثبت ملک مجله {Requests?.magazineRequestCount!==0?<div className={Style.redColor}>{Requests?.magazineRequestCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/propertyvisitlist");
              }}
            >
              <p>لیست بازدیدهای ثبت شده</p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/contactusrequest");
              }}
            >
              <p>درخواست ارتباط با ما {Requests?.contactUsMessageCount!==0?<div className={Style.redColor}>{Requests?.contactUsMessageCount}</div>:null}</p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addpage" ||
            params() === "pagelist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addpage");
            }}
          >
            <p>صفحه</p>
            <FontAwesomeIcon
              icon={
                params() === "addpage" ||
                params() === "pagelist" 
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addpage" ||
                params() === "pagelist" 
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addpage");
              }}
            >
              <p>  ایجاد صفحه</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/pagelist");
              }}
            >
              <p> لیست صفحات</p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addtour" ||
            params() === "tourlist" ||
            params() === "tourppl"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addtour");
            }}
          >
            <p> تور</p>
            <FontAwesomeIcon
              icon={
                params() === "addtour" ||
                params() === "tourlist" ||
                params() === "tourppl"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addtour" ||
                params() === "tourlist" ||
                params() === "tourppl"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addtour");
              }}
            >
              <p> افزودن تور</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/tourlist");
              }}
            >
              <p> لیست تورها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/tourppl");
              }}
            >
              <p> لیست اشخاص ثبت نام کرده در تور </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "addmagazine" || params() === "magazinelist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/addmagazine");
            }}
          >
            <p> مجله</p>
            <FontAwesomeIcon
              icon={
                params() === "addmagazine" || params() === "magazinelist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "addmagazine" || params() === "magazinelist"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/addmagazine");
              }}
            >
              <p> افزودن مجله</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/magazinelist");
              }}
            >
              <p> لیست مجله ها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        <li
          className={
            params() === "adduser" || params() === "userlist"
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              navigate("/adduser");
            }}
          >
            <p> کاربر</p>
            <FontAwesomeIcon
              icon={
                params() === "adduser" || params() === "userlist"
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                params() === "adduser" || params() === "userlist"
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/adduser");
              }}
            >
              <p> افزودن کاربر</p>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              onClick={() => {
                CloseSideBar();
                navigate("/userlist");
              }}
            >
              <p> لیست کاربر ها </p>
              <FontAwesomeIcon icon={faList} />
            </div>
          </div>
        </li>
        {/* <li
          className={params() === "userlist" ? Style.ActiveItem : null}
          onClick={() => {
            navigate("/userlist");
            CloseSideBar();
          }}
        >
          <p>لیست کاربران</p>
          <FontAwesomeIcon icon={faBars} />
        </li> */}
        {MianAdmin() === "true" ? (
          <li
            className={
              params() === "addadmin" || params() === "adminlist"
                ? Style.AccordionAvtive
                : Style.AccordionRegular
            }
          >
            <div
              className={Style.titlePart}
              onClick={() => {
                navigate("/addadmin");
              }}
            >
              <p>مدیریت ادمین ها</p>
              <FontAwesomeIcon
                icon={
                  params() === "addadmin" || params() === "adminlist"
                    ? faAngleUp
                    : faAngleDown
                }
              />
            </div>
            <div
              className={Style.AcardionBody}
              style={{
                display:
                  params() === "addadmin" || params() === "adminlist"
                    ? "block"
                    : "none",
              }}
            >
              <div
                onClick={() => {
                  CloseSideBar();
                  navigate("/addadmin");
                }}
              >
                <p>افزودن ادمین</p>
                <FontAwesomeIcon icon={faPlusCircle} />
              </div>
              <div
                onClick={() => {
                  CloseSideBar();
                  navigate("/adminlist");
                }}
              >
                <p>لیست ادمین ها</p>
                <FontAwesomeIcon icon={faList} />
              </div>
            </div>
          </li>
        ) : null}

        <li className={Style.SignOut} onClick={SignOutHandler}>
          <p>خروج</p>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </li>
      </ul>
    </section>
  );
};

export default PanelSideBar;
