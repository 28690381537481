
import React from 'react'
import PropertyFeaturesListContainer from '../../../Container/PropertyFeaturesListContainer/PropertyFeaturesListContainer'
import Layout from '../Layout'

const PropertyFeaturesList = () => {
  return (
    <Layout><PropertyFeaturesListContainer/></Layout>
  )
}

export default PropertyFeaturesList