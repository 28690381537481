import React from 'react'
import AddaffidavitContainer from '../../../../Container/AffidavitContainer/AddaffidavitContainer/AddaffidavitContainer'
import Layout from '../../Layout'


const Addaffidavit = () => {
  return (
    <Layout>
        <AddaffidavitContainer/>
    </Layout>
  )
}

export default Addaffidavit