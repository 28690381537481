import React from 'react'
import ForeignPropertyTypeListContainer from '../../../Container/ForeignPropertyTypeListContainer/ForeignPropertyTypeListContainer'
import Layout from '../Layout'

const ForeignPropertyTypeList = () => {
  return (
    <Layout><ForeignPropertyTypeListContainer/></Layout>
  )
}

export default ForeignPropertyTypeList