import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import LoadingPage from '../../LoadingPage/LoadingPage'
import Style from './AddCityToCountryForm.module.scss'
import { useForm } from "react-hook-form";
import Select from "react-select"; 
import { useEffect } from 'react';
import { AddCityToCountryAPI, getCountryListAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';

const AddCityToCountryForm = () => {
    const[loadingState, setLoadingState] = useState(false);
    const[loadingType,setLoadingType]=useState(true)
    const[countryType,setCountryType]=useState("")
    const[CountryList,setCountryListAPI]=useState()
    useEffect(()=>{
        getCountrylistHandler()
    },[])
    const getCountrylistHandler=async()=>{
        const data=await getCountryListAPI()
        setCountryListAPI(data.data.list)
        setLoadingType(false)
    }
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
      } = useForm();
      const onSubmit = (data) => {
        setLoadingState(true)
        let formData = new FormData();
        formData.append("CountryId", countryType.value);
        formData.append("title", data.title);
        formData.append("EnglishTitle", data.EnglishTitle);
        AddCityToCountryAPI(formData,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                reset()
                setLoadingState(false)
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
      }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      <h3>افزودن شهر به کشور</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="title">عنوان شهر</label>
          <input
            {...register("title", { required: false })}
            className={errors.title ? "valid" : "text"}
            autoComplete="title"
            type="text"
            name="title"
          />
        </li>
        <li>
          <label htmlFor="EnglishTitle">عنوان شهر به انگلیسی</label>
          <input
            {...register("EnglishTitle", { required: false })}
            className={errors.EnglishTitle ? "valid" : "text"}
            autoComplete="EnglishTitle"
            type="text"
            name="EnglishTitle"
          />
        </li>
        <li>
          <label htmlFor="country">کشور</label>
          <div className={Style.selectOption}>
            <Select
              {...register("country", { required: false })}
              onChange={setCountryType}
              options={CountryList&&CountryList.map(item=>{
                return ({ value: item.id, label: item.title })
              })}
              name="country"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isLoading={loadingType}
            />
          </div>
        </li>

      </ul>


      <Button
        className="w-50 my-3"
        type="submit"
        disabled={loadingState ? true : false}
      >
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  )
}

export default AddCityToCountryForm