
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { CloseTiketAPI, getTiketListAPI } from '../../API/BarangizanServices';
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import TiketTabel from '../../Components/Tabels/TiketListTabel/TiketListTabel';
import TiketChat from '../../Components/TiketChat/TiketChat';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
import { errorToast, succToast } from '../../Utils/Toast/Toast';

const NewTiketListContainer = () => {
    const [tiketInfo, setTiketInfo] = useState();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[dataList,setDataList]=useState()
    const[loadingState,setLoadingState]=useState(false)

    const handleShow = () =>{
        setShow(false)
        setShow2(false)
      };
      useEffect(()=>{
          getTiketListHandler(1)
      },[])
      const getTiketListHandler=async(pageNumber)=>{
          const data=await getTiketListAPI(pageNumber)
          setDataList(data.data.users)
      }
      let dataSource=[]
      dataList&&dataList.forEach(element => {
          if(!element.isLastMessageByAdmin){
              dataSource.push(element)
          }
      });
      
      const handelModal=(id,value)=>{
        setTiketInfo(value)
        if(id===1){
          setShow(true)
        }
        if(id===2){
          setShow2(true)
        }
      }
      const handelShow=()=>{
        setShow(false)
        setShow2(false)
      }
      const CloseTiketHandler=()=>{
        setLoadingState(true)
        CloseTiketAPI(tiketInfo.id,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                getTiketListHandler(1)
                handelShow()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
      }
  return (
    <div className='flexStyle'>
        <LoadingPage show={loadingState}/>
        <TitleHeader text={"لیست تیکت های خوانده نشده"}/>

        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>تیکت</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
                <TiketChat info={tiketInfo}/>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
        <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از بستن تیکت اطمینان دارید؟</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>CloseTiketHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
        </Modal.Body>
    </Modal>
        <TiketTabel data={dataSource}  showModal={(e,value)=>handelModal(e,value)}/>

    </div>
  )
}

export default NewTiketListContainer