import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { setPrePayAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Style from './AddPrePayForTahator.module.scss'
const AddPrePayForTahator = ({info,HandelModal}) => {
    
    const[loadingState,setLoadingState]=useState(false)
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
      } = useForm();
      const onSubmit = (data) => {
        let formData=new FormData()
        formData.append('tahatorId',info.key)
        formData.append('proposedEstateComission',data.proposedEstateComission)
        formData.append('wantedEstateComission',data.wantedEstateComission)
        setPrePayAPI(formData,(succ,res)=>{
            if(succ){
                succToast('باموفقیت ثبت شد!')
                setLoadingState(false)
                HandelModal()
            }else{
                errorToast(res.data.message)
                setLoadingState(false)
            }
        })
      }
  return (
    <div className='flexStyle'>
        <form  onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <ul className={Style.InputHolder}>
          <li>
            <label htmlFor="proposedEstateComission">کمسیون درخواست دهنده</label>
            <input
              {...register("proposedEstateComission", { required: true })}
              className={errors.Subject ? "valid" : "text"}
              autoComplete="proposedEstateComission"
              type="number"
              name="proposedEstateComission"
              
            />
          </li>
          <li>
            <label htmlFor="wantedEstateComission">کمسیون درخواست شده</label>
            <input
              {...register("wantedEstateComission", { required: true })}
              className={errors.Subject ? "valid" : "text"}
              autoComplete="wantedEstateComission"
              type="number"
              name="wantedEstateComission"
              
            />
          </li>
          <Button
          className="w-50 my-3"
          type="submit"
          disabled={loadingState ? true : false}
        >
          {loadingState ? (
            <small>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
                لطفا صبرکنید
            </small>
          ) : (
            "ثبت"
          )}
        </Button>
          </ul>
        </form>
    </div>
  )
}

export default AddPrePayForTahator