import { Divider } from 'antd'
import React,{ useState,useEffect } from 'react'
import { Button } from 'react-bootstrap'
import {  AddImagesToMagazineAPI, getMagazineGalleryAPI } from '../../../API/BarangizanServices'
import { errorToast, succToast } from '../../../Utils/Toast/Toast'
import LoadingPage from '../../LoadingPage/LoadingPage'
import MediaList from './MediaList/MediaList'
import Style from './MagazineGallery.module.scss'
const MagazineGallery = ({info}) => {
    const[galleryList,setGalleryLis]=useState()
    const[loadingState,setLoadingState]=useState(false)
    useEffect(()=>{
         getMagazineGallery()
    },[])
    const getMagazineGallery=async()=>{
        const data=await getMagazineGalleryAPI(info.id)
        setGalleryLis(data.data.gallery)
    }

    const [ImageFile, setImageFile] = useState([]);
    const imageChange = (evt) => {
      setImageFile(evt.target.files);
    };
    const ImageListUpload=()=>{
        if(ImageFile.length===0) return errorToast("فایلی انتخاب نشده است!")
        setLoadingState(true)
        let formData=new FormData()
        formData.append('id',info.id)
        for (let index = 0; index < ImageFile.length; index++) {
            formData.append(`Files[${index}].IsVideo`,false)
            formData.append(`Files[${index}].Item`,ImageFile[index])
        }
        AddImagesToMagazineAPI(formData,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                getMagazineGallery()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })

    }
    // const [videoFile, setVideoFile] = useState([]);
    // const videoFileHandler = (evt) => {
    //     setVideoFile(evt.target.files[0]);
    // };
    // const VideoFileUpload=()=>{
        
    //     if(videoFile.length===0) return errorToast("فایلی انتخاب نشده است!")
    //     setLoadingState(true)
    //     let formData=new FormData()
    //     formData.append('code',id.code)
    //     formData.append('item',videoFile)
    //     formData.append('IsVideo',true)
    //     AddVideoToProeprtyAPI(formData,(succ,res)=>{
    //         if(succ){
    //             succToast(res.data.message)
    //             setLoadingState(false)
    //             getMagazineGallery()
    //         }
    //         else{
    //             errorToast(res.data.errors[0])
    //             setLoadingState(false)
    //         }
    //     })
    // }





    let loadingHandler=null
    if(galleryList===undefined){
        loadingHandler=<LoadingPage show={true}/>
    }
    else{
        loadingHandler=<MediaList data={galleryList} getUpdate={()=>getMagazineGallery()}/>
    }
  return (
    <section className='flexStyle'>
        <LoadingPage show={loadingState}/>
        <div className='w-100 d-flex '>
            <div className='d-flex flex-column align-items-center text-center w-100'>
                <h6 className='fs-7'>آپلود تصاویر</h6>
                <label htmlFor="ImagesFile" className={Style.LabelImages}>انتخاب تصاویر</label>
                <input id='ImagesFile' type="file" style={{display:'none'}} accept="image/*" multiple onChange={(e) => imageChange(e)}/>
                <Button className='w-50 text-light' variant='info' onClick={()=>ImageListUpload()}>آپلود</Button>
            </div>
            {/* <div className='d-flex flex-column align-items-center text-center w-50'>
                <h6 className='fs-7'>آپلود ویدیو</h6>
                <label htmlFor="videoFile" className={Style.LabelImages}>انتخاب ویدیو</label>
                <input id='videoFile' type="file" style={{display:'none'}} accept="video/*" onChange={(e)=>videoFileHandler(e)}/>
                <Button className='w-50 text-light' variant='info' onClick={()=>VideoFileUpload()}>آپلود</Button>
            </div> */}
        </div>
        <Divider />
        {loadingHandler}
    </section>
  )
}

export default MagazineGallery