import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addAdminAPI } from "../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import Style from "./AddAdminForm.module.scss";
const AddAdminForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
      setLoadingState(true)
    let info={
        username:data.adminUsername,
        password:data.password
    }
    addAdminAPI(info,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            reset()
            setLoadingState(false)
        }
        else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
        }
    })
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <h3>افزودن ادمین</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="adminUsername">نام کاربری ادمین</label>
          <input
            {...register("adminUsername", { required: true })}
            className={errors.adminUsername ? "valid" : "text"}
            autoComplete="adminUsername"
            type="mail"
            name="adminUsername"
            placeholder="به صورت ایمیل"
          />
        </li>
        <li>
          <label htmlFor="password"> رمزعبور</label>
          <input
            {...register("password", { required: true })}
            className={errors.password ? "valid" : "text"}
            autoComplete="password"
            type="password"
            name="password"
          />
        </li>
      </ul>
      <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> 
            لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  );
};

export default AddAdminForm;
