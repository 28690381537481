import React from 'react'
import PropertyReservationListContainer from '../../../Container/PropertyReservationListContainer/PropertyReservationListContainer'
import Layout from '../Layout'

const PropertyReservationList = () => {
  return (
    <Layout><PropertyReservationListContainer/></Layout>
  )
}

export default PropertyReservationList