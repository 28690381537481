import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BlockUserAPI, getUserListAPI, unBlockUserAPI } from '../../API/BarangizanServices';
import EditUserForm from '../../Components/Forms/EditUserForm/EditUserForm';
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import UserListTabel from '../../Components/Tabels/UserListTabel/UserListTabel';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
import { errorToast, succToast } from '../../Utils/Toast/Toast';

const UserListContainer = () => {
    const[UserData,setUserData]=useState()
    const[pageDetail,setPageDetail]=useState()
    useEffect(()=>{
        getUserListHandler(1)
    },[])
    const getUserListHandler=async(pageNumber)=>{
      setLoadingState(true)
        const data=await getUserListAPI(pageNumber)
        setUserData(data.data.users)
        setPageDetail(data.data.pagination)
        setLoadingState(false)
        window.scroll(0,0)
    }

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[UserInfo,setUserInfo]=useState()
    const[loadingState,setLoadingState]=useState()
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
      
    };
    const handelModal=(id,value)=>{
        setUserInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }
    }
    const activeHandler=(info)=>{
        setLoadingState(true)
        if(info.active){
            BlockUserAPI(info.userName,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    setLoadingState(false)
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        }
        else{
            unBlockUserAPI(info.userName,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    setLoadingState(false)
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        }
    }
    
  return (
    <div className='flexStyle'>
    <LoadingPage show={loadingState}/>
    <TitleHeader text={"لیست کاربران"}/>
    <Modal show={show} className={'RTL'} size={'xl'}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>ویرایش کاربر</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-around w-100'>
          <EditUserForm id={UserInfo} getUpdate={()=>getUserListHandler(1)} closeModal={()=>handleShow}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>

    <UserListTabel data={UserData} pageDetail={pageDetail} updatePage={(page)=>getUserListHandler(page)} showModal={(e,value)=>handelModal(e,value)} ActivationUser={activeHandler}/>
</div>
  )
}

export default UserListContainer