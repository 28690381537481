import React from 'react'
import LoginForm from '../../Components/Forms/LoginForm/LoginForm'

const LoginContainer = () => {
  return (
    <div className='flexStyle'>
        <LoginForm/>
    </div>
  )
}

export default LoginContainer