import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import React, { useRef } from "react";
import { Button, Spinner } from "react-bootstrap";
import Style from "./MessageList.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const MessageList = ({ list }) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [list]);

  
  return (
    <section className="flexStyle">
      {list === undefined ? (
        <Spinner variant="warning" animation="border" />
      ) : (
        <ul className={Style.ChatListHolder}>
          {list.map((item) => {
            return (
              <li
                key={item.id}
                className={
                  item.isAdmin ? Style.AdminLIHolder : Style.UserLIHolder
                }
              >
                <span>
                  {item.isAdmin ? (
                    <p>
                      {" "}
                      <FontAwesomeIcon icon={faHeadset} />  ادمین
                    </p>
                  ) : (
                    <p>
                      {" "}
                      <UserOutlined />
                       کاربر
                    </p>
                  )}
                </span>
                <p>{item.message}</p>
                <section className={Style.FileHolder}>
                  {item.file && (
                    <Button
                      variant="danger"
                      onClick={() =>
                        window.open(
                          `https://api.barangizan.ir${item.file}`,
                          "_blank"
                        )
                      }
                    >
                      فایل پیوست{" "}
                      <DownloadOutlined style={{ fontSize: "1.2rem" }} />{" "}
                    </Button>
                  )}
                  <small>{item.dateAdded}</small>
                </section>
              </li>
            );
          })}
          <li ref={messagesEndRef}></li>
        </ul>
      )}
    </section>
  );
};

export default MessageList;
