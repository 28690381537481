import React, { useEffect, useState } from 'react'
import { AddUnitToProjectAPI , EditUnitProjectAPI, getBlockListAPI, getUnitDetailAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { Controller, useForm } from "react-hook-form";
import LoadingPage from '../../LoadingPage/LoadingPage';
import Select from "react-select";
import { Button,Spinner } from "react-bootstrap";

import Style from './EditUnitForm.module.scss'
import Editor from '../../Editor/Editor';


const EditUnitForm = ({ info,getUpdate,closeModal }) => {
  const [ProjectListState, setProjectListState] = useState(null);
    const [ProjectSelect, setProjectSelect] = useState(null);
    const [BlockList, setBlockList] = useState();
    const [BlockSelect, setBlockSelect] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [loadingType, setLoadingType] = useState(true);
    const [featureListState, setFeatureListState] = useState();
    const [description, setDescription] = useState("");
    const [UnitDetail, setUnitDetail] = useState("");

    
    useEffect(() => {
      getDataHandler();
    }, []);
  
    const getDataHandler = async () => {


      setLoadingType(false);
      const detail = await getUnitDetailAPI(info.UnitCode);
      setUnitDetail(detail.data.unit);
      setFeatureListState(detail.data.featuresList);
      const Blocks=await getBlockListAPI(detail.data.unit.projectEstateCode)
      setBlockList(Blocks.data.list)
    };
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      control,
    } = useForm();
    const onSubmit = (data) => {
      setLoadingState(true);
      let formData = new FormData();
      formData.append("NewCode", data.NewCode);
      formData.append("Code", UnitDetail.code);
      formData.append("Title", data.Title);
      formData.append("BlockId",BlockSelect===null? defaultBlockFloor[0].blockId:BlockSelect.value.split('-')[0]);
      formData.append("Floor",BlockSelect===null?defaultBlockFloor[0].floor: BlockSelect.value.split('-')[1]);
      formData.append("description", description===""?UnitDetail.description:description);
      formData.append("Area", data.Metraj);
      formData.append("Price", data.price);
      formData.append("discountedPrice", data.discountedPrice);
      formData.append("PrePaymentPrice", data.PrePaymentPrice);

      for (var i = 0; i < parseInt(featureListState.length); i++) {
        formData.append(`Features[${i}].Key`, data.Features[i].Key);
        let featureString = [];
        data.Features[i].value.forEach((element) => {
          featureString.push(element.label);
        });
        formData.append(`Features[${i}].Value`, featureString.toString());
      }
      EditUnitProjectAPI(formData, (succ, res) => {
        if (succ) {
          succToast(res.data.message);
          setLoadingState(false)
          closeModal()
          getUpdate()
        } else {
          errorToast(res.data.errors[0]);
          setLoadingState(false);
        }
      });
    };

    let defaultValueFeature = [];
    featureListState &&
      featureListState.forEach((element) => {
        let value = element.value !== null ? element.value.split(",") : [""];
        let testarray = [];
        value.forEach((element) => {
          testarray.push({
            label: element,
            value: element,
          });
        });
        defaultValueFeature.push(testarray);
      });
    
    
    let defaultBlockFloor=UnitDetail&&BlockList &&BlockList.filter(element =>{ return element.blockId===UnitDetail.blockId&&element.floor===UnitDetail.floor});
  console.log(defaultBlockFloor)
    return (
    <>
    {
      UnitDetail===""||BlockList===undefined?<Spinner animation='border' variant='warning'/>:<form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      <h3>افزودن واحد به پروژه ملکی خارجی</h3>
      <ul className={Style.InputHolder}>

  
        <li>
          <label htmlFor="province"> بلوک و طبقه پروژه</label>
          <div className={Style.selectOption}>
            <Select
              {...register("province", { required: false })}
              onChange={setBlockSelect}
              options={
                  BlockList &&
                  BlockList.map((item) => {
                  return { value: `${item.blockId}-${item.floor}`, label: `${item.blockName} - طبقه ${item.floor}` };
                })
              }
              name="province"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isLoading={loadingType}
              defaultValue={{
                label: `${defaultBlockFloor[0].blockName} - طبقه ${defaultBlockFloor[0].floor}`,
                value: `${defaultBlockFloor[0].blockId}-${defaultBlockFloor[0].floor}`,
              }}
            />
          </div>
        </li>
  
  
        <li>
          <label htmlFor="NewCode">کدواحد </label>
          <input
            {...register("NewCode", { required: true })}
            className={errors.NewCode ? "valid" : "text"}
            autoComplete="NewCode"
            type="text"
            name="NewCode"
            defaultValue={UnitDetail.code}
          />
        </li>
        <li>
          <label htmlFor="Title">نام واحد </label>
          <input
            {...register("Title", { required: true })}
            className={errors.Title ? "valid" : "text"}
            autoComplete="Title"
            type="text"
            name="Title"
            defaultValue={UnitDetail.title}
          />
        </li>
        <li>
          <label htmlFor="Metraj">متراژ </label>
          <input
            {...register("Metraj", { required: true })}
            className={errors.Metraj ? "valid" : "text"}
            autoComplete="Metraj"
            type="text"
            name="Metraj"
            defaultValue={UnitDetail.area}
          />
        </li>
        <li>
          <label htmlFor="price">قیمت </label>
          <input
            {...register("price", { required: true })}
            className={errors.price ? "valid" : "text"}
            autoComplete="price"
            type="number"
            name="price"
            defaultValue={UnitDetail.price}
          />
        </li>
        <li>
          <label htmlFor="discountedPrice">قیمت با تخفیف </label>
          <input
            {...register("discountedPrice", { required: true })}
            className={errors.discountedPrice ? "valid" : "text"}
            autoComplete="discountedPrice"
            type="number"
            name="discountedPrice"
            defaultValue={UnitDetail.discountedPrice}
          />
        </li>
        <li>
          <label htmlFor="PrePaymentPrice">قیمت پیش پرداخت </label>
          <input
            {...register("PrePaymentPrice", { required: true })}
            className={errors.PrePaymentPrice ? "valid" : "text"}
            autoComplete="PrePaymentPrice"
            type="number"
            name="PrePaymentPrice"
            defaultValue={UnitDetail.prePaymentPrice}
          />
        </li>
  
  
  
  
  
      </ul>
  
      <ul className={Style.InputHolder}>
              {featureListState &&
                featureListState.map((item, i) => {
                  return (
                    <li key={item.id}>
                      <label htmlFor="featureCount">{`  ویژگی ${i + 1}`}</label>
                      <input
                        {...register(`Features[${i}].Key`)}
                        className={errors.username ? "valid" : "text"}
                        autoComplete={`Features[${i}].Key`}
                        type="text"
                        name={`Features[${i}].Key`}
                        style={{ marginBottom: "0.5rem" }}
                        defaultValue={item.text}
                        readOnly
                      />
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                        }) => (
                          <div className={Style.selectOption}>
                            <Select
                              defaultValue={defaultValueFeature[i]}
                              options={item.options.map((item) => {
                                return {
                                  label: item.text,
                                  value: item.text,
                                };
                              })}
                              name={`Features[${i}].value`}
                              // onChange={setFeatureTest}
                              onChange={onChange}
                              placeholder="انتخاب کنید"
                              className={Style.selectOptionsearch}
                              isMulti
                            />
                          </div>
                        )}
                        name={`Features[${i}].value`}
                        control={control}
                        rules={{ required: false }}
                        defaultValue={defaultValueFeature[i]}
                      />
                    </li>
                  );
                })}
            </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="description">توضیحات ملک</label>
        <Editor
          desc={description}
          setdescription={setDescription}
          defaultValue={UnitDetail.description}
        />
      </div>
  
  
      <Button className="w-100 my-3" type="submit">
        ثبت
      </Button>
    </form>
    }
    </>
  )
};

export default EditUnitForm;
