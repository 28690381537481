import React from 'react'
import AddTourContainer from '../../../Container/AddTourContainer/AddTourContainer'
import Layout from '../Layout'

const AddTour = () => {
  return (
    <Layout><AddTourContainer/></Layout>
  )
}

export default AddTour