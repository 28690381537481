import React, { useEffect, useState } from 'react'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import { Button, Modal } from 'react-bootstrap'
import { DeleteCityAPI, getCitiesOfCountriesListAPI } from '../../API/BarangizanServices'
import { errorToast, succToast } from '../../Utils/Toast/Toast'
import CitiesOfContriesListTabel from '../../Components/Tabels/CitiesOfContriesListTabel/CitiesOfContriesListTabel'
import EditCityNameForm from '../../Components/Forms/EditCityNameForm/EditCityNameForm'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'
const CityListContainer = () => {
    useEffect(()=>{
        getCitiesOfCountriesHandler()
    },[])


    const getCitiesOfCountriesHandler=async()=>{
        const data=await getCitiesOfCountriesListAPI()
        setCountryList(data.data.list)
    }
    const[CountryList,setCountryList]=useState()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[loadingState,setLoadingState]=useState(false)
    const[CityInfo,setCityInfo]=useState()
    const handleShow = () =>{
        setShow(false)
        setShow2(false)
      };
      
    const handelModal=(id,value)=>{
        setCityInfo(value)
        if(id===1){
          setShow(true)
        }
        if(id===2){
          setShow2(true)
        }
  
      }
      const deleteCountryHandler=()=>{
        setLoadingState(true)
        DeleteCityAPI(CityInfo.id,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                handleShow()
                getCitiesOfCountriesHandler()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
      }
  return (
    <div className='flexStyle'>
        <TitleHeader text={'لیست شهرهای ثبت شده'}/>
        <LoadingPage show={loadingState}/>
        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>ویرایش شهر</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-around w-100'>
              <EditCityNameForm info={CityInfo} getUpdate={()=>getCitiesOfCountriesHandler()} closeModal={()=>handleShow()}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteCountryHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>
        <CitiesOfContriesListTabel data={CountryList} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default CityListContainer