import React from 'react'
import CityListContainer from '../../../Container/CityListContainer/CityListContainer'
import Layout from '../Layout'

const CityList = () => {
  return (
    <Layout><CityListContainer/></Layout>
  )
}

export default CityList