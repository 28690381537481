import React, { useState } from 'react'
import LoadingPage from "../../LoadingPage/LoadingPage";
import Style from "./RejectForeignPropertyForm.module.scss";
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import { RejectForeignPropertyRequestAPI } from '../../../API/BarangizanServices';


const RejectForeignPropertyForm = ({ProprtyInfo,getUpdate,closeModal}) => {
    const [loadingState, setLoadingState] = useState();
    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm();
    const onSubmit = (data) => {
        setLoadingState(true)
        let info={
            id:ProprtyInfo.id,
            RejectReason:data.RejectReason
        }
        RejectForeignPropertyRequestAPI(info,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                getUpdate()
                closeModal()
                setLoadingState(false)
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
    };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
    <LoadingPage show={loadingState} />
    <div className={Style.InputHolder}>
      <textarea
      {...register("RejectReason", { required: true })}
      className={errors.username ? "valid" : "text"}
      placeholder={"دلیل رد ملک"}
      />
    </div>
    <Button variant="primary" type="submit" style={{ width: "8rem" }}>
      ثبت
    </Button>
  </form>
  )
}

export default RejectForeignPropertyForm