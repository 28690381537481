import { Button } from 'react-bootstrap';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import LoadingPage from '../../LoadingPage/LoadingPage'
import Style from './LoginForm.module.scss'
import { LoginAdminAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { useNavigate } from 'react-router-dom';
const LoginForm = () => {
    const [loadingState, setLoadingState] = useState(false);
    const navigate=useNavigate()
    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm();
    const onSubmit = (data) =>{
      setLoadingState(true)
      let info={
        username:data.username,
        password:data.password
      }
      LoginAdminAPI(info,(succ,res)=>{
        if(succ){
          succToast(res.data.message)
          setLoadingState(false)
          localStorage.setItem('AdminBarangizanToken',res.data.data.token)
          localStorage.setItem('MainAdmin',res.data.data.isMainAdmin)
          setTimeout(() => {
            navigate('/')
          }, 500);
        }
        else{
          errorToast(res.data.errors[0])
          setLoadingState(false)
          
        }
      })
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
    <LoadingPage show={loadingState} />

        <div className={Style.logoHolder}>
        <img src="/Assets/Images/Logo/logo.png" alt="" />
        </div>
        <div className={Style.InputHolder}>
        <input
            {...register("username", { required: true })}
            className={errors.username ? "valid" : "text"}
            placeholder={"نام کاربری"}
            autoComplete="username"
            type="tel"
        />
        <input
            type="password"
            {...register("password", { required: true })}
            className={errors.password ? "valid" : "text"}
            placeholder="رمزعبور"
            autoComplete="password"
            
        />
        
        </div>
        <Button variant="primary" type="submit" style={{ width: "8rem" }}>
        ورود
        </Button>
    </form>
  )
}

export default LoginForm