import React from 'react'
import AddUnitToProjectContainer from '../../../Container/AddUnitToProjectContainer/AddUnitToProjectContainer'
import Layout from '../Layout'

const AddUnitToProject = () => {
  return (
    <Layout><AddUnitToProjectContainer/></Layout>
  )
}

export default AddUnitToProject