import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { EditCityNameAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from '../../LoadingPage/LoadingPage';
import Style from './EditCityNameForm.module.scss'
const EditCityNameForm = ({info,getUpdate,closeModal}) => {
    
    const[loadingState, setLoadingState] = useState(false);
    
    const {
        register,
        formState: { errors },
        handleSubmit,
      } = useForm();
      const onSubmit = (data) => {
          setLoadingState(true)
          const formData=new FormData()
          formData.append("Id",info.id)
          formData.append("title",data.title)
          formData.append("EnglishTitle",data.EnglishTitle)
          EditCityNameAPI(formData,(succ,res)=>{
              if(succ){
                  succToast(res.data.message)
                  setLoadingState(false)
                  getUpdate()
                  closeModal()
              }
              else{
                  errorToast(res.data.errors[0])
                  setLoadingState(false)
              }
          })
      }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="title">عنوان شهر</label>
          <input
            {...register("title", { required: false })}
            className={errors.title ? "valid" : "text"}
            autoComplete="title"
            type="text"
            name="title"
            defaultValue={info.city}
          />
        </li>
        <li>
          <label htmlFor="EnglishTitle">عنوان شهر به انگلیسی</label>
          <input
            {...register("EnglishTitle", { required: false })}
            className={errors.EnglishTitle ? "valid" : "text"}
            autoComplete="EnglishTitle"
            type="text"
            name="EnglishTitle"
            defaultValue={info.englishTitle}
          />
        </li>

      </ul>


      <Button
        className="w-50 my-3"
        type="submit"
        disabled={loadingState ? true : false}
      >
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  )
}

export default EditCityNameForm