import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {
  AllocationAffidavitAPI,
  getAffidavitDetailAPI,
  getAffidavitListAPI,
} from "../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
const AllocationAffidavitForm = ({ info,HandelModal }) => {
  const [AffidavitDetailState, setAffidavitDetailState] = useState();
  const [AffidavitListState, setAffidavitListState] = useState([]);
  const [loadingState, setloadingState] = useState(false);
  const [affidavitCreatedSstate, setAffidavitCreatedSstate] = useState(null);
  const [jsonFormData, setJasonFormData] = useState(null);
  useEffect(() => {
    getDataListHandler();
  }, []);
  const getDataListHandler = () => {
    getAffidavitListAPI((succ, res) => {
      if (succ) {
        setAffidavitListState(res.data.data.list);
      } else {
        errorToast(res.data.errors[0]);
      }
    });
  };
  const selectAffidavitHandler = (value) => {
    setloadingState(true);
    getAffidavitDetailAPI(value.value, (succ, res) => {
      if (succ) {
        setAffidavitDetailState(res.data.data.data);
        setloadingState(false);
      } else {
        errorToast(res.data.errors[0]);
        setloadingState(false);
      }
    });
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  let formData = new FormData();
  const onSubmit = (data) => {
    formData.append("TahatorId", info.key);
    formData.append("AffidavitTemplateId", AffidavitDetailState.id);
    formData.append("IsFinal", false);
    for (
      var i = 0;
      i < parseInt(AffidavitDetailState.dynamicVariables.length);
      i++
    ) {
      formData.append(`Variables[${i}].Key`, data.variable[i].Key);
      formData.append(`Variables[${i}].Value`, data.variable[i].value);
    }
    AllocationAffidavitAPI(formData, (succ, res) => {
      if (succ) {
        setAffidavitCreatedSstate(res.data.data);
      } else {
        errorToast(res.data.errors[0]);
      }
    });
    let object = {};
    formData.forEach((value, key) => object[key] = value);
    setJasonFormData(object)
  };
  const finallSubmit = () => {
    let fnalize=jsonFormData
    fnalize['IsFinal']='true'
    var form_data = new FormData();

    for ( var key in fnalize ) {
        form_data.append(key, fnalize[key]);
    }
    AllocationAffidavitAPI(form_data, (succ, res) => {
      if (succ) {
        succToast(res.data.message)
        HandelModal()
      } else {
        errorToast(res.data.errors[0]);
      }
    });
  };

  return (
    <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Select
          onChange={selectAffidavitHandler}
          options={AffidavitListState.map((item) => {
            return { value: item.id, label: item.title };
          })}
          name="country"
          placeholder="انتخاب کنید"
          isLoading={AffidavitListState.length === 0}
        />
      </div>
      <ul className="w-100 p-1 my-2 d-flex flex-wrap justify-content-center">
        {AffidavitDetailState &&
          AffidavitDetailState.dynamicVariables.map((item, i) => {
            return (
              <li key={i} className={"m-1 p-2"}>
                <label htmlFor="variable">{`  متغیر ${i + 1}`}</label>
                <input
                  {...register(`variable[${i}].Key`)}
                  className={errors.username ? "valid" : "text"}
                  autoComplete={`Variables[${i}].Key`}
                  type="text"
                  name={`Variables[${i}].Key`}
                  style={{ marginBottom: "0.5rem" }}
                  defaultValue={item}
                  readOnly
                />
                <input
                  {...register(`variable[${i}].value`)}
                  className={errors.username ? "valid" : "text"}
                  autoComplete={`variable[${i}].value`}
                  type="text"
                  name={`variable[${i}].value`}
                  style={{ marginBottom: "0.5rem" }}
                  placeholder={"متغیر را پر کنید..."}
                />
              </li>
            );
          })}
      </ul>
      <Button
        className="w-100 my-3"
        type="submit"
        disabled={loadingState ? true : false}
      >
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
      {affidavitCreatedSstate !== null ? (
        <div
          className="w-100 p-2 mb-3 bg-light border border-primary"
          dangerouslySetInnerHTML={{ __html: affidavitCreatedSstate }}
        ></div>
      ) : null}
      {affidavitCreatedSstate !== null ? (
        <Button onClick={finallSubmit} className="bg-warning w-100 border-0">
          ثبت نهایی و ارسال برای طرفین
        </Button>
      ) : null}
    </form>
  );
};

export default AllocationAffidavitForm;
