import React, { useEffect, useState } from 'react'
import { AddUnitToProjectAPI, getAllForeignPropertyProjectListAPI, getBlockListAPI, getFeaturesListOptionsAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { Controller, useForm } from "react-hook-form";
import LoadingPage from '../../LoadingPage/LoadingPage';
import Select from "react-select";
import { Button } from "react-bootstrap";
import { Alert } from "antd";
import Style from './AddUnitToProjectForm.module.scss'
import Editor from '../../Editor/Editor';
import { useMemo } from 'react';
const AddUnitToProjectForm = () => {
    const [ProjectListState, setProjectListState] = useState(null);
    const [ProjectSelect, setProjectSelect] = useState(null);
    const [BlockList, setBlockList] = useState([]);
    const [BlockSelect, setBlockSelect] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [loadingType, setLoadingType] = useState(true);
    const [featureListState, setFeatureListState] = useState();
    const [description, setDescription] = useState("");
    const [ImageUrls, setImageUrls] = useState();
    const [ImageFile, setImageFile] = useState();
    const imageChange = (evt) => {
      setImageFile(evt.target.files);
      let imageUrlList = [];
      Array.from(evt.target.files).forEach((element) =>
        imageUrlList.push(URL.createObjectURL(element))
      );
      setImageUrls(imageUrlList);
    };
  
    useEffect(() => {
      getDataHandler();
    }, []);
  
    const getDataHandler = async () => {
      const features = await getFeaturesListOptionsAPI(1);
      setFeatureListState(features.data.data);
        const projects=await getAllForeignPropertyProjectListAPI()
        setProjectListState(projects.data.list)
      setLoadingType(false);
    };
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      control,
    } = useForm();
    const onSubmit = (data) => {
      setLoadingState(true);
      let formData = new FormData();
      formData.append("Title", data.Title);
      formData.append("ProjectCode", ProjectSelect);
      formData.append("BlockId", BlockSelect.value.split('-')[0]);
      formData.append("Floor", BlockSelect.value.split('-')[1]);
      formData.append("description", description);
      formData.append("Area", data.Metraj);
      formData.append("Price", data.price);
      formData.append("discountedPrice", data.discountedPrice);
      formData.append("PrePaymentPrice", data.PrePaymentPrice);

      
      for (let index = 0; index < ImageFile.length; index++) {
        formData.append(`Images[${index}].IsVideo`, false);
        formData.append(`Images[${index}].Item`, ImageFile[index]);
      }
      for (var i = 0; i < parseInt(featureListState.length); i++) {
        formData.append(`Features[${i}].Key`, data.Features[i].Key);
        let featureString = [];
        data.Features[i].value.forEach((element) => {
          featureString.push(element.label);
        });
        formData.append(`Features[${i}].Value`, featureString.toString());
      }
      AddUnitToProjectAPI(formData, (succ, res) => {
        if (succ) {
          succToast(res.data.message);
          setLoadingState(false);
          reset();
        } else {
          errorToast(res.data.errors[0]);
          setLoadingState(false);
        }
      });
    };
    
    const getBlockAndFloorDetail=async(value)=>{
        setProjectSelect(value.value.code)
        const Blocks=await getBlockListAPI(value.value.code)
        setBlockList(Blocks.data.list)
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
    <LoadingPage show={loadingState} />
    <h3>افزودن واحد به پروژه ملکی خارجی</h3>
    <ul className={Style.InputHolder}>
      <li>
        <label htmlFor="title">نام پروژه</label>
        <div className={Style.selectOption}>
          <Select
            {...register("title", { required: false })}
            onChange={getBlockAndFloorDetail}
            options={
                ProjectListState &&
                ProjectListState.map((item) => {
                return { value: item, label: item.title };
              })
              
            }
            name="title"
            placeholder="انتخاب کنید"
            className={Style.selectOptionsearch}
            isLoading={loadingType}
          />
        </div>
      </li>

      <li>
        <label htmlFor="province"> بلوک و طبقه پروژه</label>
        <div className={Style.selectOption}>
          <Select
            {...register("province", { required: false })}
            onChange={setBlockSelect}
            options={
                BlockList &&
                BlockList.map((item) => {
                return { value: `${item.blockId}-${item.floor}`, label: `${item.blockName} - طبقه ${item.floor}` };
              })
            }
            name="province"
            placeholder="انتخاب کنید"
            className={Style.selectOptionsearch}
            isLoading={loadingType}
          />
        </div>
      </li>
      <li>
        <label htmlFor="Title">نام واحد </label>
        <input
          {...register("Title", { required: true })}
          className={errors.Title ? "valid" : "text"}
          autoComplete="Title"
          type="text"
          name="Title"
        />
      </li>

      <li>
        <label htmlFor="Metraj">متراژ </label>
        <input
          {...register("Metraj", { required: true })}
          className={errors.Metraj ? "valid" : "text"}
          autoComplete="Metraj"
          type="text"
          name="Metraj"
        />
      </li>
      <li>
        <label htmlFor="price">قیمت </label>
        <input
          {...register("price", { required: true })}
          className={errors.price ? "valid" : "text"}
          autoComplete="price"
          type="number"
          name="price"
        />
      </li>
      <li>
        <label htmlFor="discountedPrice">قیمت با تخفیف </label>
        <input
          {...register("discountedPrice", { required: true })}
          className={errors.discountedPrice ? "valid" : "text"}
          autoComplete="discountedPrice"
          type="number"
          name="discountedPrice"
        />
      </li>
      <li>
        <label htmlFor="PrePaymentPrice">قیمت پیش پرداخت </label>
        <input
          {...register("PrePaymentPrice", { required: true })}
          className={errors.PrePaymentPrice ? "valid" : "text"}
          autoComplete="PrePaymentPrice"
          type="number"
          name="PrePaymentPrice"
        />
      </li>





    </ul>

    <ul className={Style.InputHolder}>
      {featureListState &&
        featureListState.map((item, i) => {
          return (
            <li key={item.id}>
              <label htmlFor="featureCount">{`  ویژگی ${i + 1}`}</label>
              <input
                {...register(`Features[${i}].Key`)}
                className={errors.username ? "valid" : "text"}
                autoComplete={`Features[${i}].Key`}
                type="text"
                name={`Features[${i}].Key`}
                style={{ marginBottom: "0.5rem" }}
                defaultValue={item.text}
                readOnly
              />
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <div className={Style.selectOption}>
                    <Select
                      {...register(`Features[${i}].value`)}
                      options={item.options.map((item) => {
                        return {
                          label: item.text,
                          value: item.text,
                        };
                      })}
                      name={`Features[${i}].value`}
                      // onChange={setFeatureTest}
                      onChange={onChange}
                      placeholder="انتخاب کنید"
                      className={Style.selectOptionsearch}
                      isMulti
                      defaultValue={[{ label: "ندارد", value: 0 }]}
                    />
                  </div>
                )}
                name={`Features[${i}].value`}
                control={control}
                rules={{ required: false }}
                defaultValue={[{ label: "ندارد", value: 0 }]}
              />
            </li>
          );
        })}
    </ul>
    <div className={Style.DescHolder}>
      <label htmlFor="description">توضیحات ملک</label>
      <Editor
        desc={description}
        setdescription={setDescription}
        defaultValue={""}
      />
    </div>
    <div className={Style.ImagePart}>
      <label htmlFor="imageFile" className={Style.ImageLabel}>
        انتخاب تصویر
        <input
          type="file"
          name="imageFile"
          id="imageFile"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => imageChange(e)}
          multiple
        />
      </label>
    </div>
    <ul className={Style.InputHolder}>
      {ImageUrls &&
        ImageUrls.map((item, index) => {
          return (
            <li key={index} className={Style.ImagePerview}>
              <img
                src={item}
                className={Style.ImagePerview}
                alt=""
                onClick={() => window.open(item, "_blank")}
              />
            </li>
          );
        })}
    </ul>
    <Alert
      type="warning"
      message=" درصورت نداشتن تخفیف، قیمت و قیمت با تخفیف را یکسان وارد کنید"
      showIcon
      className="w-100"
    />
    <Alert
      type="warning"
      message=" درصورت نداشتن قیمت های بازدید عدد 1 را وارد کنید!"
      showIcon
      className="w-100 mt-2"
    />

    <Button className="w-100 my-3" type="submit">
      ثبت
    </Button>
  </form>
  )
}

export default AddUnitToProjectForm