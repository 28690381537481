import React from 'react'
import CooperationRequestContainer from '../../../Container/CooperationRequestContainer/CooperationRequestContainer'
import Layout from '../Layout'

const CooperationRequest = () => {
  return (
    <Layout><CooperationRequestContainer/></Layout>
  )
}

export default CooperationRequest