import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { DeleteTourAPI, getToursListAPI } from '../../API/BarangizanServices';
import EditTourForm from '../../Components/Forms/EditTourForm/EditTourForm';
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import TourListTabel from '../../Components/Tabels/TourListTabel/TourListTabel';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
import { errorToast, succToast } from '../../Utils/Toast/Toast';

const TourListContainer = () => {
    const[TourData,setTourData]=useState()
    const[TotalPage,setTotalPage]=useState()
    useEffect(()=>{
        getTourListHandler(1)
    },[])
    const getTourListHandler=async(pageNumber)=>{
        const data=await getToursListAPI(pageNumber)
        setTourData(data.data.list)
        setTotalPage(data.data.pagination.totalPages)

    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[TourInfo,setTourInfo]=useState()
    const[loadingState,setLoadingState]=useState()
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
      
    };
    const handelModal=(id,value)=>{
      setTourInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }
    }
    const deleteTourHandler=()=>{
      setLoadingState(true)
      DeleteTourAPI(TourInfo.id,(succ,res)=>{
        if(succ){
          succToast(res.data.message)
          getTourListHandler(1)
          setLoadingState(false)
          handleShow()
        }
        else{
          errorToast(res.data.errors[0])
          setLoadingState(false)
        }
      })
    }
  return (
    <div className='pageHolder'>
        <LoadingPage show={loadingState}/>
        <TitleHeader text={"لیست تورها"}/>
        
        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>ویرایش تور</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <EditTourForm info={TourInfo} getUpdate={()=>getTourListHandler(1)}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>

          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteTourHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>
        <TourListTabel data={TourData} totalPages={TotalPage} showModal={(e,value)=>handelModal(e,value)}/>
        
    </div>
  )
}

export default TourListContainer