import React,{useEffect, useState} from 'react'
import { Button, Modal } from 'react-bootstrap'
import { DeleteAffidavitAPI, getAffidavitListAPI } from '../../../API/BarangizanServices'
import AffidavvitDetail from '../../../Components/Details/AffidavvitDetail/AffidavvitDetail'
import AffidavitListTabel from '../../../Components/Tabels/AffidavitListTabel/AffidavitListTabel'
import TitleHeader from '../../../Components/TitleHeader/TitleHeader'
import { errorToast, succToast } from '../../../Utils/Toast/Toast'

const AffidavitListContainer = () => {
    const[affidavitState,setAffidavitState]=useState(null)
    const[affidavitInfo,setAffidavitInfo]=useState(null)
    const[RemoveModalState,setRemoveModalState]=useState(false)
    const[DetailModalState,setDetailModalState]=useState(false)
    useEffect(()=>{
        getAffidavitDataHandler()
    },[])
    const getAffidavitDataHandler=()=>{
        getAffidavitListAPI((succ,res)=>{
            if(succ){
                setAffidavitState(res.data.data.list)
            }
            else{
                errorToast(res.data.errors[0])
            }
        })
    }
    const handelModal=(id,value)=>{
        setAffidavitInfo(value)
        if(id===1){
            setRemoveModalState(true)
        }
        if(id===2){
            setDetailModalState(true)
        }
  
      }
      const deleteAffidavitHandler=()=>{
        DeleteAffidavitAPI(affidavitInfo.id,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setRemoveModalState(false)
                getAffidavitDataHandler()
            }
            else{
                errorToast(res.data.errors[0])
                setRemoveModalState(false)
            }
        })
      }
  return (
    <div className='flexStyle'>
        <TitleHeader text={'لیست قولنامه ها'}/>
        <Modal show={DetailModalState} className={'RTL'} size={'lg'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>جزئیات</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex justify-content-around w-100'>
            <AffidavvitDetail info={affidavitInfo}/>
          </Modal.Body>
          <Modal.Footer>
            <Button className='bg-danger text-white border-0' onClick={()=>setDetailModalState(false)}>بستن</Button>
          </Modal.Footer>
      </Modal>
        <Modal show={RemoveModalState} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteAffidavitHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={()=>setRemoveModalState(false)}>خیر</Button>
          </Modal.Body>
      </Modal>
        <AffidavitListTabel data={affidavitState} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default AffidavitListContainer