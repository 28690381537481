import React, { useEffect, useState } from 'react'
import { getPropertyVisitRequestAPI } from '../../API/BarangizanServices'
import PropertyVisitListTabel from '../../Components/Tabels/PropertyVisitListTabel/PropertyVisitListTabel'
import TitleHeader from '../../Components/TitleHeader/TitleHeader'

const PropertyVisitListContianer = () => {
    useEffect(()=>{
        getRequestsHandler(1)
    },[])
    const[requestsState,setRequestsState]=useState()
    const getRequestsHandler =async(pageNumber)=>{
        const data=await getPropertyVisitRequestAPI(pageNumber)
        let list=[]
        data.data.list.forEach(element => {
            if(element.isFinalized){
                list.push(element)
            }
            setRequestsState(list)
        });
    }
  return (
    <div className='flexStyle'>
    <TitleHeader text={"لیست املاک رزو شده برای بازدید"}/>
    

    <PropertyVisitListTabel data={requestsState} />
</div>
  )
}

export default PropertyVisitListContianer