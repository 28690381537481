import React from 'react'
import AddPropertyFeaturesContainer from '../../../Container/AddPropertyFeaturesContainer/AddPropertyFeaturesContainer'
import Layout from '../Layout'

const AddPropertyFeatures = () => {
  return (
    <Layout><AddPropertyFeaturesContainer/></Layout>
  )
}

export default AddPropertyFeatures