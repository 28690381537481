import React from 'react'
import AddForeignPropertyTypeContainer from '../../../Container/AddForeignPropertyTypeContainer/AddForeignPropertyTypeContainer'
import Layout from '../Layout'

const AddForeignPropertyType = () => {
  return (
    <Layout><AddForeignPropertyTypeContainer/></Layout>
  )
}

export default AddForeignPropertyType