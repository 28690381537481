import React from 'react'
import AddPropertyTypeContainer from '../../../Container/AddPropertyTypeContainer/AddPropertyTypeContainer'
import Layout from '../Layout'

const AddPropertyType = () => {
  return (
    <Layout><AddPropertyTypeContainer/></Layout>
  )
}

export default AddPropertyType