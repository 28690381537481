import React from 'react'
import UnitListContainer from '../../../Container/UnitListContainer/UnitListContainer'
import Layout from '../Layout'

const UnitList = () => {
  return (
    <Layout><UnitListContainer/></Layout>
  )
}

export default UnitList