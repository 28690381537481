import React from 'react'
import ReadedTiketsListContainer from '../../../Container/TiketListContainer/ReadedTiketsListContainer'
import Layout from '../Layout'

const ReadedTiketsList = () => {
  return (
    <Layout>
        <ReadedTiketsListContainer/>
    </Layout>
  )
}

export default ReadedTiketsList