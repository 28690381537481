import React,  { useEffect, useState }  from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getPplRegistredTourAPI, getToursListAPI } from '../../API/BarangizanServices'
import PplRegistredTourDetails from '../../Components/Details/PplRegistredTourDetails/PplRegistredTourDetails'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import PplRegistredTourTabel from '../../Components/Tabels/PplRegistredTourTabel/PplRegistredTourTabel'
import { errorToast, succToast } from '../../Utils/Toast/Toast'

const PplRegistredTourContainer = () => {
    const[loadingState,setLoadingState]=useState(false)
    const [show, setShow] = useState(false);
    const[tourData,setTourData]=useState()
    const handleShow = () =>setShow(false)  
    const[UserInfo,setUserInfo]=useState()
    useEffect(()=>{
        getTourListHandler(1)
    },[])
    const getTourListHandler=async(pageNumber)=>{
        const data=await getToursListAPI(pageNumber)
        setTourData(data.data.list)

    }
    const[PplList,setPplList]=useState()
    const handelModal=(id,value)=>{
        setUserInfo(value)
        if(id===1){
          setShow(true)
        }
      }
      const getUserTabelListHandler=async(value)=>{
        const data=await getPplRegistredTourAPI(value)
        setPplList(data.data.registrations)
      }
      
  return (
    <div className='flexStyle'>
        <LoadingPage show={loadingState}/>
        <select name="tourList" onChange={e=>getUserTabelListHandler(e.target.value)} defaultValue={0} className='text w-50'>
            <option value="0" disabled>ابتدا یک تور را انتخاب کنید...</option>
            {
                tourData&&tourData.map(item=>{
                    return(
                        <option key={item.id} value={item.id} >{item.title}</option>
                    )
                })
            }
        </select>

        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>جزئیات</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <PplRegistredTourDetails info={UserInfo}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
            {PplList&& <PplRegistredTourTabel data={PplList}  showModal={(e,value)=>handelModal(e,value)}/>}
    </div>
  )
}

export default PplRegistredTourContainer