import React, { useEffect, useState } from 'react'
import {Spinner} from 'react-bootstrap'
import { getCurrencyHistoryListAPI } from '../../../API/BarangizanServices'
import { separate } from '../../../Utils/Seprate/SeprateThreeNumber'
import Style from './CurrencyHistoryDetails.module.scss'
const CurrencyHistoryDetails = ({id}) => {
    const[currencyHistoryState,setCurrencyHistoryState]=useState(null)
    useEffect(()=>{
        getCurrencyHisoryHandler()
    },[])
    const getCurrencyHisoryHandler=async()=>{
        const data=await getCurrencyHistoryListAPI(id.id)
        setCurrencyHistoryState(data.data.list)
    }
  return (
    <div className='pageHolder'>
        {
            currencyHistoryState===null?<Spinner animation='border' variant='warning'/>:
            <ul className={Style.ListHolder}>
                {currencyHistoryState.map(item=>{
                    return(
                        <li className={Style.CurrencyHistoryPart}>
                            <div className={Style.DivisonPart}>
                                <p className={Style.titlePart}>تاریخ شروع</p>
                                <p>{item.startDate}</p>
                            </div>
                            <div className={Style.DivisonPart}>
                            <p className={Style.titlePart}>تاریخ پایان</p>
                                <p>{item.endDate}</p>
                            </div>
                            <div className={Style.DivisonPart}>
                            <p className={Style.titlePart}> قیمت</p>
                                <p>{separate(item.rateToToman)} تومان</p>   
                            </div>
                        </li>
                    )
                })
                }
            </ul>
        }
    </div>
  )
}

export default CurrencyHistoryDetails