import { SearchOutlined } from "@ant-design/icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Space, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import Style from "./ContactUsListTabel.module.scss";
class ContactUsListTabel extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sortedInfo: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {

    let dataSource=[]
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSource.push({
          key:index,
          name: element.name,
          subject: element.subject,
          text: element.text,
        });
        index = index + 1;
      });
    const columns = [
      {
        title: "نام",
        dataIndex: "name",
        key: "name",

        ...this.getColumnSearchProps("name"),
      },
      {
        title: "موضوع",
        dataIndex: "subject",
        key: "subject",

        ...this.getColumnSearchProps("subject"),
      },
     
      {
        title: "عملیات",
        key: "action",
        width: "14%",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className=" border-0"
              onClick={() => this.props.showModal(1, record)}
            >نمایش متن پیام</Button>
          </Space>
        ),
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          loading={this.props.data === undefined ? true : false}
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    );
  }
}

export default ContactUsListTabel;
