import React from 'react'
import ForeignPropertyListContainer from '../../../Container/ForeignPropertyListContainer/ForeignPropertyListContainer'
import Layout from '../Layout'

const ForeignPropertyList = () => {
  return (
    <Layout><ForeignPropertyListContainer/></Layout>
  )
}

export default ForeignPropertyList