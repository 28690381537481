import React from 'react'
import AddUserContainer from '../../../Container/AddUserContainer/AddUserContainer'
import Layout from '../Layout'

const AddUser = () => {
  return (
    <Layout><AddUserContainer/></Layout>
  )
}

export default AddUser