import React from 'react'
import PropertyRegisterRequestContainer from '../../../Container/PropertyRegisterRequestContainer/PropertyRegisterRequestContainer'
import Layout from '../Layout'

const PropertyRegisterRequest = () => {
  return (
    <Layout><PropertyRegisterRequestContainer/></Layout>
  )
}

export default PropertyRegisterRequest