import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { EditPageAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Style from './EditPageForm.module.scss'
import { Button, Spinner } from "react-bootstrap";
import Editor from '../../Editor/Editor';
const EditPageForm = ({info}) => {
    const [loadingState, setLoadingState] = useState(false);
    const [description, setDescription] = useState("");
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm();
    const onSubmit = (data) => {
        setLoadingState(true)
      let formData=new FormData()
      formData.append('Title',data.title)
      formData.append('Link',data.link)
      formData.append('Text',description===""?info.text:description)
      EditPageAPI(formData,(succ,res)=>{
          if(succ){
              succToast(res.data.message)
              reset()
              setLoadingState(false)
          }
          else{
              errorToast(res.data.errors[0])
              setLoadingState(false)
          }
      })
    };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
    <ul className={Style.InputHolder}>
      <li>
        <label htmlFor="title">نام صفحه </label>
        <input
          {...register("title", { required: true })}
          className={errors.adminUsername ? "valid" : "text"}
          autoComplete="title"
          type="text"
          name="title"
          defaultValue={info.title}
        />
      </li>
      <li>
        <label htmlFor="link"> لینک صفحه</label>
        <input
          {...register("link", { required: true })}
          className={errors.password ? "valid" : "text"}
          autoComplete="link"
          type="text"
          name="link"
          style={{textAlign:'left',direction:'ltr'}}
          defaultValue={info.link}
        />
      </li>
    </ul>
    <div className={Style.DescHolder}>
        <label htmlFor="description">محتوا صفحه</label>
        <Editor
          desc={description}
          setdescription={setDescription}
          defaultValue={info.text}
        />
      </div>
    <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
      {loadingState ? (
        <small>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          /> 
          لطفا صبرکنید
        </small>
      ) : (
        "ثبت"
      )}
    </Button>
  </form>
  )
}

export default EditPageForm