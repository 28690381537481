import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AddTourAPI, getCountryListAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import DatePickerInRange from '../../DatePickerInRange/DatePickerInRange';
import Editor from '../../Editor/Editor';
import Select from "react-select";
import Style from './AddTourForm.module.scss'
const AddTourForm = () => {
    const [loadingState, setLoadingState] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [description, setDescription] = useState('');
    const [ForeignTourState, setForeignTourState] = useState(false);
    const [countryList, setCountryList] = useState(null);
    const [countrySelect, setCountrySelect] = useState(null);
    useEffect(()=>{
      getDataHandler()
    },[])
    const getDataHandler=async()=>{
      const countries = await getCountryListAPI();
      setCountryList(countries.data.list);
    }
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [ImageUrl, setImageUrl] = useState();
  const [ImageFile, setImageFile] = useState();
  const imageChange = (evt) => {
    setImageFile(evt.target.files[0]);
    setImageUrl(URL.createObjectURL(evt.target.files[0]));
  };
  const onSubmit = (data) => {
    if(selectedDay===null) return errorToast('زمان شروع تور را مشخص کنید')
    if(description==="") return errorToast('توضیحات تور را وارد کنید')
    setLoadingState(true)
      let formData=new FormData()
      formData.append('Title',data.Title)
      formData.append('IsKhareji',ForeignTourState)
      formData.append('CountryId',countrySelect.value)
      formData.append('startDate',`${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`)
      formData.append('Description',description)
      formData.append('MustRegisterBeforeThisDate',data.MustRegisterBeforeThisDate)
      formData.append('ParticipantsCount',data.ParticipantsCount)
      formData.append('Price',data.Price)
      formData.append('PricePerPerson',data.PricePerPerson)
      formData.append('Image',ImageFile)
      formData.append('CertainAge',data.CertainAge)
      formData.append('PricePerPersonUnderCertainAge',data.PricePerPersonUnderCertainAge)
      AddTourAPI(formData,(succ,res)=>{
          if(succ){
              succToast(res.data.message)
              reset()
              setLoadingState(false)
          }
          else{
              errorToast(res.data.errors[0])
              setLoadingState(false)
          }
      })

     
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <h3>افزودن تور</h3>
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="Title">نام تور</label>
          <input
            {...register("Title", { required: true })}
            className={errors.Title ? "valid" : "text"}
            autoComplete="Title"
            type="mail"
            name="Title"
            
          />
        </li>
        <li>
            <label htmlFor="startDate"> تاریخ شروع</label>
            <DatePickerInRange id='startDate' selectedDayRange={selectedDay} setSelectedDayRange={setSelectedDay}/>
        </li>
        <li>
          <label htmlFor="MustRegisterBeforeThisDate">مقدار روز  تا بسته شدن ثبت نام</label>
          <input
            {...register("MustRegisterBeforeThisDate", { required: true })}
            className={errors.MustRegisterBeforeThisDate ? "valid" : "text"}
            autoComplete="MustRegisterBeforeThisDate"
            type="number"
            name="MustRegisterBeforeThisDate"
          />
        </li>
        <li>
          <label htmlFor="ParticipantsCount"> ظرفیت</label>
          <input
            {...register("ParticipantsCount", { required: true })}
            className={errors.ParticipantsCount ? "valid" : "text"}
            autoComplete="ParticipantsCount"
            type="number"
            name="ParticipantsCount"
          />
        </li>
        <li>
          <label htmlFor="Price"> هزینه ثبت نام</label>
          <input
            {...register("Price", { required: true })}
            className={errors.Price ? "valid" : "text"}
            autoComplete="Price"
            type="number"
            name="Price"
            placeholder='به تومان'
          />
        </li>
        <li>
          <label htmlFor="PricePerPerson">هزینه به ازای هر شخص اضافه</label>
          <input
            {...register("PricePerPerson", { required: true })}
            className={errors.PricePerPerson ? "valid" : "text"}
            autoComplete="PricePerPerson"
            type="number"
            name="PricePerPerson"
            placeholder='به تومان'
          />
        </li>
        <li>
          <label htmlFor="CertainAge">سن معین شده</label>
          <input
            {...register("CertainAge", { required: true })}
            className={errors.CertainAge ? "valid" : "text"}
            autoComplete="CertainAge"
            type="number"
            name="CertainAge"
          />
        </li>
        <li>
          <label htmlFor="PricePerPersonUnderCertainAge">  هزینه شخص زیر سن معین</label>
          <input
            {...register("PricePerPersonUnderCertainAge", { required: true })}
            className={errors.PricePerPersonUnderCertainAge ? "valid" : "text"}
            autoComplete="PricePerPersonUnderCertainAge"
            type="number"
            name="PricePerPersonUnderCertainAge"
          />
        </li>
        <li className={Style.CheckBoxHolder}>
          <label htmlFor="Rent"> تور خارجی   </label>
          <input
            style={{ accentColor: "blue" }}
            type={"checkbox"}
            id={"Rent"}
            onChange={(e) => setForeignTourState(e.target.checked)}
          />
        </li>
        {ForeignTourState?<li>
          <label htmlFor="type">  تور کشور  </label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={setCountrySelect}
              options={
                countryList?.map(item=>{
                  return({ value: item.id, label: item.title })
                })
              }
              name="type"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isMulti
            />
          </div>
        </li>:null
        }
      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="description">توضیحات ملک</label>
        <Editor
            desc={description}
            setdescription={setDescription}
            defaultValue=""
        />
      </div>
      <div className={Style.ImagePart}>
        <label htmlFor="imageFile" className={Style.ImageLabel}>
          انتخاب تصویر
          <input
            type="file"
            name="imageFile"
            id="imageFile"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => imageChange(e)}
          />
        </label>
        {ImageUrl && (
          <img src={ImageUrl} className={Style.ImagePerview} alt="" />
        )}
      </div>

      <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
        {loadingState ? (
          <small>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> 
            لطفا صبرکنید
          </small>
        ) : (
          "ثبت"
        )}
      </Button>
    </form>
  )
}

export default AddTourForm