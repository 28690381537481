import React, { useEffect, useState } from 'react'
import { DeletePageAPI, getPageListAPI } from '../../API/BarangizanServices';
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
import { errorToast, succToast } from '../../Utils/Toast/Toast';
import { Button, Modal } from 'react-bootstrap'
import PageListTabel from '../../Components/Tabels/PageListTabel/PageListTabel';
import EditPageForm from '../../Components/Forms/EditPageForm/EditPageForm';

const PagesListContainer = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[pageListState,setPageListState]=useState()
    const handleShow = () =>{
        setShow(false)
        setShow2(false)
      };
      const[pageInfo,setPageInfo]=useState()
      const[loadingState,setLoadingState]=useState()

      useEffect(()=>{
        getPageListHandler()
      },[])
      const getPageListHandler=async()=>{
          const data=await getPageListAPI()
          setPageListState(data.data.list)
      }
      const handelModal=(id,value)=>{
        setPageInfo(value)
        if(id===1){
          setShow(true)
        }
        if(id===2){
          setShow2(true)
        }

      }
      const deletePageHandler=()=>{
        setLoadingState(true)
        DeletePageAPI(pageInfo.id,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                getPageListHandler()
                handleShow()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
      }
  return (
    <div className='pageHolder'>
        <LoadingPage show={loadingState}/>
        <TitleHeader text={"لیست  صفحه ها"}/>
        
        <Modal show={show} className={'RTL'} >
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>ویرایش</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              {/* <EditAdminPasswordForm info={AdminInfo}/> */}
              <EditPageForm info={pageInfo}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>

          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deletePageHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>
        {/* <AdminListTabel data={adminListState} showModal={(e,value)=>handelModal(e,value)}/> */}
        <PageListTabel data={pageListState} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default PagesListContainer