import { Alert } from 'antd';
import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AddAffidavitAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Editor from '../../Editor/Editor';
const AddAffidavitForm = () => {
    const[loadingState,setLoadingState]=useState(false)
    const [description, setDescription] = useState("");
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
      } = useForm();
      const onSubmit = (data) => {
        setLoadingState(true)
        let info={
            title:data.title,
            text:description
        }
        AddAffidavitAPI(info,(succ,res)=>{
            if(succ){
                setLoadingState(false)
                succToast('با موفقیت ثبت شد!')
            }else{
                setLoadingState(false)
                errorToast(res.data.errors[0])
            }
        })
      }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'w-100 p-3'}>
        <div className={'mb-3'}>
        <label htmlFor="title">نام قولنامه</label>
        <input
          {...register("title", { required: true })}
          className={errors.adminUsername ? "valid" : "text"}
          autoComplete="title"
          type="text"
          name="title"
        />
        </div>
        <div className={'w-100'}>
        <Editor
          desc={description}
          setdescription={setDescription}
          defaultValue={""}
        />
        </div>
        <Alert
        type="warning"
        message=" برای تعیین متغیر ها در ابتدا و انتها کلمه @ قرار دهید!"
        showIcon
        className="w-100"
      /> 
        <Button className="w-100 my-3" type="submit" disabled={loadingState?true:false}>

      {loadingState ? (
        <small>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          /> 
          لطفا صبرکنید
        </small>
      ) : (
        "ثبت"
      )}
    </Button>
    </form>
  )
}

export default AddAffidavitForm