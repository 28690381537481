import React from 'react'
import LayoutContainer from '../../Container/LayoutContainer/LayoutContainer'


const Layout = ({children}) => {
  return (
    <section className='pageHolder'>
      <LayoutContainer children={children}/>
    </section>
  )
}

export default Layout