import React from 'react'
import PplRegistredTourContainer from '../../../Container/PplRegistredTourContainer/PplRegistredTourContainer'
import Layout from '../Layout'

const PplRegistredTour = () => {
  return (
    <Layout><PplRegistredTourContainer/></Layout>
  )
}

export default PplRegistredTour