import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { EditCurrencyRateAPI, EditUserAPI, getCurrencyDetailAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Style from './EditCurrencyRateForm.module.scss'

const EditCurrencyRateForm = ({id,getUpdate}) => {
    const [loadingState, setLoadingState] = useState(false);

    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm();
    const onSubmit = (data) => {
        setLoadingState(true)
        let formData=new FormData()
        formData.append('Id',id.id)
        formData.append('RateToToman',parseFloat(data.Rate))

            EditCurrencyRateAPI(formData,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    reset()
                    setLoadingState(false)
                    getUpdate()
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        
        
    };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <ul className={Style.InputHolder}>
        <li>
            <label htmlFor="CurrencyName">نام ارز</label>
            <input
            {...register("CurrencyName", { required: false })}
            className={errors.CurrencyName ? "valid" : "text"}
            name="CurrencyName"
            defaultValue={id.currency}
            disabled
            />
        </li>
        
        <li>
            <label htmlFor="Country">کشور</label>
            <input
            {...register("Country", { required: false })}
            className={errors.Country ? "valid" : "text"}
            name="Country"
            defaultValue={id.title}
            disabled
            />
        </li>
        
        <li>
            <label htmlFor="Rate">ارزش به تومان</label>
            <input
            {...register("Rate", { required: false })}
            className={errors.Rate ? "valid" : "text"}
            name="Rate"
            type={'number'}
            defaultValue={id.rateToToman}
            />
        </li>

        </ul>
        <Button className="w-50 my-3" type="submit" disabled={loadingState?true:false}>
        {loadingState ? (
            <small>
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            /> 
            لطفا صبرکنید
            </small>
        ) : (
            "ثبت"
        )}
        </Button>
    </form>
  )
}

export default EditCurrencyRateForm