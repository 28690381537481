import React from 'react'
import Style from './TitleHeader.module.scss'
const TitleHeader = ({text}) => {
  return (
    <section className='w-100'>
        <h5 className={Style.TextHolder}>{text}</h5>
        <div className={Style.hr}></div>
    </section>
    
  )
}

export default TitleHeader