import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  EditForeignPropertyProjectAPI,
  getCountryListAPI,
  getForeignCitylistAPI,
  getForeignCitylistAPI2,
  getForeignProjectDetailAPI,
} from "../../../API/BarangizanServices";
import Editor from "../../Editor/Editor";
import LoadingPage from "../../LoadingPage/LoadingPage";
import Select from "react-select";
import Style from "./EditForeignPropertyProjectForm.module.scss";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";

const EditForeignPropertyProjectForm = ({ info, closeModal, getUpdate }) => {
  const [loadingState, setLoadingState] = useState(false);
  const [description, setDescription] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCitiestList] = useState();
  const [citiesList2, setCitiestList2] = useState();
  const [coutrySelect, setCountrySelect] = useState();
  const [citySelect, setCitySelect] = useState();
  const [floorNumber, setFloorNumber] = useState(0);
  const [projectDetail, setProjectDetail] = useState();
  const [ProjectBlocks, setProjectBlocks] = useState();

  const [loadingType, setLoadingType] = useState(true);

  useEffect(() => {
    getDataHandler();
  }, []);

  const getDataHandler = async () => {
    const detail = await getForeignProjectDetailAPI(info.code);
    setProjectDetail(detail.data.project);
    setProjectBlocks(detail.data.blocks);
    const data = await getCountryListAPI();
    const cities = await getForeignCitylistAPI2(detail.data.project.country);
    setCitiestList(cities.data.list);
    setCountryList(data.data.list);
    setLoadingType(false);
  };
  const getCityListHandler = async (info) => {
    setCountrySelect(info);
    const cities = await getForeignCitylistAPI(info.value);
    setCitiestList2(cities.data.list);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();
  const runCallback = (cb) => {
    return cb();
  };

  const defaultCountry =
    projectDetail &&
    countryList &&
    countryList.find((element) => element.title === projectDetail.country);
  const defaultCity =
    projectDetail &&
    citiesList &&
    citiesList.find((element) => element.title === projectDetail.city);
  
  const onSubmit = (data) => {
    setLoadingState(true);
    let formData = new FormData();
    formData.append("Code", projectDetail.code);
    formData.append("Title", data.title);
    formData.append(
      "Country",
      coutrySelect === undefined ? defaultCountry.title : coutrySelect.label
    );
    formData.append(
      "CountryId",
      coutrySelect === undefined ? defaultCountry.id : coutrySelect.value
    );
    formData.append(
      "City",
      citySelect === undefined ? defaultCity.title : citySelect.label
    );
    formData.append(
      "Description",
      description === "" ? projectDetail.description : description
    );
    formData.append("Address", data.Address);
    formData.append("ForeignEstateSellingType", data.SalesCondition);
    formData.append("ExclusiveVisitPrice", data.ExclusiveVisitPrice);
    formData.append("VirtualVisitPrice", data.VirtualVisitPrice);

    for (let j = 0; j < data.block; j++) {
      formData.append(`Blocks[${j}].Id`, data.Blocks[j].Id===''?0:data.Blocks[j].Id);
      formData.append(`Blocks[${j}].Title`, data.Blocks[j].Title);
      formData.append(`Blocks[${j}].FloorCount`, data.Blocks[j].FloorCount);
    }
    EditForeignPropertyProjectAPI(formData, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        setLoadingState(false);
        reset();
        closeModal();
        getUpdate();
      } else {
        errorToast(res.data.errors[0]);
        setLoadingState(false);
      }
    });
  };
  console.log(ProjectBlocks&&ProjectBlocks[0].id)
  return (
    <>
      {projectDetail === undefined ||
      ProjectBlocks === undefined ||
      citiesList === undefined ? (
        <Spinner animation="border" variant="warning" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
          <ul className={Style.InputHolder}>
            <li>
              <label htmlFor="title">عنوان پروژه</label>
              <input
                {...register("title", { required: false })}
                className={errors.title ? "valid" : "text"}
                autoComplete="title"
                type="text"
                name="title"
                defaultValue={projectDetail.title}
              />
            </li>
            <li>
              <label htmlFor="SalesCondition">شرایط فروش</label>
              <select
                defaultValue={projectDetail.foreignEstateSellingType}
                className={"text"}
                name=""
                id=""
                {...register("SalesCondition", { required: false })}
              >
                <option value={0}>نقد</option>
                <option value={1}>اقساط</option>
                <option value={2}>نقد،اقساط</option>
              </select>
            </li>
            <li>
              <label htmlFor="country">کشور</label>
              <div className={Style.selectOption}>
                {defaultCountry && (
                  <Select
                    {...register("country", { required: false })}
                    onChange={getCityListHandler}
                    options={countryList.map((item) => {
                      return { value: item.id, label: item.title };
                    })}
                    name="country"
                    placeholder="انتخاب کنید"
                    className={Style.selectOptionsearch}
                    isLoading={loadingType}
                    defaultValue={{
                      label: defaultCountry.title,
                      value: defaultCountry.id,
                    }}
                    isDisabled
                  />
                )}
              </div>
            </li>
            <li>
              <label htmlFor="city">شهر</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("city", { required: false })}
                  onChange={setCitySelect}
                  options={
                    citiesList2 === undefined
                      ? citiesList.map((item) => {
                          return { value: item.id, label: item.title };
                        })
                      : citiesList2.map((item) => {
                          return { value: item.id, label: item.title };
                        })
                  }
                  name="city"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  isLoading={loadingType}
                  defaultValue={{
                    label: defaultCity.title,
                    value: defaultCity.id,
                  }}
                  
                />
              </div>
            </li>
            
            <li>
              <label htmlFor="ExclusiveVisitPrice">قیمت بازدید اختصاصی </label>
              <input
                {...register("ExclusiveVisitPrice", { required: true })}
                className={errors.ExclusiveVisitPrice ? "valid" : "text"}
                autoComplete="ExclusiveVisitPrice"
                type="number"
                name="ExclusiveVisitPrice"
                defaultValue={projectDetail.exclusiveVisitPrice}
              />
            </li>
            <li>
              <label htmlFor="VirtualVisitPrice">قیمت بازدید مجازی </label>
              <input
                {...register("VirtualVisitPrice", { required: true })}
                className={errors.VirtualVisitPrice ? "valid" : "text"}
                autoComplete="VirtualVisitPrice"
                type="number"
                name="VirtualVisitPrice"
                defaultValue={projectDetail.virtualVisitPrice}
              />
            </li>
            <li>
              <label htmlFor="block">تعداد بلوک</label>
              <input
                {...register("block", { required: false })}
                className={errors.block ? "valid" : "text"}
                autoComplete="block"
                type="number"
                min={0}
                name="block"
                onChange={(e) => setFloorNumber(e.target.value)}
                defaultValue={ProjectBlocks.length}
              />
            </li>
          </ul>
          <ul className={Style.InputHolder}>
            {runCallback(() => {
              const row = [];
              for (
                var i = 0;
                i <
                parseInt(
                  floorNumber === 0 ? ProjectBlocks.length : floorNumber
                );
                i++
              ) {
                row.push(
                  <li key={i} className={Style.EachBlock}>
                    <label htmlFor={`Blocks[${i}].Id`} style={{display:'none'}}>آیدی بلوک</label>
                    <input
                      {...register(`Blocks[${i}].Id`, { required: false })}
                      className={errors.Id ? "valid" : "text"}
                      autoComplete={`Blocks[${i}].Id`}
                      type="text"
                      name={`Blocks[${i}].Id`}
                      defaultValue={ProjectBlocks[i]&&ProjectBlocks[i].id}
                      style={{display:'none'}}
                    />
                    <label htmlFor={`Blocks[${i}].Title`}>نام بلوک</label>
                    <input
                      {...register(`Blocks[${i}].Title`, { required: false })}
                      className={errors.title ? "valid" : "text"}
                      autoComplete={`Blocks[${i}].Title`}
                      type="text"
                      name={`Blocks[${i}].Title`}
                      defaultValue={ProjectBlocks[i]&&ProjectBlocks[i].title}
                    />
                    <label htmlFor={`Blocks[${i}].FloorCount`}>
                      تعداد طبقه
                    </label>
                    <input
                      {...register(`Blocks[${i}].FloorCount`, {
                        required: false,
                      })}
                      className={errors.title ? "valid" : "text"}
                      autoComplete={`Blocks[${i}].FloorCount`}
                      type="number"
                      name={`Blocks[${i}].FloorCount`}
                      defaultValue={ProjectBlocks[i]&&ProjectBlocks[i].floorCount}
                    />
                  </li>
                );
              }
              return row;
            })}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="Address">آدرس ملک</label>
            <textarea
              {...register("Address", { required: true })}
              className={errors.Address ? "valid" : "text"}
              autoComplete="Address"
              type="text"
              name="Address"
              defaultValue={projectDetail.address}
            />
          </div>

          <div className={Style.DescHolder}>
            <label htmlFor="description">توضیحات پروژه</label>
            <Editor
              desc={description}
              setdescription={setDescription}
              defaultValue={projectDetail.description}
            />
          </div>

          <Button
            className="w-50 my-3"
            type="submit"
            disabled={loadingState ? true : false}
          >
            {loadingState ? (
              <small>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                  لطفا صبرکنید
              </small>
            ) : (
              "ثبت"
            )}
          </Button>
        </form>
      )}
    </>
  );
};

export default EditForeignPropertyProjectForm;
