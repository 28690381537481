import React, { useEffect } from 'react';
import './App.css';
import './DatePicker.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AddAdmin, Addaffidavit, AddCityToCountry, AddCountry, AddForeignProperty, AddForeignPropertyProject, AddForeignPropertyType, AddMagazine, AddPage, AddProperty, AddPropertyType, AddTiket, AddTour, AddUnitToProject, AddUser, AdminList, AffidavitList, CitiesOfCountries, ContactUsRequest, CooperationRequest, CountryList, CurrencyList, ForeignPropertyList, ForeignPropertyProjectList, ForeignPropertyRegisterRequest, ForeignPropertyTypeList, Login, MagazineList, MagazinePropertyRegister, NewTiketList, PageList, PPlRegsitredTour, PropertyFeaturesList, Propertylist, PropertyRegisterRequest, PropertyReservationList, PropertyReservationRequests, PropertyTypeList, PropertyVisitList, PropertyVisitRequest, ReadedTiketsList, TahatorRequests, TiketList, TourList, UnitList, UserList } from './Pages';
import AddPropertyFeatures from './Pages/Layout/PropertyFeatures/AddPropertyFeatures';
import { checkTokenAPI } from './API/BarangizanServices';

function App() {

  useEffect(()=>{
    checkTokenHandler()
  },[])
  const checkTokenHandler=()=>{
    checkTokenAPI((succ,res)=>{
      if(!succ){
        localStorage.removeItem('AdminBarangizanToken')
      }
    }) 
  }
  
  const isLogin =()=> !!localStorage.getItem("AdminBarangizanToken");

  const PrivateRoute = () => {
    return isLogin() ? <Outlet /> : <Navigate to="/login" />;
  };
  return (
  <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
            <Route exact path="/" element={<AddProperty />} />
            <Route exact path="/addproperty" element={<AddProperty />} />
            <Route exact path="/propertylist" element={<Propertylist />} />
            <Route exact path="/addadmin" element={<AddAdmin />} />
            <Route exact path="/adminlist" element={<AdminList />} />
            <Route exact path="/userlist" element={<UserList />} />
            <Route exact path="/addtour" element={<AddTour />} />
            <Route exact path="/tourlist" element={<TourList />} />
            <Route exact path="/tourppl" element={<PPlRegsitredTour />} />
            <Route exact path="/addmagazine" element={<AddMagazine />} />
            <Route exact path="/magazinelist" element={<MagazineList />} />
            <Route exact path="/addpropertyfeatures" element={<AddPropertyFeatures />} />
            <Route exact path="/propertyfeaturelist" element={<PropertyFeaturesList />} />
            <Route exact path="/propertyreservationrequests" element={<PropertyReservationRequests />} />
            <Route exact path="/propertyreservationlist" element={<PropertyReservationList />} />
            <Route exact path="/propertyvisitrequest" element={<PropertyVisitRequest />} />
            <Route exact path="/propertyvisitlist" element={<PropertyVisitList />} />
            <Route exact path="/newtiketlist" element={<NewTiketList/>} />
            <Route exact path="/readedtiketlist" element={<ReadedTiketsList/>} />
            <Route exact path="/addtiket" element={<AddTiket/>} />
            <Route exact path="/adduser" element={<AddUser/>} />
            <Route exact path="/propertyregisterrequest" element={<PropertyRegisterRequest/>} />
            <Route exact path="/foreignpropertyregisterrequest" element={<ForeignPropertyRegisterRequest/>} />
            <Route exact path="/foreignpropertyprojectlist" element={<ForeignPropertyProjectList/>} />
            <Route exact path="/cooperationrequest" element={<CooperationRequest/>} />
            <Route exact path="/addpropertytype" element={<AddPropertyType/>} />
            <Route exact path="/propertytypelist" element={<PropertyTypeList/>} />
            <Route exact path="/addforeignproperty" element={<AddForeignProperty/>} />
            <Route exact path="/addforeignpropertyproject" element={<AddForeignPropertyProject/>} />
            <Route exact path="/addcountry" element={<AddCountry/>} />
            <Route exact path="/countrylist" element={<CountryList/>} />
            <Route exact path="/addforeignproprtytype" element={<AddForeignPropertyType/>} />
            <Route exact path="/foreignpropertytypelist" element={<ForeignPropertyTypeList/>} />
            <Route exact path="/foreignpropertylist" element={<ForeignPropertyList/>} />
            <Route exact path="/addcitytocountry" element={<AddCityToCountry/>} />
            <Route exact path="/citiesofcountries" element={<CitiesOfCountries/>} />
            <Route exact path="/addunittoProject" element={<AddUnitToProject/>} />
            <Route exact path="/unitlist" element={<UnitList/>} />
            <Route exact path="/currencylist" element={<CurrencyList/>} />
            <Route exact path="/contactusrequest" element={<ContactUsRequest/>} />
            <Route exact path="/addpage" element={<AddPage/>} />
            <Route exact path="/pagelist" element={<PageList/>} />
            <Route exact path="/tahatorrequest" element={<TahatorRequests/>} />
            <Route exact path="/magazinepropertyRegister" element={<MagazinePropertyRegister/>} />
            <Route exact path="/addaffidavit" element={<Addaffidavit/>} />
            <Route exact path="/affidavitlist" element={<AffidavitList/>} />
        </Route>

      </Routes>
      <ToastContainer limit={3} />
    </Router>
  );
}

export default App;
