import React from 'react'
import LoginContainer from '../../Container/LoginContainer/LoginContainer'

const Login = () => {
  return (
    <div className='pageHolder bg-light'>
      <LoginContainer/>
    </div>
  )
}

export default Login