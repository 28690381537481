import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { EditTourAPI, getTourDetailsAPI } from "../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import DatePickerInRange from "../../DatePickerInRange/DatePickerInRange";
import Editor from "../../Editor/Editor";
import LoadingPage from "../../LoadingPage/LoadingPage";
import Style from "./EditTourForm.module.scss";
const EditTourForm = ({ info,getUpdate }) => {
    


  const [TourDetail, setTourDetail] = useState();
  
  useEffect(() => {
    getTourDetailHandler();
  }, []);
  const getTourDetailHandler = async () => {
    const data = await getTourDetailsAPI(info.id);
    setTourDetail(data.data);
    setSelectedDay({
        year:parseInt(data.data.startDate.split('/')[0]),
        month:parseInt(data.data.startDate.split('/')[1]),
        day:parseInt(data.data.startDate.split('/')[2]),
    })
  };

 
  const [loadingState, setLoadingState] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [description, setDescription] = useState('');
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
      setLoadingState(true)
    let formData=new FormData()
    formData.append('id',info.id)
    formData.append('Title',data.Title)
    formData.append('startDate',`${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`)
    formData.append('Description',description===''?TourDetail.description:description)
    formData.append('MustRegisterBeforeThisDate',data.MustRegisterBeforeThisDate)
    formData.append('ParticipantsCount',data.ParticipantsCount)
    formData.append('Price',data.Price)
    formData.append('PricePerPerson',data.PricePerPerson)
    formData.append('CertainAge',data.CertainAge)
    formData.append('PricePerPersonUnderCertainAge',data.PricePerPersonUnderCertainAge)
    EditTourAPI(formData,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            reset()
            setLoadingState(false)
            getUpdate()
        }
        else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
        }
    })
  };



  let loadingHandler = null;
  if (TourDetail === undefined) {
    loadingHandler = <LoadingPage show={true} />;
  } else {
    loadingHandler = (
      <>
        <ul className={Style.InputHolder}>
          <li>
            <label htmlFor="Title">نام تور</label>
            <input
              {...register("Title", { required: true })}
              className={errors.Title ? "valid" : "text"}
              autoComplete="Title"
              type="mail"
              name="Title"
              defaultValue={TourDetail.title}
            />
          </li>
          <li>
            <label htmlFor="startDate"> تاریخ شروع</label>
            <DatePickerInRange
              id="startDate"
              selectedDayRange={selectedDay}
              setSelectedDayRange={setSelectedDay}
            />
          </li>
          <li>
            <label htmlFor="MustRegisterBeforeThisDate">
              مقدار روز تا بسته شدن ثبت نام
            </label>
            <input
              {...register("MustRegisterBeforeThisDate", { required: true })}
              className={errors.MustRegisterBeforeThisDate ? "valid" : "text"}
              autoComplete="MustRegisterBeforeThisDate"
              type="number"
              name="MustRegisterBeforeThisDate"
              defaultValue={TourDetail.mustRegisterBeforeThisDate}
            />
          </li>
          <li>
            <label htmlFor="ParticipantsCount"> ظرفیت</label>
            <input
              {...register("ParticipantsCount", { required: true })}
              className={errors.ParticipantsCount ? "valid" : "text"}
              autoComplete="ParticipantsCount"
              type="number"
              name="ParticipantsCount"
              defaultValue={TourDetail.participantsCount}
            />
          </li>
          <li>
            <label htmlFor="Price"> هزینه ثبت نام</label>
            <input
              {...register("Price", { required: true })}
              className={errors.Price ? "valid" : "text"}
              autoComplete="Price"
              type="number"
              name="Price"
              placeholder="به تومان"
              defaultValue={TourDetail.price}
            />
          </li>
          <li>
            <label htmlFor="PricePerPerson">هزینه به ازای هر شخص اضافه</label>
            <input
              {...register("PricePerPerson", { required: true })}
              className={errors.PricePerPerson ? "valid" : "text"}
              autoComplete="PricePerPerson"
              type="number"
              name="PricePerPerson"
              placeholder="به تومان"
              defaultValue={TourDetail.pricePerPerson}
            />
          </li>
          <li>
            <label htmlFor="CertainAge">سن معین شده</label>
            <input
              {...register("CertainAge", { required: true })}
              className={errors.CertainAge ? "valid" : "text"}
              autoComplete="CertainAge"
              type="number"
              name="CertainAge"
              defaultValue={TourDetail.certainAge}
            />
          </li>
          <li>
            <label htmlFor="PricePerPersonUnderCertainAge">
              {" "}
              هزینه شخص زیر سن معین
            </label>
            <input
              {...register("PricePerPersonUnderCertainAge", { required: true })}
              className={
                errors.PricePerPersonUnderCertainAge ? "valid" : "text"
              }
              autoComplete="PricePerPersonUnderCertainAge"
              type="number"
              name="PricePerPersonUnderCertainAge"
              defaultValue={TourDetail.pricePerPersonUnderCertainAge}
            />
          </li>
        </ul>
        <div className={Style.DescHolder}>

          <Editor desc={description} setdescription={setDescription} defaultValue={TourDetail.description}/>
        </div>

        <Button
          className="w-50 my-3"
          type="submit"
          disabled={loadingState ? true : false}
        >
          {loadingState ? (
            <small>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
                لطفا صبرکنید
            </small>
          ) : (
            "ثبت"
          )}
        </Button>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      {/* <h3>افزودن تور</h3> */}
      {loadingHandler}
    </form>
  );
};

export default EditTourForm;
