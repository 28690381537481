import React, { useEffect, useState } from 'react'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
import {Modal,Button} from 'react-bootstrap'
import {  getContactUsListAPI } from '../../API/BarangizanServices';
import ContactUsListTabel from '../../Components/Tabels/ContactUsListTabel/ContactUsListTabel';
const ContactUsRequestContainer = () => {
    const [show, setShow] = useState(false);
    const[MessageInfo,setMessageInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const[MessageListState,setMessageListState]=useState(false)

    const handleShow = () =>{
        setShow(false)
      };
      useEffect(()=>{
        getAdminListHandler()
      },[])

      const getAdminListHandler=async()=>{
          const data=await getContactUsListAPI()
          setMessageListState(data.data.list)
      }
      const handelModal=(id,value)=>{
        setMessageInfo(value)
        if(id===1){
          setShow(true)
        }

      }
  return (
    <div className='pageHolder'>
    <LoadingPage show={loadingState}/>
    <TitleHeader text={"لیست درخواست ارتباط با ما"}/>
    
    <Modal show={show} className={'RTL'} >
        <Modal.Header >
          <Modal.Title style={{fontSize:'1rem'}}>ویرایش پسورد ادمین</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex justify-content-around w-100'>
          <p>{MessageInfo?.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow} variant={'danger'}>بستن</Button>
        </Modal.Footer>
    </Modal>
    <ContactUsListTabel data={MessageListState} showModal={(e,value)=>handelModal(e,value)}/>

</div>
  )
}

export default ContactUsRequestContainer