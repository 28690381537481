import React from "react";
import { useState } from "react";
import LoadingPage from "../../LoadingPage/LoadingPage";
import Style from "./RejectPropertyForm.module.scss";
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { RejectPropertyRequestAPI } from "../../../API/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";

const RejectPropertyForm = ({ProprtyInfo,getUpdate,closeModal}) => {
  const [loadingState, setLoadingState] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
      setLoadingState(true)
      let info={
          id:ProprtyInfo.id,
          RejectReason:data.RejectReason
      }
      RejectPropertyRequestAPI(info,(succ,res)=>{
          if(succ){
            succToast(res.data.message)
            setLoadingState(false)
              getUpdate()
              closeModal()
          }
          else{
              errorToast(res.data.errors[0])
              setLoadingState(false)
          }
      })
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
      <LoadingPage show={loadingState} />
      <div className={Style.InputHolder}>
        <textarea
        {...register("RejectReason", { required: true })}
        className={errors.username ? "valid" : "text"}
        placeholder={"دلیل رد ملک"}
        />
      </div>
      <Button variant="primary" type="submit" style={{ width: "8rem" }}>
        ثبت
      </Button>
    </form>
  );
};

export default RejectPropertyForm;
