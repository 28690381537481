import React from 'react'
import AddAdminContainer from '../../../Container/AddAdminContainer/AddAdminContainer'
import Layout from '../Layout'

const AddAdmin = () => {
  return (
    <Layout><AddAdminContainer/></Layout>
  )
}

export default AddAdmin