import React from 'react'
import AdminListContainer from '../../../Container/AdminListContainer/AdminListContainer'
import Layout from '../Layout'

const AdminList = () => {
  return (
    <Layout><AdminListContainer/></Layout>
  )
}

export default AdminList