import React from 'react'
import PropertyVisitRequestsContainer from '../../../Container/PropertyVisitRequestsContainer/PropertyVisitRequestsContainer'
import Layout from '../Layout'

const PropertyVisitRequests = () => {
  return (
    <Layout><PropertyVisitRequestsContainer/></Layout>
  )
}

export default PropertyVisitRequests