import React from 'react'
import AddForeignPropertyContainer from '../../../Container/AddForeignPropertyContainer/AddForeignPropertyContainer'
import Layout from '../Layout'

const AddForeignProperty = () => {
  return (
    <Layout><AddForeignPropertyContainer/></Layout>
  )
}

export default AddForeignProperty