import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import Style from "./CooperationRequestTabel.module.scss";
class CooperationRequestTabel extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sortedInfo: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {

    let dataSource=[]
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSource.push({
          key:index,
          id:element.id,
          userName: `${element.name}`,
          phoneNumber: element.phoneNumber,
          userType: element.cooperationRequestType===0?"حقیفی":"حقوقی",
        });
        index = index + 1;
      });
    const columns = [
      {
        title: "ردیف",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "نام شخص",
        dataIndex: "userName",
        key: "userName",

        ...this.getColumnSearchProps("userName"),
      },
      {
        title: "تلفن همراه",
        dataIndex: "phoneNumber",
        key: "phoneNumber",

        ...this.getColumnSearchProps("phoneNumber"),
      },

      {
        title: "نوع شخص",
        dataIndex: "userType",
        key: "userType",

        ...this.getColumnSearchProps("userType"),
      },

     
      {
        title: "عملیات",
        key: "action",
        width: "14%",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className="bg-warning border-0"
              onClick={() => this.props.showModal(1, record)}
            >
                جزئیات
            </Button>
          </Space>
        ),
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          loading={this.props.data === undefined ? true : false}
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    );
  }
}

export default CooperationRequestTabel;
