import React from 'react'
import AddPropertyFeaturesForm from '../../Components/Forms/AddPropertyFeaturesForm/AddPropertyFeaturesForm'

const AddPropertyFeaturesContainer = () => {
  return (
    <div className='flexStyle'>
        <AddPropertyFeaturesForm/>
    </div>
  )
}

export default AddPropertyFeaturesContainer