import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import Style from "./TiketListTabel.module.scss";
class TiketListTabel extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sortedInfo: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let dataSoruce = [];
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSoruce.push({
          key:index,
          id:element.id,
          ticketStatus:element.ticketStatus,
          subject:element.subject,
          lastMessage:element.lastMessage,
          userName:element.userName,
          ticketPriorities:element.ticketPriorities,
        });
        index = index + 1;
      });

    const columns = [
      {
        title: "شماره",
        dataIndex: "key",
        key: "key",
      },{
        title: "موضوع تیکت",
        dataIndex: "subject",
        key: "subject",

        ...this.getColumnSearchProps("subject"),
      },
      {
        title: "کاربر",
        dataIndex: "userName",
        key: "userName",

        ...this.getColumnSearchProps("userName"),
      },
      {
        title: "تاریخ آخرین پیغام",
        dataIndex: "lastMessage",
        key: "lastMessage",

        ...this.getColumnSearchProps("lastMessage"),
      },
      {
        title: "اهمیت",
        dataIndex: "ticketPriorities",
        key: "ticketPriorities",

        ...this.getColumnSearchProps("ticketPriorities"),
      },
      
      {
        title: "عملیات",
        key: "action",
        width: "14%",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className="bg-warning border-0"
                onClick={() => this.props.showModal(1, record)}
            >پاسخ  </Button>
            {
                record.ticketStatus===3?<p className="pt-2">بسته شد</p>:            <Button
                type="primary"
                danger
                  onClick={() => this.props.showModal(2, record)}
                  
              >بستن تیکت </Button>
            }
          </Space>
        ),
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          loading={this.props.data === undefined ? true : false}
          columns={columns}
          dataSource={dataSoruce}
          pagination={{position:['bottomCenter'],total:this.props.totalPages,pageSize:30}}
          
        />
      </div>
    );
  }
}

export default TiketListTabel;
