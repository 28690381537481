import React from 'react'
import ForeignPropertyProjectListContainer from '../../../Container/ForeignPropertyProjectListContainer/ForeignPropertyProjectListContainer'
import Layout from '../Layout'

const ForeignPropertyProjectList = () => {
  return (
    <Layout><ForeignPropertyProjectListContainer/></Layout>
  )
}

export default ForeignPropertyProjectList