import React, { useState } from 'react'
import Style from './EditCountryForm.module.scss'
import { useForm } from "react-hook-form";
import { Button, Spinner } from "react-bootstrap";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { EditeCountryAPI } from '../../../API/BarangizanServices';
const EditCountryForm = ({info,getUpdate,closeModal}) => {
    
    const [loadingState, setLoadingState] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
      } = useForm();
      const onSubmit = (data) => {
          setLoadingState(true)
          let formData=new FormData()
          formData.append('id',info.id)
          formData.append('Title',data.title)
          formData.append('EnglishTitle',data.EnglishTitle)
          formData.append('Currency',data.Currency)
          formData.append('Initials',data.Initials)
          EditeCountryAPI(formData,(succ,res)=>{
              if(succ){
                  setLoadingState(false)
                  succToast(res.data.message)
                  getUpdate()
                  closeModal()
              }
              else{
                  errorToast(res.data.errors[0])
                  setLoadingState(false)
              }
          })
      };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
    <LoadingPage show={loadingState} />
    
    <ul className={Style.InputHolder}>
      <li>
        <label htmlFor="title">عنوان کشور</label>
        <input
          {...register("title", { required: false })}
          className={errors.title ? "valid" : "text"}
          autoComplete="title"
          type="text"
          name="title"
          defaultValue={info.country}
        />
      </li>
      <li>
        <label htmlFor="EnglishTitle">عنوان کشور به انگلیسی</label>
        <input
          {...register("EnglishTitle", { required: false })}
          className={errors.EnglishTitle ? "valid" : "text"}
          autoComplete="EnglishTitle"
          type="text"
          name="EnglishTitle"
          defaultValue={info.englishTitle}
        />
      </li>
      <li>
        <label htmlFor="Currency">ارز کشور</label>
        <input
          {...register("Currency", { required: false })}
          className={errors.Currency ? "valid" : "text"}
          autoComplete="Currency"
          type="text"
          name="Currency"
          defaultValue={info.Currency}
        />
      </li>
      <li>
        <label htmlFor="Initials">مخفف کشور</label>
        <input
          {...register("Initials", { required: false })}
          className={errors.Initials ? "valid" : "text"}
          autoComplete="Initials"
          type="text"
          name="Initials"
          defaultValue={info.Initials}
        />
      </li>

    </ul>


    <Button
      className="w-50 my-3"
      type="submit"
      disabled={loadingState ? true : false}
    >
      {loadingState ? (
        <small>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
            لطفا صبرکنید
        </small>
      ) : (
        "ثبت"
      )}
    </Button>
  </form>
  )
}

export default EditCountryForm