import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import ScrollToTop from '../../Utils/ScrollToTop/ScrollToTop';
import Style from './LayoutContainer.module.scss'
import PanelSideBar from './PanelSideBar/PanelSideBar';
const LayoutContainer = ({children}) => {
    const [mobileSideBar, setMobileSideBar] = useState(false);
  return (
    <section className={Style.SectionHolder}>
        <div
            className={
                mobileSideBar ? Style.ActiveSideBarHolder : Style.SideBarHolder
            }
            >
            <PanelSideBar CloseSideBar={() => setMobileSideBar(false)}/>
        </div>
        <div className={Style.ContentHolder}>
            <div
            className={Style.OpenSideBar}
            onClick={() => setMobileSideBar(true)}
            style={{ display: mobileSideBar ? "none" : null }}
            >
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </div>
            <ScrollToTop>
            {children}
            </ScrollToTop>
        </div>
    </section>
  )
}

export default LayoutContainer