import React, { useEffect, useState } from 'react'
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import { errorToast, succToast } from '../../Utils/Toast/Toast';
import { Button, Modal } from 'react-bootstrap'
import { DeleteMagazineAPI, getMagazineListAPi } from '../../API/BarangizanServices';
import MagazineListTabel from '../../Components/Tabels/MagazineListTabel/MagazineListTabel';
import EditMagazineForm from '../../Components/Forms/EditMagazineForm/EditMagazineForm';
import MagazineGallery from '../../Components/Gallery/MagazineGallery/MagazineGallery';
import TitleHeader from '../../Components/TitleHeader/TitleHeader';
const MagazineListContainer = () => {
    const[magazineData,setMagazineData]=useState()
    const[TotalPage,setTotalPage]=useState()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const[MagazineInfo,setMagazineInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
      setShow3(false)
      
    };
    useEffect(()=>{
        getMagazineListHandler(1)
    },[])
    const getMagazineListHandler=async(pageNumber)=>{
        const data=await getMagazineListAPi(pageNumber)
        setMagazineData(data.data.list)
        setTotalPage(data.data.pagination.totalPages)
    }
    const handelModal=(id,value)=>{
        setMagazineInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }
      if(id===3){
        setShow3(true)
      }
    }
    
    const deleteTourHandler=()=>{
      setLoadingState(true)
      DeleteMagazineAPI(MagazineInfo.id,(succ,res)=>{
          if(succ){
              succToast(res.data.message)
              getMagazineListHandler(1)
              handleShow()
              setLoadingState(false)
          }
          else{
              errorToast(res.data.errors[0])
              setLoadingState(false)
          }
      })

     
    }
  return (
    <div className='flexStyle'>
        <LoadingPage show={loadingState}/>
        <TitleHeader text={"لیست مجلات ثبت شده"}/>
        <Modal show={show3} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>گالری مجله</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <MagazineGallery info={MagazineInfo}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>ویرایش مجله</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>

              <EditMagazineForm info={MagazineInfo} getUpdate={()=>getMagazineListHandler(1)} closeModal={handleShow}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show2} className={'RTL'}>
          <Modal.Header >
            <Modal.Title style={{fontSize:'1rem'}}>آیا از حذف اطمینان دارید؟</Modal.Title>
          </Modal.Header>

          <Modal.Body className='d-flex justify-content-around w-100'>
            <Button className='border-0' style={{background:'#a6ffd4',color:'green'}} onClick={()=>deleteTourHandler()}>بله</Button>
            <Button className='border-0' style={{background:'#ffcfcf',color:'red'}} onClick={handleShow}>خیر</Button>
          </Modal.Body>
      </Modal>
        <MagazineListTabel data={magazineData} totalPages={TotalPage} showModal={(e,value)=>handelModal(e,value)}/>
    
    </div>
  )
}

export default MagazineListContainer