import React from 'react'
import AddCityToCountryContainer from '../../../Container/AddCityToCountryContainer/AddCityToCountryContainer'
import Layout from '../Layout'

const AddCityToCountry = () => {
  return (
    <Layout><AddCityToCountryContainer/></Layout>
  )
}

export default AddCityToCountry