import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import LoadingPage from '../../LoadingPage/LoadingPage';
import Style from './EditPropertyTypeForm.module.scss'
import { EditPropertyTypeAPI } from '../../../API/BarangizanServices'
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
const EditPropertyTypeForm = ({id,closeModal,getUpdate}) => {
    const [loadingState, setLoadingState] = useState(false);

      const {
        register,
        formState: { errors },
        handleSubmit,
        reset
      } = useForm();
      const onSubmit = (data) =>{
          setLoadingState(true)
          let formData = new FormData();
          formData.append("Title", data.Text);
          formData.append("Id", id.id);
          EditPropertyTypeAPI(formData,(succ,res)=>{
                  if(succ){
                      succToast(res.data.message)
                      setLoadingState(false)
                      closeModal()
                      getUpdate()
                  }
                  else{
                      errorToast(res.data.errors[0])
                      setLoadingState(false)
                  }
              })
          
      }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      
      <ul className={Style.InputHolder}>
       
        <li>
          <label htmlFor="Text">عنوان نوع ملک</label>
          <input
            {...register("Text", { required: true })}
            className={errors.Text ? "valid" : "text"}
            autoComplete="Text"
            name="Text"
            defaultValue={id.title}
          />
        </li>
      </ul>




      <Button className="w-100 my-3" type="submit">
        ثبت
      </Button>
    </form>
  )
}

export default EditPropertyTypeForm