import {getAxios} from "./ApiInstance"

export const LoginAdminAPI=(info, callback)=>{
    getAxios().post("admin/account/Login",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const checkTokenAPI=( callback)=>{
    getAxios().get("account/test").then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const AddPropertyAPI=(info, callback)=>{
    getAxios().post("/admin/estate/Create",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const addAdminAPI=(info, callback)=>{
    getAxios().post("/admin/account/AddAdmin",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const addPageAPI=(info, callback)=>{
    getAxios().post("admin/Page/create",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const ChangePasswordAdminApi=(info, callback)=>{
    getAxios().post("/admin/account/ChangeAdminPassword",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const EditPropertyAPI=(info, callback)=>{
    getAxios().post("/admin/estate/Edit",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditCurrencyRateAPI=(info, callback)=>{
    getAxios().post("admin/Countries/ChangeCurrencyRate",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditUnitProjectAPI=(info, callback)=>{
    getAxios().post("admin/ProjectUnit/Edit",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditTourAPI=(info, callback)=>{
    getAxios().post("/admin/Tour/edit",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditMagazineAPI=(info, callback)=>{
    getAxios().post("admin/Magazines/Edit",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const AddImageListToPropertyAPI=(info, callback)=>{
    getAxios().post("/admin/estate/AddListToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddImageListToForeignPropertyAPI=(info, callback)=>{
    getAxios().post("/admin/ForeignEstate/AddListToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddImageListToUnitAPI=(info, callback)=>{
    getAxios().post("admin/ProjectUnit/AddListToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddImageListToForeignPropertyProjectAPI=(info, callback)=>{
    getAxios().post("/admin/ProjectEstate/AddListToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddVideoToProeprtyAPI=(info, callback)=>{
    getAxios().post("/admin/estate/AddItemToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddVideoToForeignProeprtyAPI=(info, callback)=>{
    getAxios().post("/admin/ForeignEstate/AddItemToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddVideoToForeignProeprtyProjectAPI=(info, callback)=>{
    getAxios().post("/admin/ProjectEstate/AddItemToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddVideoToUnitAPI=(info, callback)=>{
    getAxios().post("admin/ProjectUnit/AddItemToGallery",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddTourAPI=(info, callback)=>{
    getAxios().post("/admin/Tour/Create",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddMagazineAPI=(info, callback)=>{
    getAxios().post("admin/Magazines/Create",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddImagesToMagazineAPI=(info, callback)=>{
    getAxios().post("admin/Magazines/AddListOfImageToMagazine",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CreateTiketAPI=(info, callback)=>{
    getAxios().post("admin/ticket/CreateTicket",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const MakeImageMainAPI=(id, callback)=>{
    getAxios().post(`/admin/estate/MakeImageMain?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const MakeImageMainForeignPropertyAPI=(id, callback)=>{
    getAxios().post(`/admin/ForeignEstate/MakeImageMain?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const MakeImageMainUnitAPI=(id, callback)=>{
    getAxios().post(`admin/ProjectUnit/MakeImageMain?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const MakeImageMainForeignPropertyProjectAPI=(id, callback)=>{
    getAxios().post(`/admin/ProjectEstate/MakeImageMain?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const makeImageCoverMagazineAPI=(id, callback)=>{
    getAxios().get(`admin/Magazines/MakeImageCover?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const BlockUserAPI=(number, callback)=>{
    getAxios().post(`admin/account/BlockUser?username=${number}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const unBlockUserAPI=(number, callback)=>{
    getAxios().post(`admin/account/UnBlockUser?username=${number}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddPropertyTypeAPI=(info, callback)=>{
    getAxios().post(`admin/EstateType/create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddForeignPropertyAPI=(info, callback)=>{
    getAxios().post(`admin/ForeignEstate/create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddUnitToProjectAPI=(info, callback)=>{
    getAxios().post(`admin/ProjectUnit/Create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddForeignProjectPropertyAPI=(info, callback)=>{
    getAxios().post(`admin/ProjectEstate/Create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CreatePropertyFeaturesAPI=(info, callback)=>{
    getAxios().post(`/admin/Features/Create/`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditPropertyFeatureAPI=(info, callback)=>{
    getAxios().post(`admin/Features/Edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const newMessageTiketAPI=(info, callback)=>{
    getAxios().post(`/admin/ticket/NewMessage`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddUserAPI=(info, callback)=>{
    getAxios().post(`admin/account/AddUser`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddCountryAPI=(info, callback)=>{
    getAxios().post(`admin/Countries/Create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddCityToCountryAPI=(info, callback)=>{
    getAxios().post(`admin/Countries/CreateCity`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditForeignPropertyTypeAPI=(info, callback)=>{
    getAxios().post(`admin/ForeignEstateType/edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddForeignPropertyTypeAPI=(info, callback)=>{
    getAxios().post(`admin/ForeignEstateType/create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditUserAPI=(info, callback)=>{
    getAxios().post(`admin/account/EditUser`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const PropertyRequestConfirmAPI=(info, callback)=>{
    getAxios().post(`admin/EstateCreationRequest/Confirm`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditPageAPI=(info, callback)=>{
    getAxios().post(`admin/Page/edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const ForeignPropertyRequestConfirmAPI=(info, callback)=>{
    getAxios().post(`admin/ForeignEstateCreationRequest/Confirm`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditPropertyTypeAPI=(info, callback)=>{
    getAxios().post(`admin/EstateType/edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditeCountryAPI=(info, callback)=>{
    getAxios().post(`admin/Countries/Edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditCityNameAPI=(info, callback)=>{
    getAxios().post(`admin/Countries/EditCity`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditForeignPropertyAPI=(info, callback)=>{
    getAxios().post(`admin/ForeignEstate/Edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const EditForeignPropertyProjectAPI=(info, callback)=>{
    getAxios().post(`admin/ProjectEstate/Edit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CloseTiketAPI=(id, callback)=>{
    getAxios().post(`admin/ticket/CloseTicket?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const RejectPropertyRequestAPI=(info, callback)=>{
    getAxios().post(`admin/EstateCreationRequest/Reject?id=${info.id}&RejectReason=${info.RejectReason}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const RejectForeignPropertyRequestAPI=(info, callback)=>{
    getAxios().post(`admin/ForeignEstateCreationRequest/Reject?id=${info.id}&RejectReason=${info.RejectReason}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const ConfirmRequestReseverationAPI=(id, callback)=>{
    getAxios().post(`admin/estate/ConfirmReseveRequest?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const ConfirmRequestVisitAPI=(id, callback)=>{
    getAxios().post(`admin/estate/ConfirmVisitRequest?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const DeletePageAPI=(code, callback)=>{
    getAxios().post(`admin/Page/Remove?id=${code}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeletePropertyAPI=(code, callback)=>{
    getAxios().post(`admin/estate/Remove?code=${code}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteUnitAPI=(code, callback)=>{
    getAxios().post(`admin/ProjectUnit/Remove?code=${code}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const DeleteItemFromGalleryAPI=(id, callback)=>{
    getAxios().post(`admin/estate/RemoveFromGallery?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteItemFromForeignGalleryAPI=(id, callback)=>{
    getAxios().post(`admin/ForeignEstate/RemoveFromGallery?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteItemFromUntiGalleryAPI=(id, callback)=>{
    getAxios().post(`admin/ProjectUnit/RemoveFromGallery?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteItemFromForeignProjectGalleryAPI=(id, callback)=>{
    getAxios().post(`admin/ProjectEstate/RemoveFromGallery?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteImageFromGalleryMagazineAPI=(id, callback)=>{
    getAxios().post(`admin/Magazines/RemoveFromMagazine?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteAdminAPI=(userName, callback)=>{
    getAxios().post(`admin/account/RemoveAdmin?username=${userName}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const DeleteTourAPI=(id, callback)=>{
    getAxios().post(`admin/Tour/remove?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const DeleteMagazineAPI=(id, callback)=>{
    getAxios().post(`admin/Magazines/Remove?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeletePropertyFeatureAPI=(id, callback)=>{
    getAxios().post(`admin/Features/Remove?featureId=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeletePropetyTypeAPI=(id, callback)=>{
    getAxios().post(`admin/EstateType/Remove?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const DeleteCountryAPI=(id, callback)=>{
    getAxios().post(`admin/Countries/remove?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteForeignPropertyTypeAPI=(id, callback)=>{
    getAxios().post(`admin/ForeignEstateType/Remove?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const DeleteForeignPropertyAPI=(id, callback)=>{
    getAxios().post(`admin/ForeignEstate/Remove?code=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteForeignPropertyProjectAPI=(id, callback)=>{
    getAxios().post(`admin/ProjectEstate/Remove?code=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteCityAPI=(id, callback)=>{
    getAxios().post(`admin/Countries/RemoveCity?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const setPrePayAPI=(info, callback)=>{
    getAxios().post(`admin/Tahator/SetPrice`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const AddAffidavitAPI=(info, callback)=>{
    getAxios().post(`admin/AffidavitTemplates/Create`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const DeleteAffidavitAPI=(id, callback)=>{
    getAxios().post(`admin/AffidavitTemplates/Delete?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const AllocationAffidavitAPI=(info, callback)=>{
    getAxios().post(`admin/Tahator/SetAffidavit`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};








export const getAffidavitListAPI=(callback)=>{
    getAxios().get(`admin/AffidavitTemplates/List`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const getAffidavitDetailAPI=(id,callback)=>{
    getAxios().get(`admin/AffidavitTemplates/Detail?id=${id}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const getNewRequestApi=async () => {
    try{
        const result =await getAxios().get("admin/general/Notificantions");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPageListAPI=async () => {
    try{
        const result =await getAxios().get("admin/Page/list");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getProvinceApi=async () => {
    try{
        const result =await getAxios().get("admin/estate/provinces");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getContactUsListAPI=async () => {
    try{
        const result =await getAxios().get("admin/ContactUsMessage/list");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyTypeAPI=async () => {
    try{
        const result =await getAxios().get("admin/ForeignEstateType/list");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getCitiesOfCountriesListAPI=async () => {
    try{
        const result =await getAxios().get("admin/Countries/Cities");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignCitylistAPI=async (countryId) => {
    try{
        const result =await getAxios().get(`admin/Countries/Cities?countryId=${countryId}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignCitylistAPI2=async (countryname) => {
    try{
        const result =await getAxios().get(`admin/Countries/Cities?countryname=${countryname}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const CooperationRequestAPI=async () => {
    try{
        const result =await getAxios().get("admin/General/CooperationRequests?type=0");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getAdminListAPI=async () => {
    try{
        const result =await getAxios().get("/admin/account/AdminList");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const checkMainAdminAPI=async () => {
    try{
        const result =await getAxios().get("admin/account/IsMainAdmin");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getCountryListAPI=async () => {
    try{
        const result =await getAxios().get("admin/Countries/list");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getTahatorRequestAPI=async () => {
    try{
        const result =await getAxios().get("admin/Tahator/list");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getMagazineRegisterAPI=async () => {
    try{
        const result =await getAxios().get("admin/magazines/Requests");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getFeaturesListOptionsAPI=async (type) => {
    try{
        const result =await getAxios().get(`admin/Features/GetAllWithOptions?FeatureType=${type}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getProeprtyDetailAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/estate/Detail?Code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignProeprtyDetailAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/ForeignEstate/Detail?Code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getUnitDetailAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/ProjectUnit/Detail?Code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignProjectDetailAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/ProjectEstate/Detail?Code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};

export const getPropertyGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/estate/Gallery?code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/ForeignEstate/Gallery?code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getUnitGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/ProjectUnit/Gallery?code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyProjectGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/ProjectEstate/Gallery?code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};


export const getMagazineGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`admin/Magazines/Gallery?id=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};



export const getCityApi=async (name) => {
    try{
        const result =await getAxios().get(`admin/estate/Cities?provinceName=${name}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyRequestDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/EstateCreationRequest/detail?id=${id}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyRequestDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/ForeignEstateCreationRequest/detail?id=${id}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};


export const getPropertyListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/estate/GetList?pageNumber=${pageNumber}&pageDataCount=50`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/ForeignEstate/GetList?pageNumber=${pageNumber}&pageDataCount=50`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getUnitListApi=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/ProjectUnit/List?pageNumber=${pageNumber}&pageDataCount=50`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyProjectListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/ProjectEstate/List?pageNumber=${pageNumber}&pageDataCount=50`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getAllForeignPropertyProjectListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/ProjectEstate/List?pageNumber=1&pageDataCount=100000`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyRequestAPI=async (pageNumber,status) => {
    try{
        const result =await getAxios().get(`admin/EstateCreationRequest/list?Status=${status===undefined?0:status}&pageNumber=${pageNumber}&pageDataCount=50`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyRequestAPI=async (pageNumber,status) => {
    try{
        const result =await getAxios().get(`admin/ForeignEstateCreationRequest/list?Status=${status===undefined?0:status}&pageNumber=${pageNumber}&pageDataCount=50`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const gerPropertyFeaturesAPI=async (type) => {
    try{
        const result =await getAxios().get(`admin/Features/List?FeatureType=${type}&pageNumber=1&pageDataCount=30`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyTypesAPI=async () => {
    try{
        const result =await getAxios().get(`admin/EstateType/list`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getCurrencyListAPI=async () => {
    try{
        const result =await getAxios().get(`admin/Countries/Currencies`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getCurrencyHistoryListAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/Countries/CurrencyHistory?id=${id}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};

export const getPorpertyFeaturesDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/Features/Detail?featureId=${id}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getBlockListAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/ProjectEstate/BlocksAndFloors?code=${id}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getToursListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/Tour/list?pageNumber=${pageNumber}&pageDataCount=30`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyReservationRequestAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/estate/ReseveRequests?pageNumber=${pageNumber}&pageDataCount=100`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyVisitRequestAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/estate/VisitRequests?pageNumber=${pageNumber}&pageDataCount=100`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getTiketListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/ticket/List?pageNumber=${pageNumber}&pageDataCount=100`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getMagazineListAPi=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/Magazines/List?pageNumber=${pageNumber}&pageDataCount=30`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getTourDetailsAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/Tour/detail?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getPplRegistredTourAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/Tour/TourRegistrations?tourId=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getPplRegistredTourDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/Tour/TourRegistrationsDetail?tourRegistrationId=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getTiketInfoAPI=async (id) => {
    try{
        const result =await getAxios().get(`/admin/ticket/ViewTicket?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getMagazineDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/Magazines/Detail?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getUserDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`admin/account/GetUser?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getUserListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/account/Users?pageNumber=${pageNumber}&pageDataCount=30`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getAllUserListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`admin/account/Users?pageNumber=${pageNumber}&pageDataCount=1000000`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};























