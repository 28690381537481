import React from 'react'
import TahatorRequestListContainer from '../../../Container/TahatorRequestContainer/TahatorRequestListContainer'
import Layout from '../Layout'

const TahatorRequest = () => {
  return (
    <Layout><TahatorRequestListContainer/></Layout>
  )
}

export default TahatorRequest