import React from 'react'
import AddTiketContainer from '../../../Container/AddTiketContainer/AddTiketContainer'
import Layout from '../Layout'
const AddTikets = () => {
  return (
    <Layout>
        <AddTiketContainer/>
    </Layout>
  )
}

export default AddTikets