import React, { useEffect, useState } from 'react'
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CreatePropertyFeaturesAPI, EditPropertyFeatureAPI, getPorpertyFeaturesDetailAPI } from '../../../API/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from "../../LoadingPage/LoadingPage";
import Style from './EditPropertyFeaturesForm.module.scss'
const EditPropertyFeaturesForm = ({id,getUpdate,closeModal}) => {
    
    const[featureDetail,setFeatureDetail]=useState()
    useEffect(()=>{
        getFeatureDetailHandler()
    },[])
    const getFeatureDetailHandler=async()=>{
        const data=await getPorpertyFeaturesDetailAPI(id.id)
        setFeatureDetail(data.data)
    }
    const [loadingState, setLoadingState] = useState(false);
    const [featuresCountState, setFeaturesCountState] = useState();
    const runCallback = (cb) => {
        return cb();
      };
      const {
        register,
        formState: { errors },
        handleSubmit,
        reset
      } = useForm();
      const onSubmit = (data) =>{
          setLoadingState(true)
          let formData = new FormData();
          formData.append("id", id.id);
          formData.append("Text", data.Text);
          formData.append("EnglishText", data.EnglishText);
          
          for (var i = 0; i < parseInt(featuresCountState===undefined?featureDetail.options.length:featuresCountState); i++) {
            formData.append(`Options[${i}].id`, data.Options[i].id===""?0:data.Options[i].id);
            formData.append(`Options[${i}].Text`, data.Options[i].Text);
            formData.append(`Options[${i}].EnglishText`, data.Options[i].EnglishText);
          }
          EditPropertyFeatureAPI(formData,(succ,res)=>{
              if(succ){
                  succToast(res.data.message)
                  getUpdate()
                  reset()
                  setLoadingState(false)
                  closeModal()
              }
              else{
                  errorToast(res.data.errors[0])
                  setLoadingState(false)
              }
          })
      }
      
  return (
    <div className='flexStyle'>
        {
            featureDetail===undefined?<Spinner animation='border'/>:
            <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      <ul className={Style.InputHolder}>
       
        <li>
          <label htmlFor="Text">نام ویژگی</label>
          <input
            {...register("Text", { required: true })}
            className={errors.Text ? "valid" : "text"}
            autoComplete="Text"
            name="Text"
            defaultValue={featureDetail.feature}
          />
        </li>
        <li>
          <label htmlFor="EnglishText">نام ویژگی به انگلیسی</label>
          <input
            {...register("EnglishText", { required: true })}
            className={errors.EnglishText ? "valid" : "text"}
            autoComplete="EnglishText"
            name="EnglishText"
            defaultValue={featureDetail.englishText}
          />
        </li>
        
        
        <li>
          <label htmlFor="featureCount"> تعداد ویژگی های ملک </label>
          <input
            {...register("featureCount", { required: true })}
            className={errors.featureCount ? "valid" : "text"}
            autoComplete="featureCount"
            type="number"
            name="featureCount"
            onChange={(e) => setFeaturesCountState(e.target.value)}
            defaultValue={featureDetail.options.length}
          />
        </li>
      </ul>
      <ul className={Style.InputHolder}>
        {runCallback(() => {
          const row = [];
          for (var i = 0; i < parseInt(featuresCountState===undefined?featureDetail.options.length:featuresCountState); i++) {
            row.push(
              <li key={i}>
                <input  {...register(`Options[${i}].id`, { required: false })} 
                 name={`Options[${i}].id`}
                style={{display:"none"}}
                defaultValue={featureDetail.options[i]&&featureDetail.options[i].id}
                />
                <input
                  {...register(`Options[${i}].Text`, { required: true })}
                  className={errors.Options ? "valid" : "text"}
                  autoComplete={`Options[${i}].Text`}
                  type="text"
                  name={`Options[${i}].Text`}
                  style={{ marginBottom: "0.5rem" }}
                  placeholder={` مقدار  ${i + 1}`}
                  defaultValue={featureDetail.options[i]&&featureDetail.options[i].text}
                />
                <input
                  {...register(`Options[${i}].EnglishText`, { required: true })}
                  className={errors.Options ? "valid" : "text"}
                  autoComplete={`Options[${i}].EnglishText`}
                  type="text"
                  name={`Options[${i}].EnglishText`}
                  style={{ marginBottom: "0.5rem" }}
                  placeholder={` مقدار به انگلیسی  ${i + 1}`}
                  defaultValue={featureDetail.options[i]&&featureDetail.options[i].englishText}
                />
              </li>
            );
          }
          return row;
        })}
      </ul>




      <Button className="w-100 my-3" type="submit">
        ثبت
      </Button>
    </form>
        }
    </div>
  )
}

export default EditPropertyFeaturesForm