import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getMagazineRegisterAPI } from '../../API/BarangizanServices'
import AddTiketForOneUserForm from '../../Components/Forms/AddTiketForOneUserForm/AddTiketForOneUserForm'
import LoadingPage from '../../Components/LoadingPage/LoadingPage'
import MagazinePropertyRegisterTabel from '../../Components/Tabels/MagazinePropertyRegisterTabel/MagazinePropertyRegisterTabel'

const MagazinePropertyRegisterContainer = () => {
    useEffect(()=>{
        getRequestsHandler(1)
    },[])
    const[requestsState,setRequestsState]=useState()
    const getRequestsHandler =async(pageNumber)=>{
        const data=await getMagazineRegisterAPI()
        setRequestsState(data)
    }
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const[RequestInfo,setRequestInfo]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const handleShow = () =>{
      setShow(false)
      setShow2(false)
    };
    const handelModal=(id,value)=>{
      setRequestInfo(value)
      if(id===1){
        setShow(true)
      }
      if(id===2){
        setShow2(true)
      }

    }

  return (
    <div className='flexStyle'>
        <h5 className='w-100 text-end px-3'>لیست درخواست های ثبت ملک در مجله</h5>
        <LoadingPage show={loadingState}/>
        <Modal show={show2} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}>بازکردن تیکت</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
              <AddTiketForOneUserForm info={RequestInfo}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShow} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>


        <MagazinePropertyRegisterTabel data={requestsState} showModal={(e,value)=>handelModal(e,value)}/>
    </div>
  )
}

export default MagazinePropertyRegisterContainer