import { SearchOutlined } from "@ant-design/icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Space, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { separate } from "../../../Utils/Seprate/SeprateThreeNumber";
import Style from "./TourListTabel.module.scss";
class TourListTabel extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sortedInfo: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let dataSoruce = [];
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSoruce.push({
          key:index,
          id:element.id,
          title: element.title,
          startDate: element.startDate,
          participantsCount: element.participantsCount,
          participantsRegistered: element.participantsRegistered,
          pricePerPerson: separate(element.pricePerPerson),

          price: separate(element.price),
        });
        index = index + 1;
      });

    const columns = [
      {
        title: "شماره",
        dataIndex: "key",
        key: "key",
      },{
        title: "نام تور",
        dataIndex: "title",
        key: "title",

        ...this.getColumnSearchProps("type"),
      },
      {
        title: "تاریخ تور",
        dataIndex: "startDate",
        key: "startDate",

        ...this.getColumnSearchProps("startDate"),
      },
      {
        title: " ظرفیت",
        dataIndex: "participantsCount",
        key: "participantsCount",

        ...this.getColumnSearchProps("participantsCount"),
      },
      {
        title: "تعداد ثبت نام کرده",
        dataIndex: "participantsRegistered",
        key: "participantsRegistered",

        ...this.getColumnSearchProps("participantsRegistered"),
      },
      {
        title: "هزینه",
        dataIndex: "price",
        key: "price",

        ...this.getColumnSearchProps("price"),
      },
      {
        title: "هزینه شخص اضافه",
        dataIndex: "pricePerPerson",
        key: "pricePerPerson",

        ...this.getColumnSearchProps("pricePerPerson"),
      },
      
      {
        title: "عملیات",
        key: "action",
        width: "14%",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              className="bg-warning border-0"
              icon={
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => this.props.showModal(1, record)}
                />
              }
            />
            <Button
              type="primary"
              danger
              icon={
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => this.props.showModal(2, record)}
                />
              }
            />
          </Space>
        ),
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          loading={this.props.data === undefined ? true : false}
          columns={columns}
          dataSource={dataSoruce}
          pagination={{position:['bottomCenter'],total:this.props.totalPages,pageSize:30}}
        />
      </div>
    );
  }
}

export default TourListTabel;
