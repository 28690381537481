import React from 'react'
import PagesListContainer from '../../../Container/PagesListContainer/PagesListContainer'
import Layout from '../Layout'

const PagesList = () => {
  return (
    <Layout><PagesListContainer/></Layout>
  )
}

export default PagesList